import React from "react";
import "./AdminSidebar.css";
import { FaHome, FaImage, FaUserMd } from "react-icons/fa";
import { FaListUl } from "react-icons/fa";
import { FaPeopleArrows } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import { FaUserAstronaut } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { AiFillSetting } from "react-icons/ai";
import { FaUserEdit } from "react-icons/fa";




const AdminSidebar = () => {

  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className={`${isActive(`/admin/dashboard`) ? "active" : ""}`}>
              <Link to={"/admin/dashboard"}>
                <FaHome /> <span>Dashboard</span>
              </Link>
            </li>
            <li
              className={`${isActive("/admin/appointments") ? "active" : ""}`}
            >
              <Link to={"/admin/appointments"}>
                <FaListUl /> <span>Appointments</span>
              </Link>
            </li>
            <li className={`${isActive("/admin/services") ? "active" : ""}`}>
              <Link to={"/admin/services"}>
                <FaPeopleArrows /> <span>Services</span>
              </Link>
            </li>
            <li className={`${isActive("/admin/specialites") ? "active" : ""}`}>
              <Link to={"/admin/specialites"}>
                <FaPeopleArrows /> <span>Specialities</span>
              </Link>
            </li>
            <li className={`${isActive("/admin/hospital") ? "active" : ""}`}>
              <Link to={"/admin/hospital"}>
                <FaPeopleArrows /> <span>Hospital</span>
              </Link>
            </li>
            <li className={`${isActive("/admin/doctors") ? "active" : ""}`}>
              <Link to={"/admin/doctors"}>
                <FaUserMd /> <span>Doctors</span>
              </Link>
            </li>
            <li className={`${isActive("/admin/patients") ? "active" : ""}`}>
              <Link to={"/admin/patients"}>
                <FaRegUser /> <span>Patients</span>
              </Link>
            </li>
            <li className={`${isActive("/admin/insuranceCompany") ? "active" : ""}`}>
              <Link to={"/admin/insuranceCompany"}>
                <FaRegUser /> <span>Insurance</span>
              </Link>
            </li>
            <li className={`${isActive("/admin/reviews") ? "active" : ""}`}>
              <Link to={"/admin/reviews"}>
                <FaRegStar /> <span>Reviews</span>
              </Link>
            </li>
            <li className={`${isActive("/admin/Gallery") ? "active" : ""}`}>
              <Link to={"/admin/Gallery"}>
                <FaImage /> <span>Gallery</span>
              </Link>
            </li>
            <li className={`${isActive("/admin/blogs") ? "active" : ""}`}>
              <Link to={"/admin/blogs"}>
                <FaImage /> <span>BLogs</span>
              </Link>
            </li>
            <li className={`${isActive("/admin/transaction") ? "active" : ""}`}>
              <Link to={"/admin/transaction"}>
                <FaBriefcase />
                <span>Transactions</span>
              </Link>
            </li>

            {/* <li className="submenu">
              <a href="#">
                <i className="fe fe-document"></i> <span> Reports</span>{" "}
                <span className="menu-arrow"></span>
              </a>
               <ul style={{ display: "none" }}>
                <li>
                  <a>Invoice Reports</a>
                </li>
              </ul> 
            </li> */}
            {/* <li className="menu-title">
              <span>Pages</span>
            </li> */}
            <li
              className={`text-white ${
                isActive("/admin/Setting") ? "active" : ""
              }`}
            >
              <Link to={"/admin/Setting"}>
                <AiFillSetting /> <span>Setting</span>
              </Link>
            </li>
            <li
              className={`text-white ${
                isActive("/admin/profile") ? "active" : ""
              }`}
            >
              <Link to={"/admin/profile"}>
                <FaRegUser /> <span>Profile</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;


