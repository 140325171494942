import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import showImg from "../../../images/specialities/specialities-01.png";
import StarRatings from "react-star-ratings";
import img from "../../../images/avatar.jpg";
import { Tag } from "antd";
import "./index.css";
import {
  FaLocationArrow,
  FaRegThumbsUp,
  FaDollarSign,
  FaComment,
  FaUserCircle,
} from "react-icons/fa";
import { truncate } from "../../../utils/truncate";
import { FaHospitalAlt } from "react-icons/fa";
import { SettingContext } from "../../context/setting";
import HeartComponent from "../../UI/component/heart";
import useAuthCheck from "../../../redux/hooks/useAuthCheck";
import {
  useAddFavouriteMutation,
  useRemoveFavouriteMutation,
} from "../../../redux/api/favouriteApi";

const SearchContent = ({ data }) => {
  const { data: AuthData, role: AuthRole } = useAuthCheck();
  // console.log(AuthData, AuthRole)
  const services = data?.services?.split(",");
  const setting = useContext(SettingContext);
  const [ShowPrice, setShowPrice] = useState(setting[0].isDisplayPrice);
  const [isFavourite, setIsFavourite] = useState(false);
  const [
    AddtoFavourite,
    { isLoading: HisLoading, isSuccess: HisSuccess, isError: HisError },
  ] = useAddFavouriteMutation();
  const [
    RemoveFavourite,
    { isLoading: RisLoading, isSuccess: RisSuccess, isError: RisError },
  ] = useRemoveFavouriteMutation();

  let averageRating = 5;

  if (data && data?.reviews?.length > 0 && data?.reviews) {
    const totalRating = data?.reviews?.reduce(
      (acc, item) => acc + Number(item?.star),
      0
    );
    // Calculate the average rating
    averageRating = totalRating / data?.reviews?.length;
  }

  useEffect(() => {
    const favouritePatient = data?.favourites?.find(
      (fav) => fav?.patient?.id === AuthData?.id
    );
    if (AuthData && favouritePatient) {
      setIsFavourite(true);
    } else {
      setIsFavourite(false);
    }
  }, [data, AuthData]);

  const heartClick = () => {
    if (AuthData && isFavourite !== true) {
      AddtoFavourite({ doctorId: data?.id });
      setIsFavourite(true);
    } else {
      RemoveFavourite({ doctorId: data?.id });
      setIsFavourite(false);
    }
  };

  return (
    <section>
      {/* <div className="mb-4 rounded" style={{ background: "#f3f3f3" }}>
        <div className="d-flex p-3 justify-content-between">
          <div className="d-flex gap-3">
            <div className="doc-img-fluid d-flex align-items-center">
              {data?.img && (
                <img src={data?.img} className="" alt="User Image" />
              )}
            </div>
            <div className="doc-info">
              <h5 className="mb-0">
                <Link to={`/doctors/profile/${data?.id}`}>
                  Dr. {data?.firstName + " " + data?.lastName}
                </Link>
              </h5>
              <p className="m-0 form-text">{data?.designation}</p>
              <p className="doc-department m-0">
                <img src={showImg} className="img-fluid" alt="Speciality" />
                Urology
              </p>

              <div className="d-flex align-items-center">
                <div>
                  <StarRatings
                    rating={5}
                    starRatedColor="#f4c150"
                    numberOfStars={5}
                    name="rating"
                    starDimension="15px"
                    starSpacing="2px"
                  />
                </div>
                <div>(4)</div>
              </div>

              <div className="clinic-details">
                <p className="form-text text-secondary">
                  <FaLocationArrow /> {data?.address}, {data?.country}
                </p>
                <ul className="clinic-gallery mt-3">
                  <li>
                    <img
                      src={showImg}
                      alt="Feature"
                      style={{ maxWidth: "30px" }}
                    />
                  </li>
                  <li>
                    <img
                      src={showImg}
                      alt="Feature"
                      style={{ maxWidth: "30px" }}
                    />
                  </li>
                  <li>
                    <img
                      src={showImg}
                      alt="Feature"
                      style={{ maxWidth: "30px" }}
                    />
                  </li>
                  <li>
                    <img
                      src={showImg}
                      alt="Feature"
                      style={{ maxWidth: "30px" }}
                    />
                  </li>
                </ul>
              </div>
              {services?.map((item, id) => (
                <Tag key={id + 51}>{item}</Tag>
              ))}
            </div>
          </div>
          <div className="doc-info-right me-3">
            <div className="clini-infos">
              <ul>
                <li>
                  <FaRegThumbsUp /> 97%
                </li>
                <li>
                  <FaComment /> 4 Feedback
                </li>
                <li>
                  <FaLocationArrow />
                  {truncate(data?.clinicAddress, 20)}
                </li>
                <li>
                  <FaDollarSign /> {data?.price ? truncate(data?.price, 4) : 60}{" "}
                  (Per Hour)
                </li>
              </ul>
            </div>
            <div className="clinic-booking">
              <Link
                to={`/doctors/profile/${data?.id}`}
                className="view-pro-btn"
              >
                View Profile
              </Link>
              <Link to={`/booking/${data?.id}`} className="apt-btn">
                Book Appointment
              </Link>
            </div>
          </div>
        </div>
      </div> */}

      <div className="card doctor-appointment-card mt-4">
        <div className="card-header">
          <div className="row">
            <div className="col-8">
              <h5 className="card-title">
                <Link to={`/doctors/profile/${data?.id}`}>
                  Dr. {data?.firstName + " " + data?.lastName}
                  <img
                    src={data.img || img}
                    alt="profile-image"
                    style={{
                      width: "2rem",
                      height: "2rem",
                      objectFit: "cover",
                      borderRadius: "50%",
                      marginLeft: "1rem",
                      display: "inline",
                    }}
                  />
                  {/* <FaUserCircle className="ms-2" /> */}
                </Link>
              </h5>
              {/* <div className="flex ">
                {data?.specialistsList?.length !== 0 && (
                  <span style={{ color: "var(--secondary)" }} className="me-4">
                   
                    {data?.specialistsList[0]?.specialist?.name ?? ""}
                    {data?.specialistsList.length > 1 ? (
                      <span className="text-secondary fs-6">
                        {"...+" + data?.specialistsList.length}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                )}
                {
                data?.hospitalDoctors?.map(({hospital})=>{
                  return (<span style={{ color: "" }} ><FaHospitalAlt /> {hospital.name} </span> )
                })
              }
                {data?.hospitalDoctors.length !== 0 && (
                  <span style={{ color: "" }}>
                    <FaHospitalAlt />{" "}
                    {data?.hospitalDoctors[0]?.hospital?.name ?? ""}
                    {data?.hospitalDoctors.length > 1 ? (
                      <span className="text-secondary fs-6">
                        {"...+" + data?.hospitalDoctors.length}
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                )}
              </div> */}
            </div>
            <div className="col-4 d-flex  justify-content-between">
              {AuthRole === "patient" && (
                <div className="me-2">
                  <HeartComponent
                    isFavourite={isFavourite}
                    setIsFavourite={setIsFavourite}
                    onClick={heartClick}
                  />
                </div>
              )}
              <button className="btn btn-primary">
                <Link
                  to={`/booking/${data?.id}`}
                  className="apt-btn"
                  style={{ color: "white" }}
                >
                  Book Appointment
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <p>{data?.biography}</p>
        </div>
        <div className="card-footer">
          <div className="row d-flex justify-content-between">
            {ShowPrice && (
              <div className="col-4">
                <span style={{ color: "var(--primary)" }}>
                    <FaDollarSign className="inline" />{" "}
                  {data?.price ? truncate(data?.price, 4) : 60} (Per Hour)
                </span>
              </div>
            )}
            <div className="col-4">
              <span style={{ color: "var(--primary)" }}>
                <FaComment className="inline" /> {data?.reviews?.length}{" "}
                Feedback
              </span>
            </div>
            <div className="col-4">
              <StarRatings
                rating={averageRating}
                starRatedColor="#f4c150"
                numberOfStars={5}
                name="rating"
                starDimension="15px"
                starSpacing="2px"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SearchContent;
