import React from "react";
import img1 from "../../../images/specialities/specialities-01.png";
import img2 from "../../../images/specialities/specialities-02.png";
import img3 from "../../../images/specialities/specialities-03.png";
import img4 from "../../../images/specialities/specialities-04.png";
import img5 from "../../../images/specialities/specialities-05.png";
import icon from "../../../images/img/tilde.png";
import { FaCheckDouble } from "react-icons/fa";
// import { PiTildeBold } from "react-icons/pi";
import { GiKidneys, GiBrain, GiHeartOrgan } from "react-icons/gi";
import { Empty } from "antd";
import { Link } from "react-router-dom";
import { useGetAllSpecialistQuery } from "../../../redux/api/specialites";

import "./index.css";
import Heading from "../../UI/component/Heading";
import { doctorSpecialistArray } from "../../../../src/constant/global";
import { useNavigate } from "react-router-dom";

const ClinicAndSpecialities = () => {
  const navigate = useNavigate();
  // const specialtiesToShow = doctorSpecialistArray.slice(0, 4);

  const { data, isLoading, isError } = useGetAllSpecialistQuery({ limit: 4 });
  const specialist = data?.specialist;

  let content = null;
  if (!isLoading && isError) content = <div>Something Went Wrong !</div>;
  if (!isLoading && !isError && specialist?.length === 0)
    content = (
      <div>
        <Empty />
      </div>
    );
  if (!isLoading && !isError && specialist?.length > 0)
    content = (
      <>
        <div className="wrapper">
          {specialist.map((item, key) => (
            <div
              className="circle-content-wrapper"
              key={key}
              onClick={() => {
                navigate(`/specialites/${item?.id}`);
              }}
            >
              <div className="circle">
                <img
                  src={item?.icon}
                  alt="specialist"
                  style={{ width: "70%", height: "70%" }}
                />
              </div>
            </div>
          ))}
        </div>
      </>
    );

  return (
    // <section className="section section-specialities position-relative">
    // 	<div className="container-fluid">
    // 		<div className='mb-5 section-title text-center'>
    // 			<h2>Clinic and Specialities</h2>
    // 			<p className='m-0'>Lorem ipsum dolor sit amet consectetur adipisicing.</p>
    // 		</div>

    // 		<div className="row justify-content-center">
    // 			<div className="col-md-9">
    // 				<div className="specialities-slider d-flex justify-content-center align-items-center gap-5">
    // 					<div className="speicality-item text-center">
    // 						<div className="speicality-img">
    // 							<img src={img1} className="img-fluid" alt="" />
    // 							<span><i><FaCheckDouble/></i></span>
    // 						</div>
    // 						<p>Urology</p>
    // 					</div>
    // 					<div className="speicality-item text-center">
    // 						<div className="speicality-img">
    // 							<img src={img2} className="img-fluid" alt="" />
    // 							<span><i><FaCheckDouble/></i></span>
    // 						</div>
    // 						<p>Neurology</p>
    // 					</div>
    // 					<div className="speicality-item text-center">
    // 						<div className="speicality-img">
    // 							<img src={img3} className="img-fluid" alt="" />
    // 							<span><i><FaCheckDouble/></i></span>
    // 						</div>
    // 						<p>Orthopedic</p>
    // 					</div>
    // 					<div className="speicality-item text-center">
    // 						<div className="speicality-img">
    // 							<img src={img4} className="img-fluid" alt="" />
    // 							<span><i><FaCheckDouble/></i></span>
    // 						</div>
    // 						<p>Cardiologist</p>
    // 					</div>
    // 					<div className="speicality-item text-center">
    // 						<div className="speicality-img">
    // 							<img src={img5} className="img-fluid" alt="" />
    // 							<span><i><FaCheckDouble/></i></span>
    // 						</div>
    // 						<p>Dentist</p>
    // 					</div>
    // 				</div>
    // 			</div>
    // 		</div>
    // 	</div>
    // </section>
    // <section className="clinic">
    //   <div className="container">
    // 	<Heading name="Clinic and Specialities" iconUrl={icon}/>
    //   </div>

    //   <div className="clinic-content mt-5">
    //     <div className="container">
    //       <div className="wrapper">
    //         <div className="circle-content-wrapper">
    //           <div className="circle">
    //             <GiKidneys className="clinic-icon" />
    //           </div>
    //           <h6 className="text-center text-white mt-3">Urology</h6>
    //         </div>

    // 	<div className="circle-content-wrapper">
    //           <div className="circle">
    //             <GiBrain className="clinic-icon" />
    //           </div>
    // 	  <h6 className="text-center text-white mt-3">Neurology</h6>
    //         </div>

    // 	<div className="circle-content-wrapper">
    //           <div className="circle">
    //             <GiKidneys className="clinic-icon" />
    //           </div>
    //           <h6 className="text-center text-white mt-3">Urology</h6>
    //         </div>

    // 	<div className="circle-content-wrapper">
    //           <div className="circle">
    //             <GiBrain className="clinic-icon" />
    //           </div>
    // 	  <h6 className="text-center text-white mt-3">Neurology</h6>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>

    <section className="clinic">
      <div className="container">
        <Heading name="Clinic and Specialities" iconUrl={icon} />
      </div>

      <div className="clinic-content mt-5">
        <div className="container">
          <div className="wrapper mb-3">
            {content}
            {/* {specialtiesToShow.map((speciality) => (
            <div className="circle-content-wrapper" key={speciality.id}>
              <div className="circle">
                Render the appropriate icon based on the speciality
                {speciality.value === "Dermatologist" ? (
                  <GiHeartOrgan className="clinic-icon" />
                ) : speciality.value === "Cardiologist" ? (
                  <GiHeartOrgan className="clinic-icon" />
                ) : (
                <GiHeartOrgan  className="clinic-icon" />
                )}
              </div>
              <h6 className="text-center text-white mt-3">{speciality.value}</h6>
            </div>
          ))} */}
          </div>
          <div className="text-center mt-2">
            <Link
              to={"/specialites"}
              className="more-btn scrollto "
              style={{ color: "white", border: "1px solid white" }}
            >
              Know More
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClinicAndSpecialities;
