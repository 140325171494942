import React, { memo, useCallback } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const MedicineRangePickerForm = ({ id, medicineList, setMedicineList, defaultValue }) => {
    const onRangeChange = useCallback((dates, dateStrings) => {
        if (dates) {
            const durationData = dateStrings[0] + ',' + dateStrings[1];
            const findObj = medicineList.find((item) => item.id === id);

            // Prevent unnecessary updates if the duration hasn't changed
            if (findObj?.duration === durationData) return;

            const updateObj = { ...findObj, duration: durationData };

            setMedicineList(prev => {
                const prevArray = [...prev];
                const findToIndex = prevArray.findIndex(item => item.id === id);
                if (findToIndex !== -1) {
                    prevArray[findToIndex] = updateObj;
                } else {
                    prevArray.push(updateObj);
                }
                return prevArray;
            });
        }
    }, [id, medicineList, setMedicineList]);

    return (
        <RangePicker
            onChange={onRangeChange}
            // defaultValue={defaultValue} 
            size="large"
            style={{ width: '100%' }}
        />
    );
};

export default memo(MedicineRangePickerForm);
