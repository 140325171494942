import { tagTypes } from "../tag-types"
import { baseApi } from "./baseApi"
const PAT_URL = '/patient'

export const patientApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getAllPatient: build.query({
            query: (id) => ({
                url: `${PAT_URL}`,
                method: 'GET',
            }),
            providesTags: [tagTypes.patient]
        }),
        getPatient: build.query({
            query: (id) => ({
                url: `${PAT_URL}/${id}`,
                method: 'GET',
            }),
            providesTags: [tagTypes.patient]
        }),
        updatePatient: build.mutation({
            query: ({ data, id }) => ({
                url: `${PAT_URL}/${id}`,
                method: 'PATCH',
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }),
            invalidatesTags: [tagTypes.patient]
        }),
        createPatients: build.mutation({
            query: ({ data }) => ({
                url: `${PAT_URL}`,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }),
            invalidatesTags: [tagTypes.doctor]
        }),
        deletePatient: build.mutation({
            query: (id) => ({
                url: `${PAT_URL}/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: [tagTypes.blogs]
        }),
    })
})

export const { 
     useGetAllPatientQuery,
     useGetPatientQuery, 
     useUpdatePatientMutation,
     useCreatePatientsMutation, 
     useDeletePatientMutation, 
      } = patientApi