import React, { useEffect } from "react";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import img from "../../../images/avatar.jpg";

import "./Appointments.css";
import {
  useGetDoctorAppointmentsQuery,
  useUpdateAppointmentMutation,
} from "../../../redux/api/appointmentApi";
import moment from "moment";
import { Button, Empty, message, Tabs, Tag, Tooltip } from "antd";
import { FaEye, FaCheck, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  FaClock,
  FaEnvelope,
  FaLocationArrow,
  FaPhoneAlt,
  FaBriefcaseMedical,
} from "react-icons/fa";
import { clickToCopyClipBoard } from "../../../utils/copyClipBoard";
import AppointmentCalendar from "../../Shared/Calender/AppointmentCalendar";
import AppointmentsListView from "./AppointmentListView";

const Appointments = () => {
  const onChange = (key) => {
    console.log(key);
  };
  
  const items = [
    {
      key: '1',
      label: 'Calendar View',
      children: <AppointmentCalendar/>      ,
    },
    {
      key: '2',
      label: 'List View',
      children: <AppointmentsListView/>,
    }
   
  ];
  
 
  return <DashboardLayout>
    <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </DashboardLayout>;
};

export default Appointments;
