import React, { useContext, useEffect, useState } from "react";
import img from "../../images/doctor.png";
import "./DashboardSidebar.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import useAuthCheck from "../../redux/hooks/useAuthCheck";
import {
  FaTable,
  FaCalendarDay,
  FaUserInjured,
  FaHourglassStart,
  FaRegStar,
  FaUserCog,
  FaBlog,
  FaSignOutAlt,
  FaLock,
  FaHouseUser,
  FaPlus,
} from "react-icons/fa";
import { SettingContext } from "../context/setting";
import { loggedOut } from "../../service/auth.service";
import { message } from "antd";

const PatientSideBarMenus = () => {
  return (
    <>
      <li>
        <NavLink to={"/dashboard"} className="active" end>
          <FaTable className="icon" />
          <span>Dashboard</span>
        </NavLink>
      </li>
      <li>
        <NavLink to={"/dashboard/favourite"} className="active">
          <FaHouseUser className="icon" />
          <span>Favourites</span>
        </NavLink>
      </li>
      <li>
        <NavLink to={"/dashboard/profile-setting"} className="active">
          <FaUserCog className="icon" />
          <span>Profile Settings</span>
        </NavLink>
      </li>

      <li>
        <NavLink to={"/dashboard/change-password"} className="active">
          <FaLock className="icon" />
          <span>Change Password</span>
        </NavLink>
      </li>
    </>
  );
};

const DoctorSideBarMenus = ({ showPrescription, showInvoice }) => {
  return (
    <>
      <li>
        <NavLink to={"/dashboard"} className="active" end>
          <FaTable className="icon" />
          <span>Dashboard</span>
        </NavLink>
      </li>
      <li>
        <NavLink to={"/dashboard/appointments"} className="active" end>
          <FaCalendarDay className="icon" />
          <span>Appointments</span>
        </NavLink>
      </li>
      <li>
        <NavLink to={"/dashboard/my-patients"} className="active" end>
          <FaUserInjured className="icon" />
          <span>My Patients</span>
        </NavLink>
      </li>
      {showPrescription && (
        <li>
          <NavLink to={"/dashboard/prescription"} className="active" end>
            <FaUserInjured className="icon" />
            <span>Prescription</span>
          </NavLink>
        </li>
      )}
      <li>
        <NavLink to={"/dashboard/schedule"} className="active" end>
          <FaCalendarDay className="icon" />
          <span>Schedule Timings</span>
        </NavLink>
      </li>
      {showInvoice && (
        <li>
          <NavLink to={"/dashboard/invoices"} className="active" end>
            <FaHourglassStart className="icon" />
            <span>Invoices</span>
          </NavLink>
        </li>
      )}
      <li>
        <NavLink to={"/dashboard/reviews"} className="active" end>
          <FaRegStar className="icon" />
          <span>Reviews</span>
        </NavLink>
      </li>

      <li>
        <NavLink to={"/dashboard/profile-setting"} className="active" end>
          <FaUserCog className="icon" />
          <span>Profile Settings</span>
        </NavLink>
      </li>

      {/* <li>
              <NavLink to={"/dashboard/blogs"} activeClassName="active" end>
                <FaBlog className="icon" />
                <span>Blogs (Will move to Admin)</span>
              </NavLink>
            </li> */}

      <li>
        <NavLink to={"/dashboard/change-password"} className="active" end>
          <FaLock className="icon" />
          <span>Change Password</span>
        </NavLink>
      </li>
      <li>
        <NavLink to={"/dashboard/invite-patient"} className="active" end>
          <FaPlus className="icon" />
          <span>Invite Patient</span>
        </NavLink>
      </li>
      {/* <li>
        <NavLink to={"/dashboard/invite-patient"} className="active" end>
          <FaLock className="icon" />
          <span>Invite Patient</span>
        </NavLink>
      </li> */}
    </>
  );
};

const DashboardSidebar = () => {
  const { data, role } = useAuthCheck();

  const navigate = useNavigate();
  const { authChecked } = useAuthCheck();
  const [isLoggedIn, setIsLogged] = useState(false);

  const setting = useContext(SettingContext);
  const [ShowInvoice, setInvoice] = useState(setting[0].isInvoice);
  const [ShowPrescription, setPrescription] = useState(
    setting[0].isPrescription
  );

  useEffect(() => {
    setInvoice(setting[0].isInvoice);
    setPrescription(setting[0].isPrescription);
  }, [setting]);

  //console.log(setting[0], ShowInvoice, ShowPrescription);

  useEffect(() => {
    authChecked && setIsLogged(true);
  }, [authChecked]);

  const hanldeSignOut = () => {
    console.log("signout");
    loggedOut();
    message.success("Successfully Logged Out");
    setIsLogged(false);
    navigate("/");
  };
  return (
    <div className="profile-sidebar p-3 rounded">
      <div className="p-2 text-center border-bottom">
        {role === "doctor" ? (
          <div className="profile-info text-center">
            <Link to={"/"}>
              <img src={data?.img ? data?.img : img} alt="" />
            </Link>
            <div className="profile-details">
              <h5 className="mb-0">{data?.firstName + " " + data?.lastName}</h5>
              <div>
                <p className="mb-0">{data?.designation}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="profile-info text-center">
            <Link to={"/"}>
              <img src={data?.img ? data?.img : img} alt="" />
            </Link>
            <div className="profile-details">
              <h5 className="mb-0">{data?.firstName + " " + data?.lastName}</h5>
              <div className="mt-2">
                <p className=" form-text m-0">{data?.address ?? "--"}</p>
                <p className=" form-text m-0">
                  {" "}
                  {data?.city ?? "--"} , {data?.country ?? "--"}
                </p>
                <p className=" form-text m-0">{data?.email}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <nav className="dashboard-menu">
        <ul>
          {role === "patient" ? (
            <PatientSideBarMenus />
          ) : (
            <DoctorSideBarMenus
              showPrescription={ShowPrescription}
              showInvoice={ShowInvoice}
            />
          )}
          <li>
            <NavLink to={"/"}>
              <FaSignOutAlt className="icon" />
              <span onClick={hanldeSignOut}>Logout</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};
export default DashboardSidebar;
