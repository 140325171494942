import React, { useEffect } from 'react';
import './Reviews.css';
import DashboardLayout from '../DashboardLayout/DashboardLayout';
import img from '../../../images/avatar.jpg';
import { useGetDoctorReviewsQuery } from '../../../redux/api/reviewsApi';
import { FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import moment from 'moment';
import StarRatings from 'react-star-ratings';
import useAuthCheck from '../../../redux/hooks/useAuthCheck';
import { Empty } from 'antd';

const Reviews = () => {
    const { data: loginInfo } = useAuthCheck();
    console.log(loginInfo)
    const { data, isError, isLoading } = useGetDoctorReviewsQuery(loginInfo?.id);

    useEffect(() => { console.log(data) }, [data])
    let content = null;
    if (!isLoading && isError) content = <div>Something Went Wrong !</div>
    if (!isLoading && !isError && data?.length === 0) content = <Empty />
    if (!isLoading && !isError && data?.length > 0) content =
        <>
            {
                data && data.filter((item)=> item.isActive === true).map((item, key) => (
                    <div className='mb-4' key={item?.id + key}>
                        <div className='d-flex gap-3 justify-content-between'>
                            <div className='d-flex gap-4'>
                                <div className='review-img'>
                                    <img className="h-100 w-100 rounded-circle" alt="" src={item?.patient?.img ? item?.patient?.img : img} />
                                </div>
                                <div>
                                    <h5 className="text-nowrap text-capitalize">{item?.patient?.firstName + ' ' + item?.patient?.lastName}</h5>
                                    <p className={item?.isRecommended ? 'text-success' : 'text-danger'}>
                                        {item?.isRecommended ? (
                                            <>
                                               <FaRegThumbsUp /> I recommend the doctor
                                            </>
                                        ) : (
                                            <>
                                               <FaRegThumbsDown /> I do not recommend the doctor
                                            </>
                                        )}
                                    </p>

                                </div>
                            </div>

                            <div className='text-end'>
                                <div>
                                    <StarRatings
                                        rating={Number(item?.star ?? '')}
                                        starRatedColor="#f4c150"
                                        numberOfStars={5}
                                        name='rating'
                                        starDimension="15px"
                                        starSpacing="2px"
                                    />
                                </div>
                                <div className="">Reviewed {moment(item?.createdAt).fromNow()}</div>
                            </div>
                        </div>
                        <div>
                            <p className="mx-2 form-text">{item?.description}</p>
                        </div>
                        <hr />
                    </div>
                ))
            }
        </>
    return (
        <DashboardLayout>
            <div className="w-100 mb-3 rounded py-3 px-2" style={{ background: '#f8f9fa' }}>
                {content}
            </div>
        </DashboardLayout>
    )
}

export default Reviews;