import React, { useEffect, useState } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import "./Doctors.css";
import {
  AutoComplete,
  message,
  Space,
  Table,
  Tag,
  Tooltip,
  Pagination,
  Select,
  Input,
} from "antd";
import {
  FaCloudDownloadAlt,
  FaEllipsisH,
  FaRegTrashAlt,
  FaEdit,
  FaSearch,
} from "react-icons/fa";
import {
  useDeleteDoctorMutation,
  useGetDoctorsQuery,
} from "../../../redux/api/doctorApi";
import UpsertDoctor from "./UpsertDoctor";
import { useDebounced } from "../../../utils/hooks/useDebounced";

const Doctors = () => {
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedDoctorId, setSelectedDoctorId] = useState(null);

  const debouncedQuery = useDebounced({ searchQuery: value, delay: 200 });

  const {
    data: DoctorList,
    refetch,
    isError,
    isLoading,
  } = useGetDoctorsQuery({
    searchTerm: query,
    limit: size,
    page,
  });

  const { data: AutoCompleteList } = useGetDoctorsQuery({
    searchTerm: debouncedQuery,
    limit: size,
    page,
  });

  const [deleteDoctor] = useDeleteDoctorMutation();

  useEffect(() => {
    if (DoctorList?.doctors) {
      const data = DoctorList.doctors.map((doctor) => ({
        key: doctor.id,
        name: doctor.firstName + " " + doctor.lastName,
        email: doctor.email,
        gender: doctor.gender,
        address: doctor.address,
        degree: doctor.degree,
      }));
      setDataList(data);
    }
  }, [DoctorList]);

  useEffect(() => {
    if (AutoCompleteList?.doctors) {
      setOptions(
        AutoCompleteList.doctors.map((doctor) => ({
          key: doctor.id,
          value: doctor.firstName + " " + doctor.lastName,
        }))
      );
    }
  }, [AutoCompleteList]);

  const deleteHandler = async (id) => {
    try {
      const res = await deleteDoctor(id);
      if (res.error) {
        message.error(res.error.data.message);
      } else {
        message.success("Successfully Deleted !!");
      }
      refetch();
    } catch (error) {
      message.error(error.message);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Degree",
      key: "degree",
      dataIndex: "degree",
      render: (_, record) => (
        <Tag color={record.degree?.length > 5 ? "geekblue" : "green"}>
          {record.degree}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <a
              onClick={() => {
                setSelectedDoctorId(record.key);
                setShowForm(true);
              }}
            >
              <FaEdit />
            </a>
          </Tooltip>
          <Tooltip title="Delete" onClick={() => deleteHandler(record.key)}>
            <a>
              <FaRegTrashAlt />
            </a>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const onSearch = (searchText) => {
    setOptions(searchText ? [{ value: searchText }] : []);
  };

  const onSelect = (data) => {
    setValue(data); // Update the value state
  };

  const onChange = (data) => {
    if (data === "") {
      setValue(""); // Clear the value state
    }
    setValue(data);
  };

  const handleSearch = () => {
    setQuery(value); // Set the query state to the current input value
    setPage(1); // Reset to the first page
    refetch();
  };
  const handleReset = () => {
    setQuery(""); // Clear the query state
    setValue(""); // Clear the value state
    setPage(1); // Reset to the first page
    refetch(); // Refetch the data
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
    refetch();
  };

  return (
    <AdminLayout>
      {showForm ? (
        <UpsertDoctor setShowForm={setShowForm} id={selectedDoctorId} />
      ) : (
        <div className=" ">
          <h1 className="text-black inline ">Doctors</h1>
          <button
            className="float-right bg-[#2c657b] p-2 rounded text-white"
            onClick={() => setShowForm(true)}
          >
            New Doctor
          </button>

          <div className="flex items-center mt-4 mb-4">
            <AutoComplete
              onChange={onChange}
              onSelect={onSelect}
              onSearch={onSearch}
              value={value}
              options={options}
              style={{ width: 200 }}
              className="h-full"
              placeholder="Search Doctor"
            />

            <button
              className="bg-[#2c657b] p-2 rounded ml-2 text-white h-full"
              onClick={handleSearch}
            >
              <FaSearch />
            </button>

            <button
              className="bg-[#2c657b] p-2 rounded ml-2 text-white h-[2rem]"
              onClick={handleReset}
            >
              Reset
            </button>
          </div>

          <div className="w-full ">
            <div className="overflow-x-auto">
              <Table
                columns={columns}
                dataSource={dataList}
                loading={isLoading}
                pagination={false}
                className="min-w-full bg-[white]"
              />
            </div>
            <div className="flex justify-center md:justify-end mt-4">
              <Pagination
                className="pagination"
                current={page}
                pageSize={size}
                total={DoctorList?.meta?.total || 0}
                onChange={handlePageChange}
                showSizeChanger
                onShowSizeChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      )}
    </AdminLayout>
  );
};

export default Doctors;
