import React from "react";
import "./index.css";
import Header from "../Shared/Header/Header";
import SubHeader from "../Shared/SubHeader";
import Footer from "../Shared/Footer/Footer";
import { Empty } from "antd";
import { GiHeartOrgan} from "react-icons/gi";
import { FaEye } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { truncate } from "../../utils/truncate";
import { useGetAllSpecialistQuery } from "../../redux/api/specialites";

const Specialities = () => {
  const { data, isLoading, isError } = useGetAllSpecialistQuery();
  const specialist = data?.specialist;

  let content = null;
  if (!isLoading && isError) content = <div>Something Went Wrong !</div>;
  if (!isLoading && !isError && specialist?.length === 0)
    content = (
      <div>
        <Empty />
      </div>
    );
  if (!isLoading && !isError && specialist?.length > 0)
    content = (
      <>
        {specialist &&
          specialist.map((item, key) => (
            <div className="col-md-4 col-sm-12 mt-3" key={key}>
              <div className="specialist-image" style={{position: 'relative'}}>
                <img 
                  src={item?.img}
                  alt="{item.name}"
                  style={{ width: "100%", height: "12rem", objectFit: "cover" }}
                />
                <div className="specialist-overlay">
                <Link to={`/specialites/${item?.id}`}>
                  <FaEye style={{fontSize: '2rem', color: 'var(--white)'}}  />
                </Link>
                </div>
                <div className="specialist-circle">
                  <img src={item?.icon} alt="icon" style={{ width: "2rem", height: '2rem', objectFit:'cover' }} />

                </div>
              </div>

              <div className="mt-5">
                <span className="doctor-name">{item?.name}</span>
                <p style={{  textAlign: 'justify'}} >
                  {truncate(item?.description, 129)}
                </p>
              </div>
            </div>
          ))}
      </>
    );
  return (
    <>
      <Header />
      <SubHeader
        title="Clinic And Specialities"
        // subtitle="Lorem ipsum dolor sit amet."
      />
      <div className="container ">
        <div className="row mt-5 mb-5">{content}</div>
      </div>
      <Footer />
    </>
  );
};

export default Specialities;
