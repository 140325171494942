import { Empty, Tabs } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetDoctorQuery } from "../../../redux/api/doctorApi";
import Footer from "../../Shared/Footer/Footer";
import Header from "../../Shared/Header/Header";
import SubHeader from "../../Shared/SubHeader";
import SearchContent from "../SearchDoctor/SearchContent";
import Availibility from "./Availibility";
import "./index.css";
import Location from "./Location";
import OverView from "./OverView";
import Review from "./Review";

const DoctorProfile = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useGetDoctorQuery(id);
  let content = null;
  // if (!isLoading && isError) content = <div>{message.error('Something went Wrong!')}</div>
  if (!isLoading && !isError && data?.id === undefined) content = <Empty />;
  if (!isLoading && !isError && data?.id)
    content = <SearchContent data={data} />;

  const items = [
    {
      key: "1",
      label: "Overview",
      children: <OverView data={data} />,
    },
    {
      key: "2",
      label: "Locations",
      children: <Location doctorId={id} />,
    },
    {
      key: "3",
      label: "Reviews",
      children: <Review doctorId={id} />,
    },
    {
      key: "4",
      label: "Availability",
      children: <Availibility doctorId={id} />,
    },
  ];

  return (
    <>
      <Header />
      <SubHeader
        title="Doctor Details"
        subtitle="Learn more about our medical experts and their specialties."
      />
      <div
        className="container"
        style={{ marginBottom: "4rem", marginTop: "6rem" }}
      >
        {content}
        <div
          className="mt-5 "
          style={{
            marginBottom: "7rem",
            backgroundColor: "#227fa42e",
            color: "var(--white)",
            padding: "2rem",
            borderRadius: "3rem",
          }}
        >
          <Tabs defaultActiveKey="1" items={items} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DoctorProfile;
