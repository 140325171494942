import { useState, useEffect } from "react"
import { Box, Button, ButtonGroup, Card, CardContent, CardHeader, Container, Divider } from "@mui/material"
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar"
import format from "date-fns/format"
import parse from "date-fns/parse"
import startOfWeek from "date-fns/startOfWeek"
import getDay from "date-fns/getDay"
import enUS from "date-fns/locale/en-US"
import "react-big-calendar/lib/css/react-big-calendar.css"
import EventInfo from "./EventInfo"
import AddEventModal from "./AddEventModal"
import EventInfoModal from "./EventInfoModal"
import AddDatePickerEventModal from "./AddDatePickerEventModal"
import { useCreateTimeSlotMutation, useDeleteTimeSlotMutation, useGetDoctorTimeSlotQuery } from '../../../redux/api/timeSlotApi';
import { message } from "antd"

const locales = {
  "en-US": enUS,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

export const generateId = () => (Math.floor(Math.random() * 10000) + 1).toString()

const initialEventFormState = {
  description: "",
  maximumPatient: 1,
}

const initialDatePickerEventFormData = {
  description: "",
  maximumPatient: 1,
  start: undefined,
  end: undefined,
}

const EventCalendar = () => {
  const [openSlot, setOpenSlot] = useState(false)
  const [openDatepickerModal, setOpenDatepickerModal] = useState(false)
  const [eventInfoModal, setEventInfoModal] = useState(false)
  const [currentEvent, setCurrentEvent] = useState(null)
  const [events, setEvents] = useState([])
  const [eventFormData, setEventFormData] = useState(initialEventFormState)
  const [datePickerEventFormData, setDatePickerEventFormData] = useState(initialDatePickerEventFormData)

  const { data, isLoading, isError } = useGetDoctorTimeSlotQuery();
  const [createTimeSlot] = useCreateTimeSlotMutation();
  const [deleteTimeSlot] = useDeleteTimeSlotMutation();
  const [currentView, setCurrentView] = useState(Views.DAY);

  useEffect(() => {
    if (data) {
      const mappedEvents = data.map(slot => 
        slot.timeSlot.map(time => ({
          id: time.id, // Optional: helps with unique identification
          description: time.description || "No Description", // Display description or a default text
          start: new Date(time.startTime), // Ensure start is a Date object
          end: new Date(time.endTime), // Ensure end is a Date object
          doctorId: slot.doctorId, // Add any additional information you might need
          maximumPatient: time.maximumPatient, // Additional property if needed
        }))
      ).flat(); // Flatten the array of arrays into a single array
      console.log(mappedEvents)
      
      setEvents(mappedEvents);
    }
  }, [data]);

  const handleSelectSlot = (event) => {
    if (currentView !== Views.MONTH && event.box) {
      setOpenSlot(true);
      setCurrentEvent(event);
    }
  }

  const handleSelectEvent = (event) => {
    setCurrentEvent(event)
    setEventInfoModal(true)
  }

  const handleClose = () => {
    setEventFormData(initialEventFormState)
    setOpenSlot(false)
  }

  const handleDatePickerClose = () => {
    setDatePickerEventFormData(initialDatePickerEventFormData)
    setOpenDatepickerModal(false)
  }

  const onAddEvent = async (e) => {
    e.preventDefault()
    const data = {
      day: format(new Date(currentEvent?.start), "yyyy-MM-dd"), // Use local date format
      timeSlot: [
        {
          startTime: format(new Date(currentEvent?.start), "yyyy-MM-dd'T'HH:mm:ss"), // Use local date format
          endTime: format(new Date(currentEvent?.end), "yyyy-MM-dd'T'HH:mm:ss"), // Use local date format
          maximumPatient: eventFormData.maximumPatient,
          description: eventFormData.description,
        },
      ],
    }
    
    try {
      await createTimeSlot({ data })
      const newEvents = [...events, { ...data, _id: generateId(), start: new Date(currentEvent?.start), end: new Date(currentEvent?.end) }]
      setEvents(newEvents)
      console.log("Event added:", newEvents)
      handleClose()
    } catch (error) {
      console.error("Error adding event:", error)
    }
  }

  const onAddEventFromDatePicker = async (e) => {
    e?.preventDefault()
    
    const setMinToZero = (date) => {
      date?.setSeconds(0)
      return date
    }
    const data = {
      day: format(new Date(datePickerEventFormData.start), "yyyy-MM-dd"), // Use local date format
      timeSlot: [
        {
          startTime: format(setMinToZero(new Date(datePickerEventFormData.start)), "yyyy-MM-dd'T'HH:mm:ss"), // Use local date format
          endTime: format(setMinToZero(new Date(datePickerEventFormData.end)), "yyyy-MM-dd'T'HH:mm:ss"), // Use local date format
          description: datePickerEventFormData.description,
          maximumPatient: datePickerEventFormData.maximumPatient,
        },
      ],
    }

    try {
      await createTimeSlot({ data })
      const newEvents = [...events, { ...data, _id: generateId(), start: new Date(datePickerEventFormData.start), end: new Date(datePickerEventFormData.end) }]
      setEvents(newEvents)
      setDatePickerEventFormData(initialDatePickerEventFormData)
    } catch (error) {
      console.error("Error adding event from date picker:", error)
    }
  }

  const onDeleteEvent = async () => {
    try {
      const res = await deleteTimeSlot(currentEvent.id)
      if (res.error) {
        message.error(res.error.data.message);
      } else {
        setEvents(events.filter((e) => e.id !== currentEvent?.id))
      
        message.success("TimeSlot Successfully Deleted !!");
      }
    } catch (error) {
      message.error(error.message);
    }
    setEventInfoModal(false)
  }

  return (
    <Box mt={2} mb={2} component="main" sx={{ flexGrow: 1, py: 8 }}>
      <Container maxWidth={false}>
        <Card>
          <CardHeader title="Calendar" subheader="Create Events and Todos and manage them easily" />
          <Divider />
          <CardContent>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <ButtonGroup size="large" variant="contained" aria-label="outlined primary button group">
                <Button onClick={() => setOpenDatepickerModal(true)} size="small" variant="contained">
                  Add event
                </Button>
              </ButtonGroup>
            </Box>
            <Divider style={{ margin: 10 }} />
            <AddEventModal
              open={openSlot}
              handleClose={handleClose}
              eventFormData={eventFormData}
              setEventFormData={setEventFormData}
              onAddEvent={onAddEvent}
            />
            <AddDatePickerEventModal
              open={openDatepickerModal}
              handleClose={handleDatePickerClose}
              datePickerEventFormData={datePickerEventFormData}
              setDatePickerEventFormData={setDatePickerEventFormData}
              onAddEvent={onAddEventFromDatePicker}
            />
            <EventInfoModal
              open={eventInfoModal}
              handleClose={() => setEventInfoModal(false)}
              onDeleteEvent={onDeleteEvent}
              currentEvent={currentEvent}
            />
            <Calendar
              localizer={localizer}
              events={events}
              step={60}
              timeslots={1}
              onSelectEvent={handleSelectEvent}
              onSelectSlot={(slotInfo) => {
                  handleSelectSlot(slotInfo)
              }}
              selectable
              views={["month", "week", "day"]} // Include the month view but make it read-only
              startAccessor="start"
              components={{ event: EventInfo }}
              endAccessor="end"
              defaultView={Views.DAY} // Default view is day
              eventPropGetter={(event) => ({
                style: {
                  backgroundColor: "#b64fc8",
                  borderColor: "#b64fc8",
                },
              })}
              style={{ height: 900 }}
              onView={setCurrentView}
            />
          </CardContent>
        </Card>
      </Container>
    </Box>
  )
}

export default EventCalendar
