import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Checkbox, message, Modal, Space, Table, Tooltip } from "antd";
import { Link } from "react-router-dom";
import ImageUpload from "../../UI/form/ImageUpload";
import dImage from "../../../images/doctor.png";
import "./UpsertInsuranceCompany.css";
import { FaAngleLeft, FaEdit, FaRegTrashAlt } from "react-icons/fa";
import {
  useCreateInsuranceCompanyMutation,
  useGetInsuranceCompanyQuery,
  useUpdateInsuranceCompanyMutation,
} from "../../../redux/api/InsuranceCompanyApi";

const UpsertInsuranceCompany = ({ setShowForm, id }) => {
  const [updateInsuranceCompany, { isLoading, isSuccess, isError, error }] =
    useUpdateInsuranceCompanyMutation();
  const [
    createInsuranceCompany,
    {
      isLoading: createIsLoading,
      isSuccess: createIsSuccess,
      isError: createIsError,
    },
  ] = useCreateInsuranceCompanyMutation();
  const {
    data,
    isLoading: isLoadingData,
    isError: isErrorData,
  } = useGetInsuranceCompanyQuery(id, { skip: !id });

  const { register, handleSubmit, setValue, reset } = useForm();
  const {
    register: planRegister,
    handleSubmit: planHandleSubmit,
    setValue: planSetValue,
    reset: planReset,
  } = useForm();
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [editingPlan, setEditingPlan] = useState(null);

  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
    setEditingPlan(null);
    planReset();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record, index) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <a onClick={() => editPlan(record, index)}>
              <FaEdit />
            </a>
          </Tooltip>
          <Tooltip title="Delete">
            <a onClick={() => removePlan(index)}>
              <FaRegTrashAlt />
            </a>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const editPlan = (plan, index) => {
    setEditingPlan({ ...plan, index });
    planSetValue("name", plan.name);
    planSetValue("description", plan.description);
    showModal();
  };

  const addPlan = (formData) => {
    if (editingPlan !== null) {
      setPlanList((prev) =>
        prev.map((plan, index) =>
          index === editingPlan.index ? { ...formData } : plan
        )
      );
    } else {
      setPlanList((prev) => [...prev, { ...formData }]);
    }
    handleCancel();
  };

  const removePlan = (index) => {
    setPlanList((prev) => prev.filter((_, i) => i !== index));
  };

  const onPlanSubmit = async (formData) => {
    addPlan(formData);
  };

  useEffect(() => {
    if (id && data) {
      const { name, description, logo, isPopular, isEnabled, insurancePlans } =
        data;
      setPlanList(insurancePlans || []);
      setValue("name", name);
      setValue("description", description);
      setValue("isPopular", isPopular);
      setValue("isEnabled", isEnabled);
      setSelectedImage(logo || dImage);
    } else {
      reset();
    }
  }, [id, data, setValue, reset]);

  const onSubmit = async (formData) => {
    const newObj = {
      ...formData,
      insurancePlans: planList,
      price: formData.price?.toString(),
    };
    const changedValue = Object.fromEntries(
      Object.entries(newObj).filter(([key, value]) => value !== "")
    );

    const finalFormData = new FormData();
    selectedImage && finalFormData.append("file", file);
    finalFormData.append("data", JSON.stringify(changedValue));
    if (id) {
      await updateInsuranceCompany({ data: finalFormData, id });
    } else {
      await createInsuranceCompany({ data: finalFormData });
    }
    setShowForm(false);
  };

  useEffect(() => {
    if (!isLoading && isError) {
      message.error(error?.message);
    }
    if (isSuccess) {
      message.success("Successfully saved changes!");
    }
  }, [isLoading, isError, error, isSuccess]);

  useEffect(() => {
    if (!createIsLoading && createIsError) {
      message.error(error?.data?.message);
    }
    if (createIsSuccess) {
      message.success("Successfully created insurance company!");
    }
  }, [createIsLoading, createIsError, createIsSuccess]);

  return (
    <>
      <div style={{ marginBottom: "10rem" }}>
        <div
          className="w-100 mb-3 rounded mb-5 p-2"
          style={{ background: "#f8f9fa" }}
        >
          <div className="top">
            <h5 className="text-title mb-2 mt-3">
              {id ? "Update" : "Create"} Insurance Company
            </h5>
            <button
              className="getbackButton"
              onClick={() => setShowForm(false)}
            >
              <FaAngleLeft className="inline" /> Go Back
            </button>
          </div>
          <form className="row form-row" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-md-12 mb-5">
              <div className="form-group">
                <div className="change-avatar d-flex gap-2 align-items-center">
                  <Link to="/" className="my-3 patient-img">
                    <img src={selectedImage || dImage} alt="" />
                  </Link>
                  <div className="mt-3">
                    <ImageUpload
                      setSelectedImage={setSelectedImage}
                      setFile={setFile}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group mb-2 card-label">
                <label>
                  Name <span className="text-danger">*</span>
                </label>
                <input
                  defaultValue={data?.name}
                  {...register("name")}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group mb-2 card-label">
                <label>
                  Is Popular &nbsp;
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    defaultChecked={data?.isPopular}
                    {...register("isPopular")}
                  />
                </label>
              </div>
              <div className="form-group mb-2 card-label">
                <label>
                  Is Enabled &nbsp;
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    defaultChecked={data?.isEnabled}
                    {...register("isEnabled")}
                  />
                </label>
              </div>
            </div>

            <div className="col-md-12">
              <div className="card mb-2 mt-2">
                <div className="card-body">
                  <h6 className="card-title text-secondary">About Company</h6>
                  <div className="form-group mb-2 card-label">
                    <label>Description</label>
                    <textarea
                      defaultValue={data?.description}
                      {...register("description")}
                      className="form-control"
                      rows={5}
                    />
                  </div>
                </div>
              </div>
            </div>
            {id && (
              <div className="text-right">
                <Button type="primary" onClick={showModal}>
                  Add Plan
                </Button>
              </div>
            )}
            <Table
              columns={columns}
              dataSource={planList}
              loading={isLoading}
              rowKey="id"
            />

            <div className="text-center my-3">
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                loading={isLoading || createIsLoading}
              >
                {id
                  ? isLoading
                    ? "Updating ..."
                    : "Update Changes"
                  : createIsLoading
                  ? "Saving ..."
                  : "Save Changes"}
              </Button>
              <Button
                htmlType="button"
                className="ms-2"
                size="large"
                onClick={() => setShowForm(false)}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>

      <Modal
        open={open}
        title={editingPlan ? "Edit Plan" : "Add Plan"}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={planHandleSubmit(onPlanSubmit)}
          >
            {editingPlan ? "Update" : "Add"}
          </Button>,
        ]}
      >
        <div className="w-100 rounded p-2" style={{ background: "#f8f9fa" }}>
          <form
            className="row form-row"
            onSubmit={planHandleSubmit(onPlanSubmit)}
          >
            <div className="col-md-12">
              <div className="form-group mb-2 card-label">
                <label>
                  Name <span className="text-danger">*</span>
                </label>
                <input
                  {...planRegister("name", { required: true })}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="card mb-2 mt-2">
                <div className="card-body">
                  <h6 className="card-title text-secondary">About Plan</h6>
                  <div className="form-group mb-2 card-label">
                    <label>Description</label>
                    <textarea
                      {...planRegister("description")}
                      className="form-control"
                      rows={5}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default UpsertInsuranceCompany;
