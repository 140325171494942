import { Empty, message } from "antd";
import moment from "moment";
import React from "react";
import {
  FaBusinessTime,
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
} from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useGetSingleBlogQuery } from "../../redux/api/blogApi";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
import SubHeader from "../Shared/SubHeader";
import BlogAside from "./BlogAside";
import BlogComment from "./BlogComment";

const BlogDetails = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useGetSingleBlogQuery(id);

  let content = null;
  if (!isLoading && isError)
    content = <div>{message.error("Something went Wrong!")}</div>;
  if (!isLoading && !isError && data?.id === undefined) content = <Empty />;
  if (!isLoading && !isError && data?.id)
    content = (
      <div>
        <div style={{ maxHeight: "40rem" }}>
          {data?.img && (
            <img
              src={data?.img}
              alt="blog Image"
              width={800}
              height={500}
              className="image-hover w-100 rounded"
              style={{ objectFit: "cover" }}
            />
          )}
        </div>

        <div className="d-flex mt-4">
          {/* <div className="d-flex gap-1 text-muted align-items-center justify-content-center">
            <FaRegUser className="form-text" />{" "}
            <span className="form-text">
              {data?.user?.firstName + " " + data?.user?.lastName}
            </span>{" "}
          </div> */}
          <div className="d-flex gap-1 text-muted align-items-center justify-content-center ms-3">
            <FaBusinessTime className="form-text" />
            <span className="form-text">
              {moment(data?.cretedAt).format("LL")}
            </span>
          </div>
        </div>

        <div>
          <h5
            className="text-start mb-1 mt-3"
            style={{
              color: "var(--primary)",
              textTransform: "capitalize",
              fontSize: "1.6rem",
            }}
          >
            {data?.title}
          </h5>
          <div>
            <p className="text-secondary text-start">{data?.description}</p>
          </div>
        </div>
      </div>
    );
  // <div className="card shadow-sm text-center border-0 rounded-bottom">

  //     <div className="flex-column card-header p-0 border-0 d-flex justify-content-center align-items-center" style={{ overflow: 'hidden', maxHeight: '40rem' }}>
  //         {data?.img && <img src={data?.img} alt="blog Image" width={800} height={500} className="image-hover w-100 rounded-top" style={{ objectFit: 'cover' }} />}
  //     </div>

  //     <div className="card-body p-0">
  //         <div className="p-2 my-2">
  //             <h5 className="text-start mb-1" style={{ color: '#05335c' }}>{data?.title}</h5>
  //             <div className="d-flex text-start gap-2">
  //                 <div className="d-flex gap-1 text-muted align-items-center justify-content-center">
  //                     <FaRegUser className='form-text' />
  //                     <span className="form-text">{data?.user?.firstName + ' ' + data?.user?.lastName}</span>
  //                 </div>
  //                 <div className="d-flex gap-1 text-muted align-items-center justify-content-center">
  //                     <FaBusinessTime className='form-text' />
  //                     <span className="form-text">{moment(data?.cretedAt).format('LL')}</span>
  //                 </div>
  //             </div>
  //             <hr className="my-1 p-0" />
  //         </div>
  //         <div className="px-3 my-3">
  //             <p className="text-secondary text-start">{data?.description}</p>
  //         </div>
  //     </div>
  //     <div className="d-flex gap-2 ms-2 px-2 py-3">
  //         <span className="">Tags:</span>
  //         <div className="d-flex gap-2">
  //             {
  //                 Array(4).fill(null).map((_item, index) => (<h5 key={index + 2}>#tag{index}</h5>))
  //             }
  //         </div>
  //     </div>
  // </div>
  return (
    <>
      <Header />
      <SubHeader
        title="Blog Details"
        subtitle="Insights, tips, and the latest updates from our healthcare experts."
      />

      <div className="container" style={{ marginTop: 100 }}>
        <div className="row mx-2">
          <div className="col-md-9 col-sm-12">
            {content}
            <hr style={{ borderTop: "1px solid #808080b8" }} />
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex gap-2 align-items-center">
                <span
                  className="tags"
                  style={{
                    color: "var(--primary)",
                    textShadow: "4px 4px 5px rgba(0, 5, 0, 0.28)",
                    fontWeight: "600",
                  }}
                >
                  Tags:
                </span>{" "}
                <div className="d-flex gap-2">
                  {" "}
                  {Array(4)
                    .fill(null)
                    .map((_item, index) => (
                      <div
                        key={index + 2}
                        style={{
                          background: "transparent",
                          border: "1px solid var(--primary)",
                          padding: "0.3rem",
                          borderRadius: "1rem",
                        }}
                      >
                        <span style={{ fontSize: "0.8rem" }}>
                          #Hospital{index}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              <div className="col-md-5 col-lg-4 ml-lg-0 text-end text-md-end">
                <span
                  className=" me-2"
                  style={{
                    color: "var(--primary)",
                    textShadow: "4px 4px 5px rgba(0, 5, 0, 0.28)",
                    fontWeight: "600",
                  }}
                >
                  Share On{" "}
                </span>
                <a className="me-2">
                  <FaFacebookSquare
                    style={{ fontSize: "2rem", color: "var(--primary)" }}
                  />
                </a>
                <a className="me-2">
                  <FaInstagramSquare
                    style={{ fontSize: "2rem", color: "var(--primary)" }}
                  />
                </a>
                <a>
                  <FaLinkedin
                    style={{ fontSize: "2rem", color: "var(--primary)" }}
                  />
                </a>
              </div>
            </div>
            <hr style={{ borderTop: "1px solid #808080b8" }} />
          </div>
          <div className="col-md-3 col-sm-12">
            <BlogAside setSearchTerm={undefined} />
          </div>
        </div>
        <BlogComment />
      </div>
      <Footer />
    </>
  );
};

export default BlogDetails;
