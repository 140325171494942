import React from "react";
import "./Footer.css";
// import logo from "../../../images/logo-footer.svg";
import logo from "../../../images/logo-dummy.png";
import { Link } from "react-router-dom";
import { FaAngleDoubleRight } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer position-relative">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-6 col-md-6 col-lg-3">
              <div className="footer-widget footer-about">
                <div className="footer-logo">
                  <Link to={"/"}>
                    <img src={logo} alt="logo" style={{ maxWidth: "160px" }} />
                  </Link>
                </div>
                <div className="footer-about-content">
                  <p className="form-text" style={{ maxWidth: 200 }}>
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.{" "} */}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-6 col-lg-3">
              <div className="footer-widget footer-menu">
                <h4 className="footer-title">For Patients</h4>
                <ul>
                  <li>
                    <Link to={"/doctors"}>
                      <FaAngleDoubleRight className="icon inline" /> Search for
                      Doctors
                    </Link>
                  </li>
                  <li>
                    <Link to={"/login"}>
                      <FaAngleDoubleRight className="icon inline" /> Login
                    </Link>
                  </li>
                  <li>
                    <Link to={"/login"}>
                      <FaAngleDoubleRight className="icon inline" /> Register
                    </Link>
                  </li>
                  <li>
                    <Link to={"/doctors"}>
                      <FaAngleDoubleRight className="icon inline" /> Booking
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"}>
                      <FaAngleDoubleRight className="icon inline" /> Patient
                      Dashboard
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-6 col-md-6 col-lg-3">
              <div className="footer-widget footer-menu">
                <h4 className="footer-title">For Doctors</h4>
                <ul>
                  <li>
                    <Link to={"/"}>
                      <FaAngleDoubleRight className="icon inline" />{" "}
                      Appointments
                    </Link>
                  </li>
                  <li>
                    <Link to={"/login"}>
                      <FaAngleDoubleRight className="icon inline" /> Login
                    </Link>
                  </li>
                  <li>
                    <Link to={"/register"}>
                      <FaAngleDoubleRight className="icon inline" /> Register
                    </Link>
                  </li>
                  <li>
                    <Link to={"/dashboard"}>
                      <FaAngleDoubleRight className="icon inline" /> Doctor
                      Dashboard
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-6 col-md-6 col-lg-3">
              <div className="footer-widget footer-menu">
                <h4 className="footer-title">Contact Us</h4>
                <ul>
                  <li>
                    <Link to={"/about"}>
                      <FaAngleDoubleRight className="icon inline" /> About Us
                    </Link>
                  </li>
                  <li>
                    <p className="text-white">
                      {" "}
                      121, Apartment 123,
                      <br /> Dummy Anytown, 12345
                    </p>
                  </li>
                  <li>
                    <p className="text-white">
                      <i className="fas fa-phone-alt"></i>
                      +88 017 51 040425
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className="col-6 col-md-6 col-lg-3">
              <div className="footer-widget footer-contact">
                <h4 className="footer-title mt-3 mt-md-0">Contact Us</h4>
                <div className="footer-contact-info">
                  <div className="footer-address">
                    <span>
                      <i className="fas fa-map-marker-alt"></i>
                    </span>
                    <p>
                      {" "}
                      121, Mirzapure Union office,
                      <br /> Sylhet, Bangladesh 03214{" "}
                    </p>
                  </div>
                  <p>
                    <i className="fas fa-phone-alt"></i>
                    +88 017 51 040425
                  </p>
                  <p className="mb-0">
                    <i className="fas fa-envelope"></i>
                    ujjalzaman@gmail.com
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container-fluid">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <div className="copyright-text">
                  <p className="mb-0">
                    <a href="templateshub.net">
                      <div className="copyRight text-end">
                        <div>
                          Copyright {new Date().getFullYear()} All Rights
                          Reserved
                        </div>
                      </div>
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="copyright-menu">
                  <ul className="policy-menu d-flex justify-content-start">
                    <Link to={"/"} className="text-white me-2">
                      Terms and Conditions
                    </Link>
                    <Link to={"/"} className="text-white">
                      Policy
                    </Link>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
