import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Button } from 'antd';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: -3.745,
  lng: -38.523
};

const GoogleMapComponent = ({ location, setLocation, disableFixButton = true }) => {
  const [userLocation, setUserLocation] = useState(null);
  const [zoom, setZoom] = useState(15);
  useEffect(() => {
     if(disableFixButton){
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting user location:", error.message);
        }
      );
     }
  }, []);
  const handleFixLocation = () => {
    if (userLocation) {
      setLocation(userLocation)
      setZoom(15); 
    }
  };
  return (
    <div className='text-right'>
      {
        disableFixButton &&  <Button  type='primary' className='w-25 m-3' onClick={handleFixLocation}>Fix My Location</Button>
      
      }
      <GoogleMap
      
        mapContainerStyle={containerStyle}
        center={location || center}
       zoom={zoom}
        onClick={(e) =>{
          if(disableFixButton){
            setLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() })
          }
        }}
      >
        {location && <Marker position={location} />}
      </GoogleMap>  
    </div>
  );
};

export default GoogleMapComponent;
