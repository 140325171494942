import React, { useEffect, useState } from "react";
import { SettingContext } from "../../context/setting";
import { useContext } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import { AutoComplete, message, Space, Switch, Table, Tooltip } from "antd";
import "./Setting.css";
import { useUpdateSettingMutation } from "../../../redux/api/settingApi";
import {
  FaCloudDownloadAlt,
  FaEdit,
  FaEllipsisH,
  FaRegTrashAlt,
} from "react-icons/fa";
import {
  useDeletePaymentOptionMutation,
  useGetPaymentOptionsQuery,
  useUpdatePaymentOptionMutation,
} from "../../../redux/api/paymentOptionApi";
import { compose } from "@reduxjs/toolkit";
import { json } from "react-router-dom";

const Setting = () => {
  const setting = useContext(SettingContext);
  const { data: paymentOptions, refetch } = useGetPaymentOptionsQuery();
  const [deletePaymentOption] = useDeletePaymentOptionMutation();
  const [updatePaymentOption] = useUpdatePaymentOptionMutation();

  const [value, setValue] = useState([]);
  const [updateSetting, { isLoading, isSuccess, isError, error }] =
    useUpdateSettingMutation();
  useEffect(() => {
    if (!isLoading && isError) {
      message.error(error?.data?.message);
    }
    if (isSuccess) {
      message.success("Successfully Changed Saved !");
    }
  }, [isLoading, isError, error, isSuccess]);
  const onDisplayPriceChange = (checked) => {
    Update({ isDisplayPrice: checked });
  };
  const onInvoiceChange = (checked) => {
    Update({ isInvoice: checked });
  };
  const onPrescriptionChange = (checked) => {
    Update({ isPrescription: checked });
  };

  const onPaymentEnabledChange = (checked) => {
    Update({ isPaymentEnabled: checked });
  };

  const Update = async (data) => {
    await updateSetting({ data: data });
  };

  console.log(paymentOptions);

  useEffect(() => {
    let data = paymentOptions?.PaymentOptions?.map((option) => {
      return {
        key: option.id,
        PaymentMethod: option.name,
        description: option.description,
        isActive: option.isEnabled,
      };
    });
    setValue(data);
  }, [paymentOptions]);

  const deleteHandler = async (id) => {
    // message.loading("Deleting ...");
    try {
      const res = await deletePaymentOption(id);
      if (res) {
        message.success("Successfully Deleted !!");
        refetch();
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleActiveChange = async (isActive, recordId) => {
    const res = await updatePaymentOption({
      data: { isEnabled: isActive },
      id: recordId,
    });
    if (res) {
      console.log(res);
      message.success("Successfully Updated !!");
      refetch();
    }
  };

  const columns = [
    {
      title: "Payment Method",
      dataIndex: "PaymentMethod",
      key: "PaymentMethod",
      render: (name) => <a>{name}</a>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "descritption",
      render: (descritption) => <>{descritption?.substring(0, 10)}...</>,
    },
    {
      title: "Is Active",
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive, record) => (
        <Switch
          checked={isActive}
          onChange={(checked) => handleActiveChange(checked, record.key)}
        />
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <Tooltip title="Edit">
            <a
              onClick={() => {
                setSelectedHospitalId(record.key); // Set the selected doctor ID
                setShowForm(true);
              }}
            >
              <FaEdit />
            </a>
          </Tooltip> */}
          <Tooltip title="Delete" onClick={() => deleteHandler(record.key)}>
            <a>
              <FaRegTrashAlt />{" "}
            </a>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <AdminLayout>
        <div className="doctorContainer">
          <div className="doctorHeader">
            <h2 class="doctortitle text-black">Setting</h2>
          </div>
          <div className="doctorSearchRow mb-3">
            <div className="text-black">
              <span> Invoice </span>
              <Switch onChange={onInvoiceChange} value={setting[0].isInvoice} />
            </div>
            <div className="text-black">
              <span> Prescription </span>
              <Switch
                onChange={onPrescriptionChange}
                value={setting[0].isPrescription}
              />
            </div>
            <div className="text-black">
              <span> Display Price </span>
              <Switch
                onChange={onDisplayPriceChange}
                value={setting[0].isDisplayPrice}
              />
            </div>
            <div className="text-black">
              <span> Payment Enabled </span>
              <Switch
                onChange={onPaymentEnabledChange}
                value={setting[0].isPaymentEnabled}
              />
            </div>
            <div className="w-100">
              <Table columns={columns} dataSource={value} />
            </div>
          </div>
        </div>
        <div className="doctorSearchRow mb-3">
          {/* <AutoComplete
            className="auto-search"
            options={options}
            onSelect={onSelect}
            onSearch={(text) => setOptions(getPanelValue(text))}
            placeholder="input here"
          /> */}
          {/* <div className="rightSearch">
            <span className="text-white">
              <Tooltip title="Export PDf">
                <FaCloudDownloadAlt />
              </Tooltip>
              Import
            </span>
            <span className="text-white">
              More
              <FaEllipsisH />
            </span>
          </div> */}
        </div>
      </AdminLayout>
    </>
  );
};
export default Setting;
