import React, { useEffect } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import userImg from "../../../images/doctor.png";
import "./Dashboard.css";
import { FaUserMd, FaUserAlt } from "react-icons/fa";
import { useGetAllHospitalQuery } from "../../../redux/api/hospital";
import { useGetDoctorsQuery } from "../../../redux/api/doctorApi";
import {
  useGetAllPatientQuery,
  useGetPatientQuery,
} from "../../../redux/api/patientApi";
import { useGetAllDoctorAppointmentsQuery } from "../../../redux/api/appointmentApi";

const AdminDashboard = () => {
  const {
    data: HospitalList,
    refetch: hRefectch,
    isError: hIsError,
    isLoading: hIsLoading,
  } = useGetAllHospitalQuery();
  const {
    data: DoctorList,
    refetch: dRefectch,
    isError: dIsError,
    isLoading: dIsLoading,
  } = useGetDoctorsQuery();
  const {
    data: PatientList,
    refetch: pRefectch,
    isError: pIsError,
    isLoading: pIsloading,
  } = useGetAllPatientQuery();
  const {
    data: AppoinmentList,
    refetch: aRefectch,
    isError: aIsError,
    isLoading: aIsLoading,
  } = useGetAllDoctorAppointmentsQuery();

  console.log(DoctorList, "DoctorList");

  // const [dataList, setdataList] = useState([]);
  useEffect(() => {}, [HospitalList, DoctorList, PatientList, AppoinmentList]);
  return (
    <>
      <AdminLayout>
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <h3 className="page-title text-black">Welcome Admin!</h3>
              <ul className="breadcrumb">
                <li style={{ color: "black", fontSize: "large" }}>Dashboard</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <span className="dash-widget-icon text-primary border-primary">
                    <i className="fe fe-users"></i>
                  </span>
                  <div className="dash-count">
                    <h3>{DoctorList?.doctors?.length || "0"}</h3>
                  </div>
                </div>
                <div className="dash-widget-info">
                  <h6 className="text-muted">Doctors</h6>
                  <div className="progress progress-sm">
                    <div className="progress-bar bg-primary w-50"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <span className="dash-widget-icon text-success">
                    <i className="fe fe-credit-card"></i>
                  </span>
                  <div className="dash-count">
                    <h3>{PatientList?.length || "0"}</h3>
                  </div>
                </div>
                <div className="dash-widget-info">
                  <h6 className="text-muted">Patients</h6>
                  <div className="progress progress-sm">
                    <div className="progress-bar bg-success w-50"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <span className="dash-widget-icon text-danger border-danger">
                    <i className="fe fe-money"></i>
                  </span>
                  <div className="dash-count">
                    <h3>{AppoinmentList?.length || "0"}</h3>
                  </div>
                </div>
                <div className="dash-widget-info">
                  <h6 className="text-muted">Appointment</h6>
                  <div className="progress progress-sm">
                    <div className="progress-bar bg-danger w-50"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 col-12">
            <div className="card">
              <div className="card-body">
                <div className="dash-widget-header">
                  <span className="dash-widget-icon text-warning border-warning">
                    <i className="fe fe-folder"></i>
                  </span>
                  <div className="dash-count">
                    <h3>{HospitalList?.hospitals?.length || "0"}</h3>
                  </div>
                </div>
                <div className="dash-widget-info">
                  <h6 className="text-muted">Hospitals</h6>
                  <div className="progress progress-sm">
                    <div className="progress-bar bg-warning w-50"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Revenue and Status tables  */}
        {/* <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-md-12 col-lg-6">
            <div className="card card-chart">
              <div className="card-header">
                <h4 className="card-title">Revenue</h4>
              </div>
              <div className="card-body">
                <div id="morrisArea"></div>
              </div>
            </div>
          </div>
          
        </div> */}
        <div className="row mt-5">
          <div className="col-md-12 col-lg-6">
            <div className="card card-table flex-fill">
              <div className="card-header">
                <h4 className="card-title">Hospital List</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-center mb-0">
                    <thead>
                      <tr>
                        <th>Hospital Name</th>
                        <th>Web Url</th>
                        <th>Phone</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {HospitalList?.hospitals?.map((hospital) => {
                        return (
                          <tr key={hospital.id}>
                            <td>
                              <a>{hospital.name}</a>
                            </td>
                            <td>{hospital.webUrl}</td>
                            <td>{hospital.phone}</td>
                            <td>{hospital.description}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="card card-chart">
              <div className="card-header">
                <h4 className="card-title">Revenue</h4>
              </div>
              <div className="card-body">
                <div id="morrisLine"></div>

                <h3 style={{ textAlign: "center" }}>Total Revenue: $3200.00</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-md-6 d-flex">
            <div className="card card-table flex-fill">
              <div className="card-header">
                <h4 className="card-title">Doctors List</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-center mb-0">
                    <thead>
                      <tr>
                        <th>Doctor Name</th>
                        <th>Speciality</th>
                        <th>Earned</th>
                        <th>Reviews</th>
                      </tr>
                    </thead>
                    <tbody>
                      {DoctorList?.doctors?.map((doctor) => {
                        return (
                          <tr key={doctor.id}>
                            <td>
                              <h2 className="table-avatar">
                                <a className="avatar avatar-sm mr-2">
                                  {/* <img
                                    className="avatar-img rounded-circle"
                                    src={doctor.img}
                                    alt=""
                                  /> */}
                                  <FaUserMd className="inline" /> &nbsp;
                                  {doctor.firstName + " " + doctor.lastName}
                                </a>
                              </h2>
                            </td>
                            <td>{doctor.specialization}</td>
                            <td>$3200.00</td>
                            <td>
                              <i className="fe fe-star text-warning"></i>
                              <i className="fe fe-star text-warning"></i>
                              <i className="fe fe-star text-warning"></i>
                              <i className="fe fe-star text-warning"></i>
                              <i className="fe fe-star-o text-secondary"></i>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex">
            <div className="card  card-table flex-fill">
              <div className="card-header">
                <h4 className="card-title">Patients List</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-center mb-0">
                    <thead>
                      <tr>
                        <th>Patient Name</th>
                        <th>Phone</th>
                        <th>Last Visit</th>
                        <th>Paid</th>
                      </tr>
                    </thead>
                    <tbody>
                      {PatientList?.map((patient) => {
                        return (
                          <tr>
                            <td>
                              <h2 className="table-avatar">
                                <a
                                  href="profile.html"
                                  className="avatar avatar-sm mr-2"
                                >
                                  {/* <img
                                    className="avatar-img rounded-circle"
                                    src={patient?.img}
                                    alt=""
                                  /> */}{" "}
                                  <FaUserAlt className="inline" /> &nbsp;
                                </a>
                                <a href="profile.html">
                                  {patient?.firstName + " " + patient?.lastName}{" "}
                                </a>
                              </h2>
                            </td>
                            <td>{patient?.mobile}</td>
                            <td>20 Oct 2019</td>
                            <td className="text-right">$100.00</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{ marginTop: "10px", marginBottom: "3rem" }}
        >
          <div className="col-md-12">
            <div className="card card-table">
              <div className="card-header">
                <h4 className="card-title">Appointment List</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-center mb-0">
                    <thead>
                      <tr>
                        <th>Doctor Name</th>
                        <th>Speciality</th>
                        <th>Patient Name</th>
                        <th>Apointment Time</th>
                        <th>Status</th>
                        <th className="text-right">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {AppoinmentList &&
                        AppoinmentList?.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>
                                <h2 className="table-avatar">
                                  <a
                                    href="profile.html"
                                    className="avatar avatar-sm mr-2"
                                  >
                                    <img
                                      className="avatar-img rounded-circle inline"
                                      src={item?.doctor?.img}
                                      alt=""
                                    />
                                  </a>
                                  <a href="profile.html">
                                    {item?.doctor?.firstName +
                                      " " +
                                      item?.doctor?.lastName}
                                  </a>
                                </h2>
                              </td>
                              <td>{item?.doctor?.specialization}</td>
                              <td>
                                <h2 className="table-avatar">
                                  <a
                                    href="profile.html"
                                    className="avatar avatar-sm mr-2"
                                  >
                                    <img
                                      className="avatar-img rounded-circle inline"
                                      src={item?.patient?.img}
                                      alt=""
                                    />
                                  </a>
                                  <a href="profile.html">
                                    {item?.patient?.firstName +
                                      " " +
                                      item?.patient?.lastName}{" "}
                                  </a>
                                </h2>
                              </td>
                              <td>
                                {item?.scheduleDate.split(" ")[0]}{" "}
                                <span className="text-primary d-block ">
                                  {item?.scheduleTime}
                                </span>
                              </td>
                              <td>
                                <div className="status-toggle">
                                  <label
                                    for="status_1"
                                    className={`${
                                      item?.status == "cancel"
                                        ? "text-danger"
                                        : "text-success"
                                    }`}
                                  >
                                    {item?.status}
                                  </label>
                                </div>
                              </td>
                              <td className="text-center">
                                {item?.paymentStatus}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  );
};
export default AdminDashboard;
