import { Outlet, useNavigate } from 'react-router-dom';
import { getUserInfo } from '../../service/auth.service';
import { useEffect } from 'react';

const PrivateAdminOutlet = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const localAuth = getUserInfo();
    if ( localAuth == null || localAuth.role !== 'admin' ) {navigate('/admin/login', {replace: true})}
  }, [navigate])

  return <Outlet />
};

export default PrivateAdminOutlet;