import React from "react";
import "./component.css";
import { FaRegTimesCircle } from "react-icons/fa";

const Popup = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "popup display-block" : "popup display-none";
  
    return (
      <div className={showHideClassName}>
        <section className="popup-main">
          {children}
          <div  onClick={handleClose}><FaRegTimesCircle className="close-btn" /></div>
        </section>
      </div>
    );
};

export default Popup;
