import { get } from "react-hook-form";
import { tagTypes } from "../tag-types";
import { baseApi } from "./baseApi";

const Gallery_URL = '/gallery'

export const galleryApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getAllGallery: build.query({
            query: (arg) => ({
                url: `${Gallery_URL}`,
                method: 'GET',
                params: arg
            }),
            transformResponse: (response) => {
                return {
                    gallery: response.data,
                    meta: response.meta
                }
            },
            providesTags: [tagTypes.gallery]
        }),
        getGallery: build.query({
            query: (id) => ({
                url: `${Gallery_URL}/${id}`,
                method: 'GET',
            }),
            providesTags: [tagTypes.gallery]
        }),
        createGallery: build.mutation({
            query: ({ data }) => ({
                url: `${Gallery_URL}`,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }),
            invalidatesTags: [tagTypes.gallery]
        }),
        updateGallery: build.mutation({
            query: ({ data, id }) => ({
                url: `${Gallery_URL}/${id}`,
                method: 'PATCH',
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }),
            invalidatesTags: [tagTypes.gallery]
        }),
        deleteGallery: build.mutation({
            query: (id) => ({
                url: `${Gallery_URL}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [tagTypes.gallery]
        }),
        getGalleryDDL: build.query({
            query: () => ({
                url: `${Gallery_URL}/dropdown`,
                method: 'GET',
            }),
            providesTags: [tagTypes.gallery]
        }),

    })
})

export const {
    useGetAllGalleryQuery,
    useGetGalleryQuery,
    useCreateGalleryMutation,
    useUpdateGalleryMutation,
    useDeleteGalleryMutation,
    useGetGalleryDDLQuery
} = galleryApi