import React, { useEffect, useState } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import "./InsuranceCompany.css";
import {
  AutoComplete,
  message,
  Space,
  Table,
  Tag,
  Tooltip,
  Pagination,
  Checkbox,
} from "antd";
import {
  FaCloudDownloadAlt,
  FaEllipsisH,
  FaRegTrashAlt,
  FaEdit,
  FaSearch,
} from "react-icons/fa";
import { useDebounced } from "../../../utils/hooks/useDebounced";
import {
  useDeleteInsuranceCompanyMutation,
  useGetAllInsuranceCompanyQuery,
} from "../../../redux/api/InsuranceCompanyApi";
import UpsertinsuranceCompany from "./UpsertInsuranceCompany";

const InsuranceCompany = () => {
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedInsuranceCompanyId, setselectedInsuranceCompanyId] =
    useState(null);

  const debouncedQuery = useDebounced({ searchQuery: value, delay: 200 });

  const {
    data: InsuranceCompanyList,
    refetch,
    isError,
    isLoading,
  } = useGetAllInsuranceCompanyQuery({
    searchTerm: query,
    limit: size,
    page,
  });

  const { data: AutoCompleteList } = useGetAllInsuranceCompanyQuery({
    searchTerm: debouncedQuery,
    limit: size,
    page,
  });

  const [deleteDoctor] = useDeleteInsuranceCompanyMutation();

  useEffect(() => {
    console.log(InsuranceCompanyList);
    if (InsuranceCompanyList?.insuranceCompanys) {
      const data = InsuranceCompanyList.insuranceCompanys.map((company) => ({
        key: company.id,
        name: company.name,
        description: company.description,
        isEnabled: company.isEnabled,
        isPopular: company.isPopular,
        logo: company.logo,
      }));
      setDataList(data);
    }
  }, [InsuranceCompanyList]);

  useEffect(() => {
    if (AutoCompleteList?.doctors) {
      setOptions(
        AutoCompleteList.doctors.map((doctor) => ({
          key: doctor.id,
          value: doctor.firstName + " " + doctor.lastName,
        }))
      );
    }
  }, [AutoCompleteList]);

  const deleteHandler = async (id) => {
    try {
      const res = await deleteDoctor(id);
      if (res.error) {
        message.error(res.error.data.message);
      } else {
        message.success("Successfully Deleted !!");
      }
      refetch();
    } catch (error) {
      message.error(error.message);
    }
  };

  const columns = [
    {
      title: "Logo",
      dataIndex: "logo",
      key: "logo",
      render: (Url) => (
        <img
          width="50"
          height="50"
          src={Url}
          alt="logo"
          className=" rounded-circle"
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "isPopular",
      dataIndex: "isPopular",
      key: "isPopular",
      render: (value) => (
        <Checkbox disabled defaultChecked={value}>
          {String(value)}
        </Checkbox>
      ),
    },
    {
      title: "isEnabled",
      dataIndex: "isEnabled",
      key: "isEnabled",
      render: (value) => (
        <Checkbox disabled defaultChecked={value}>
          {String(value)}
        </Checkbox>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <a
              onClick={() => {
                setselectedInsuranceCompanyId(record.key);
                setShowForm(true);
              }}
            >
              <FaEdit />
            </a>
          </Tooltip>
          <Tooltip title="Delete" onClick={() => deleteHandler(record.key)}>
            <a>
              <FaRegTrashAlt />
            </a>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const onSearch = (searchText) => {
    setOptions(searchText ? [{ value: searchText }] : []);
  };

  const onSelect = (data) => {
    setValue(data); // Update the value state
  };

  const onChange = (data) => {
    if (data === "") {
      setValue(""); // Clear the value state
    }
    setValue(data);
  };

  const handleSearch = () => {
    setQuery(value); // Set the query state to the current input value
    setPage(1); // Reset to the first page
    refetch();
  };
  const handleReset = () => {
    setQuery(""); // Clear the query state
    setValue(""); // Clear the value state
    setPage(1); // Reset to the first page
    refetch(); // Refetch the data
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
    refetch();
  };

  return (
    <AdminLayout>
      {showForm ? (
        <UpsertinsuranceCompany
          setShowForm={setShowForm}
          id={selectedInsuranceCompanyId}
        />
      ) : (
        <div className="doctorContainer">
          <div className="doctorHeader">
            <h2 className="doctortitle text-black">Insurance Company</h2>
            <button
              className="doctorbtn"
              onClick={() => {
                setShowForm(true);
                setselectedInsuranceCompanyId(null);
              }}
            >
              New Insurance Company
            </button>
          </div>
          <div className="doctorSearchRow mb-3">
            <div className="flex items-center mt-4 mb-4">
              <AutoComplete
                onChange={onChange}
                onSelect={onSelect}
                onSearch={onSearch}
                value={value}
                options={options}
                style={{ width: 200 }}
                className="h-full"
                placeholder="Search Doctor"
              />

              <button
                className="bg-[#2c657b] p-2 rounded ml-2 text-white h-full"
                onClick={handleSearch}
              >
                <FaSearch />
              </button>

              <button
                className="bg-[#2c657b] p-2 rounded ml-2 text-white h-full"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>
            <div className="rightSearch">
              <span className="text-black">
                <Tooltip title="Export PDF">
                  <FaCloudDownloadAlt style={{ color: "black" }} />
                </Tooltip>
                Import
              </span>
              <span className="text-black">
                More
                <FaEllipsisH />
              </span>
            </div>
          </div>
          <div className="w-100">
            <Table
              columns={columns}
              dataSource={dataList}
              loading={isLoading}
              pagination={false}
            />
            <Pagination
              className="pagination"
              current={page}
              pageSize={size}
              total={InsuranceCompanyList?.meta?.total || 0}
              onChange={handlePageChange}
              showSizeChanger
              onShowSizeChange={handlePageChange}
            />
          </div>
        </div>
      )}
    </AdminLayout>
  );
};

export default InsuranceCompany;
