import React from "react";
import { Link } from "react-router-dom";
import ImageHeading from "../../images/doc/doctor 5.jpg";
import img from "../../images/logo.png";
import { useGetAllBlogsQuery } from "../../redux/api/blogApi";
import { useGetDoctorsQuery } from "../../redux/api/doctorApi";
import { truncate } from "../../utils/truncate";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
import SubHeader from "../Shared/SubHeader";
import "./index.css";

const About = () => {
  const { data, isError, isLoading } = useGetAllBlogsQuery({ limit: 5 });
  const {
    data: doctorData,
    isLoading: DoctorIsLoading,
    isError: doctorIsError,
  } = useGetDoctorsQuery({ limit: 5 });

  const blogData = data?.blogs ?? [];
  const doctors = doctorData?.doctors ?? [];

  return (
    <>
      <Header />
      <SubHeader
        title="About Us"
        subtitle="Learn about our mission, values, and commitment to quality care."
      />
      <div className="container" style={{ marginBottom: 100, marginTop: 100 }}>
        <div className="row p-5">
          <div className="col-lg-4">
            <div className="section-title text-center">
              <h2 className="text-uppercase">Our Doctors' Achievements</h2>
              <p className="form-text m-0">
                Celebrating excellence in healthcare and patient care.
              </p>
            </div>
            <p className="mt-3">
              Our dedicated doctors have achieved numerous accolades and
              certifications, reflecting their commitment to providing
              exceptional care. With extensive experience in various
              specialties, they continuously strive for excellence, ensuring the
              highest standards of medical practice and patient satisfaction.
            </p>
          </div>

          <div className="col-lg-8">
            <img
              src={ImageHeading}
              alt=""
              className="img-fluid rounded shadow"
            />
          </div>
        </div>
      </div>
      {blogData.length > 0 && (
        <div
          className="container"
          style={{ marginBottom: 100, marginTop: 100 }}
        >
          <div className="row">
            {blogData?.map((item, id) => (
              <div className="col-lg-3 col-md-6" key={id + item.id}>
                <div className="card shadow border-0 mb-5 mb-lg-0">
                  <img
                    src={item?.img}
                    alt="blog Image"
                    width={300}
                    height={200}
                    className="w-100  rounded-top image-hover"
                    style={{ objectFit: "contain" }}
                  />

                  <div className="p-2">
                    <Link to={`/blog/${item?.id}`}>
                      <h6
                        className="text-start mb-1 text-capitalize"
                        style={{ color: "#223a66" }}
                      >
                        {truncate(item?.title, 40)}
                      </h6>
                    </Link>
                    <div className="px-2">
                      <p className="form-text text-start text-capitalize">
                        {truncate(item?.description, 80)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="container" style={{ marginBottom: 100, marginTop: 100 }}>
        <div className="row align-items-center">
          <div className="col-lg-4">
            <div className="section-title text-center">
              <h2 className="text-uppercase">Our Doctors' Achievements</h2>
              <p className="form-text m-0">
                Highlighting our commitment to excellence in healthcare.
              </p>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="row">
              {Array(6)
                .fill(null)
                .map((_, id) => (
                  <div className="col-lg-4 col-md-6 col-sm-6" key={id + 3}>
                    <div className="award-img">
                      <img src={img} alt="" className="img-fluid" />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <div className="container" style={{ marginBottom: 100, marginTop: 100 }}>
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="mb-4 section-title text-center">
              <h2 className="text-uppercase">Meet Our Specialists</h2>
              <p className="form-text m-0">
                Get to know our dedicated healthcare professionals who are
                committed to providing exceptional care and expertise in their
                fields.
              </p>
            </div>
          </div>
        </div>
        {doctors.length > 0 && (
          <div className="row">
            {doctors.map((item, id) => (
              <div className="col-lg-3 col-md-6 col-sm-6" key={id + item.id}>
                <div className="card shadow border-0 mb-5 mb-lg-0">
                  {item.img && (
                    <img src={item.img} class="img-fluid w-100" alt="" />
                  )}
                  <div className="p-2">
                    <h4 className="mt-4 mb-0" style={{ color: "#223a66" }}>
                      <a>{item?.firstName + " " + item?.lastName}</a>
                    </h4>
                    <p>{item?.designation}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div
        className="container say-about"
        style={{ marginBottom: 100, marginTop: 100 }}
      >
        <div className="row">
          <div className="col-lg-6 offset-lg-6">
            <div className="mb-4 section-title text-center">
              <h2 className="text-uppercase">What Doctors Say</h2>
              <p className="form-text m-0">
                Insights and advice from our experienced doctors to help you
                make informed health decisions.
              </p>
            </div>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-lg-6 offset-lg-6">
            <div className="my-2">
              <h4 style={{ color: "#223a66" }} className="my-0">
                Amazing service!
              </h4>
              <span>John Partho</span>
            </div>
            <p className="form-text">
              They provide great service facilty consectetur adipisicing elit.
              Itaque rem, praesentium, iure, ipsum magnam deleniti a vel eos
              adipisci suscipit fugit placeat. Quibusdam laboriosam eveniet
              nostrum nemo commodi numquam quod.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
