import { tagTypes } from "../tag-types"
import { baseApi } from "./baseApi"

const InsCom_URL = '/insuranceCompany'
const InPlan_URL = '/insurancecompany/dropdown'

export const insuranceCompanyApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getAllInsuranceCompany: build.query({
            query: (arg) => ({
                url: `${InsCom_URL}`,
                method: 'GET',
                params: arg
            }),
            transformResponse: (response) => {
                return {
                    insuranceCompanys: response.data,
                    meta: response.meta
                }
            },
            providesTags: [tagTypes.insuranceCompany]
        }),
        getInsuranceCompanyDDL: build.query({
            query: () => ({
                url: `${InsCom_URL}/dropdown`,
                method: 'GET',
            }),
            providesTags: [tagTypes.insuranceCompany]
        }),
        getInsuranceCompany: build.query({
            query: (id) => ({
                url: `${InsCom_URL}/${id}`,
                method: 'GET',
            }),
            providesTags: [tagTypes.insuranceCompany]
        }),
        updateInsuranceCompany: build.mutation({
            query: ({ data, id }) => ({
                url: `${InsCom_URL}/${id}`,
                method: 'PATCH',
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }),
            invalidatesTags: [tagTypes.insuranceCompany]
        }),
        createInsuranceCompany: build.mutation({
            query: ({ data }) => ({
                url: `${InsCom_URL}`,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }),
            invalidatesTags: [tagTypes.insuranceCompany]
        }),
        deleteInsuranceCompany: build.mutation({
            query: (id) => ({
                url: `${InsCom_URL}/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: [tagTypes.insuranceCompany]
        }),

    })
})


export const {
    useGetAllInsuranceCompanyQuery,
    useGetInsuranceCompanyDDLQuery,
    useGetInsuranceCompanyQuery,
    useUpdateInsuranceCompanyMutation,
    useCreateInsuranceCompanyMutation,
    useDeleteInsuranceCompanyMutation,

} = insuranceCompanyApi