import { Checkbox, message } from "antd";
import { useEffect, useState } from "react";
import useAuthCheck from "../../redux/hooks/useAuthCheck";
import { AllergiesOptions, DiagnosisOptions, DiseaseOptions } from "../../constant/global";
import SelectForm from "../UI/form/SelectForm";
import InputAutoCompleteForm from "../UI/form/InputAutoCompleteForm";

const PersonalInformation = ({
  handleChange,
  selectValue,
  setPatientId = () => { },
}) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    reasonForVisit,
    description,
    address,
  } = selectValue;
  // const [checked, setChecked] = useState(false);
  const { data } = useAuthCheck();

  // const onChange = (e) => {
  //   setChecked(e.target.checked);
  // };

  useEffect(() => {
    if (data?.id) {
      setPatientId(data.id);
      selectValue.firstName = data.firstName || "";
      selectValue.lastName = data.lastName || "";
      selectValue.email = data.email || "";
      selectValue.phone = data.mobile || "";
      selectValue.address = data.address || "";

    }
  }, [data, setPatientId]);

  return (
    <form className="rounded p-3 mt-5" style={{ background: "#f8f9fa" }}>
      <div className="row">
        {/* <Checkbox checked={checked} onChange={onChange}>
          Already have an account?
        </Checkbox> */}

        <div className="col-md-6 col-sm-12">
          <div className="form-group card-label mb-3">
            <label>
              First Name <span style={{ color: "red" }}>*</span>
            </label>
            <input
              onChange={(e) => {
                handleChange(e);
              }}
              name="firstName"
              value={firstName && firstName}
              className="form-control"
              type="text"
              required
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group card-label mb-3">
            <label>
              Last Name <span style={{ color: "red" }}>*</span>
            </label>
            <input
              onChange={(e) => handleChange(e)}
              name="lastName"
              value={lastName && lastName}
              className="form-control"
              type="text"
              required
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group card-label mb-3">
            <label>
              Email <span style={{ color: "red" }}>*</span>
            </label>
            <input
              onChange={(e) => handleChange(e)}
              name="email"
              value={email && email}
              className="form-control"
              type="email"
              required
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group card-label mb-3">
            <label>
              Phone <span style={{ color: "red" }}>*</span>
            </label>
            <input
              onChange={(e) => handleChange(e)}
              name="phone"
              value={phone && phone}
              className="form-control"
              type="text"
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group card-label mb-3">
            <label>
              Reason For Visit <span style={{ color: "red" }}>*</span>
            </label>
            <textarea
              rows={8}
              onChange={(e) => handleChange(e)}
              name="reasonForVisit"
              value={reasonForVisit && reasonForVisit}
              className="form-control"
              type="text"
              required
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group card-label mb-3">
            <label>Description</label>
            <textarea
              rows={8}
              onChange={(e) => handleChange(e)}
              name="description"
              value={description && description}
              className="form-control"
              type="text"
            />
          </div>
        </div>
          <div className="col-md-6 col-sm-12">
            <div className="form-group card-label mb-3">
                <label>Existing Conditions</label>
              <SelectForm
                mode={true}
                options={DiseaseOptions}
                name="existingConditions"
                setSelectData={(value) => handleChange({ 'existingConditions': value.join(',') })}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="form-group  card-label mb-3">
                <label>Allergies</label>
              <SelectForm
                mode={true}
                name="allergies"
                setSelectData={(value) => handleChange({ 'allergies': value.join(',') })}
                options={AllergiesOptions}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
          <div className="form-group card-label mb-3">
            <label>Current Medications Name </label>
            <input
              onChange={(e) => handleChange(e)}
              name="currentMedications"
              className="form-control"
              type="text"
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group card-label mb-3">
            <label>Recent Test </label>
            <input
              onChange={(e) => handleChange(e)}
              name="recentTest"
              className="form-control"
              type="text"
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group card-label mb-3">
            <label>Address </label>
            <input
              onChange={(e) => handleChange(e)}
              name="address"
              value={address && address}
              className="form-control"
              type="text"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default PersonalInformation;
