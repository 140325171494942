// import React from 'react';
// import img from '../../../images/avatar.jpg';
// import DashboardLayout from '../DashboardLayout/DashboardLayout';
// import { useGetDoctorPatientsQuery } from '../../../redux/api/appointmentApi';
// import moment from 'moment';
// import { Link } from 'react-router-dom';
// import { FaClock, FaEnvelope, FaLocationArrow, FaPhoneAlt } from "react-icons/fa";
// import { Empty } from 'antd';

// const MyPatients = () => {
//     const getInitPatientName = (item) => {
//         const fullName = `${item?.firstName ?? ''} ${item?.lastName ?? ''}`;
//         return fullName.trim() || "Private Patient";
//     }
//     const { data, isLoading, isError } = useGetDoctorPatientsQuery();
//     let content = null;
//     if (!isLoading && isError) content = <div>Something Went Wrong !</div>
//     if (!isLoading && !isError && data?.length === 0) content = <Empty/>
//     if (!isLoading && !isError && data?.length > 0) content =
//         <>
//             {data && data?.map((item) => (
//                 <div className="w-100 mb-3 rounded p-3 text-center" style={{ background: '#f8f9fa' }}>
//                     <div className="">
//                         <Link to={'/'} className="my-3 patient-img">
//                             <img src={item?.img ? item?.img : img} alt="" />
//                         </Link>
//                         <div className="patients-info mt-4">
//                             <h5>{getInitPatientName(item)}</h5>
//                             <div className="info">
//                                 <p><FaClock className='icon' /> {moment(item?.appointmentTime).format("MMM Do YY")} </p>
//                                 <p><FaLocationArrow className='icon' /> {item?.address}</p>
//                                 <p><FaEnvelope className='icon' /> {item?.email}</p>
//                                 <p><FaPhoneAlt className='icon' /> {item?.mobile}</p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             ))}
//         </>
//     return (
//         <DashboardLayout>
//             <div className="row">
//                 <div className="col-md-6 col-lg-4 col-xl-3">
//                     {content}
//                 </div>
//             </div>
//         </DashboardLayout>
//     )
// }

// export default MyPatients

import React, { useState } from "react";
import img from "../../../images/avatar.jpg";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import { useGetDoctorPatientsQuery } from "../../../redux/api/appointmentApi";
import moment from "moment";
import { Link } from "react-router-dom";
import { Table, Input, Empty } from "antd";
import {
  FaClock,
  FaEnvelope,
  FaLocationArrow,
  FaPhoneAlt,
} from "react-icons/fa";

const MyPatients = () => {
  const { data, isLoading, isError } = useGetDoctorPatientsQuery();
  console.log(data);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const getInitPatientName = (item) => {
    const fullName = `${item?.firstName ?? ""} ${item?.lastName ?? ""}`;
    return fullName.trim() || "Private Patient";
  };

  const handleSearch = (value) => {
    setSearchTerm(value.toLowerCase());
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  let filteredData = data || [];

  if (searchTerm) {
    filteredData = filteredData.filter(
      (item) =>
        item?.firstName.toLowerCase().includes(searchTerm) ||
        item?.lastName.toLowerCase().includes(searchTerm) ||
        item?.email.toLowerCase().includes(searchTerm) ||
        item?.mobile.toLowerCase().includes(searchTerm)
    );
  }

  const paginatedData = filteredData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "name",
      key: "name",
      render: (_, item) => (
        <>
          <img
            src={item?.img ? item?.img : img}
            alt=""
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              display: "inline",
            }}
          />{" "}
          {getInitPatientName(item)}
        </>
      ),
    },
    {
      title: "Appointment Time",
      dataIndex: "appointmentTime",
      key: "appointmentTime",
      render: (appointmentTime) => (
        <span>
          <FaClock className="icon inline" />{" "}
          {moment(appointmentTime).format("MMM Do YY")}
        </span>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (address) => (
        <span>
          <FaLocationArrow className="icon inline" /> {address}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email) => (
        <span>
          <FaEnvelope className="icon inline" /> {email}
        </span>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (mobile) => (
        <span>
          <FaPhoneAlt className="icon inline" /> {mobile}
        </span>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <Input.Search
        placeholder="Search by patient name, email or mobile"
        onSearch={handleSearch}
        style={{ width: 300, marginBottom: 16 }}
      />
      {!isLoading && isError && <div>Something Went Wrong!</div>}
      {!isLoading && !isError && filteredData.length === 0 && <Empty />}
      {!isLoading && !isError && filteredData.length > 0 && (
        <Table
          columns={columns}
          dataSource={paginatedData}
          rowKey={(record) => record.id}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: filteredData.length,
            onChange: handlePageChange,
          }}
        />
      )}
    </DashboardLayout>
  );
};

export default MyPatients;
