import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Button } from 'antd';

const WithoutLogin = () => {

  const navigate = useNavigate();


  return (
    <>
      <div className=" d-flex justify-content-center align-items-sm-center" style={{ marginBottom: '12rem', marginTop: '8rem' }}>
                <div className='mb-5 mt-3 mx-3'>Oops You are not Logged In</div>
                <div className=' text-center ' >
                <Button style={{ paddingBottom: '32px'}} onClick={()=>{
                   navigate("/login");

                }}  >Login</Button>
                </div>
            </div>
    </>
  );
};

export default WithoutLogin;
