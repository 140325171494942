import React, { useEffect, useState } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import imgplaceholder from "../../../images/landscapeplaceholder.svg";
import "./gallery.css";
import {
  AutoComplete,
  Checkbox,
  Image,
  Modal,
  Pagination,
  Space,
  Table,
  message,
  Tooltip,
  Button,
} from "antd";
import ImageUpload from "../../UI/form/ImageUpload";
import {
  FaCloudDownloadAlt,
  FaEdit,
  FaEllipsisH,
  FaImage,
  FaRegTrashAlt,
} from "react-icons/fa";
import {
  useCreateGalleryMutation,
  useDeleteGalleryMutation,
  useGetAllGalleryQuery,
} from "../../../redux/api/galleryApi";
import { useDebounced } from "../../../utils/hooks/useDebounced";
import { useForm } from "react-hook-form";

const AdminGallery = () => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [size, setSize] = useState(10);
  const [value, setValue] = useState("");
  const [createGallery] = useCreateGalleryMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { register, handleSubmit, setValue: formsetvalue, reset } = useForm();

  const [dataList, setDataList] = useState([]);
  const [options, setOptions] = useState([]);
  const [deleteGallery] = useDeleteGalleryMutation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    data: GalleryList,
    isLoading,
    refetch,
  } = useGetAllGalleryQuery({
    searchTerm: query,
    limit: size,
    page,
  });

  const images = GalleryList?.gallery?.map((item) => item?.imgSrc) || [];
  const imageArray = [...images];

  const showModal = () => {
    setIsModalOpen(true);
  };
  const [openImage, setOpenImage] = useState(false);

  const debouncedQuery = useDebounced({ searchQuery: value, delay: 200 });

  const { data: AutoCompleteList } = useGetAllGalleryQuery({
    searchTerm: debouncedQuery,
    limit: size,
    page,
  });

  useEffect(() => {
    if (AutoCompleteList?.gallery) {
      setOptions(
        AutoCompleteList.gallery.map((gallery) => ({
          key: gallery.id,
          value: gallery.name,
        }))
      );
    }
  }, [AutoCompleteList]);

  const handleOpenImage = () => {
    setOpenImage(!openImage);
  };

  // const handleOk = () => {
  //   onSubmit();
  //   setIsModalOpen(false);
  // };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    console.log(GalleryList);
    if (GalleryList?.gallery) {
      const data = GalleryList.gallery.map((Images) => ({
        key: Images.id,
        name: Images.name,
        imgSrc: Images.imgSrc,
        CreatedAt: Images.createdAt,
      }));
      setDataList(data);
    }
  }, [GalleryList]);

  const deleteHandler = async (id) => {
    try {
      const res = await deleteGallery(id);
      if (res.error) {
        message.error(res.error.data.message);
      } else {
        message.success("Successfully Deleted !!");
      }
      refetch();
    } catch (error) {
      message.error(error.message);
    }
  };
  const onSearch = (searchText) => {
    setOptions(searchText ? [{ value: searchText }] : []);
  };

  const onSelect = (data) => {
    setValue(data); // Update the value state
  };

  const onChange = (data) => {
    if (data === "") {
      setValue(""); // Clear the value state
    }
    setValue(data);
  };

  const handleSearch = () => {
    setQuery(value); // Set the query state to the current input value
    setPage(1); // Reset to the first page
    refetch();
  };
  const handleReset = () => {
    setQuery(""); // Clear the query state
    setValue(""); // Clear the value state
    setPage(1); // Reset to the first page
    refetch(); // Refetch the data
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setSize(pageSize);
    refetch();
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "imgSrc",
      key: "imgSrc",
      render: (Url) => (
        <Image.PreviewGroup>
          <div>
            <div className="">
              <div className="">
                <Image
                  src={Url}
                  alt=""
                  className=""
                  style={{
                    height: "100px",
                    width: "100px",
                  }}
                />
              </div>
            </div>
          </div>
        </Image.PreviewGroup>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Created At",
      dataIndex: "CreatedAt",
      key: "CreatedAt",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <Tooltip title="Edit">
            <a onClick={showModal}>
              <FaEdit />
            </a>
          </Tooltip> */}
          <Tooltip title="Delete" onClick={() => deleteHandler(record.key)}>
            <a>
              <FaRegTrashAlt />
            </a>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const onSubmit = async (formData) => {
    console.log("form clicked!");
    setLoading(true);
    console.log(formData);
    const newObj = { ...formData };
    const changedValue = Object.fromEntries(
      Object.entries(newObj).filter(([key, value]) => value !== "")
    );
    const finalFormData = new FormData();
    selectedImage && finalFormData.append("imgSrc", file);
    finalFormData.append("data", JSON.stringify(changedValue));
    try {
      const res = await createGallery({ data: finalFormData });
      if (res.error) {
        message.error(res.error.data.message);
      } else {
        message.success("Successfully Added !!");
      }
      refetch();
    } catch (error) {
      message.error(error.message);
    }
    reset();
    setLoading(false);
    setSelectedImage(null);
    handleCancel();
  };

  return (
    <>
      <AdminLayout>
        <section className="">
          <div className="doctorContainer">
            <div className="doctorHeader">
              <h2 class="doctortitle text-black">Gallery</h2>
              <div>
                <button className="doctorbtn" onClick={showModal}>
                  <FaImage className="inline" /> Add Image
                </button>
                <Modal
                  title="Upload Image"
                  open={isModalOpen}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <label>Name: </label>{" "}
                    <input
                      type="text"
                      className="form-control"
                      {...register("name")}
                    />
                    <hr />
                    <div style={{}} onClick={handleOpenImage}>
                      <img
                        src={selectedImage || imgplaceholder}
                        alt=""
                        style={{
                          width: "100%",
                          height: "30vh",
                          objectFit: "cover",
                          marginBottom: "10px",
                        }}
                      />
                    </div>
                    <ImageUpload
                      openImage={openImage}
                      setSelectedImage={setSelectedImage}
                      setFile={setFile}
                    />
                    <hr />
                    <Button key="back" onClick={handleCancel}>
                      Return
                    </Button>
                    <Button
                      key="submit"
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      Submit
                    </Button>
                  </form>
                </Modal>
              </div>
            </div>
          </div>
          <hr />
          <div
            className="d-flex flex-wrap "
            style={{
              gap: "10px",
            }}
          ></div>

          <div className="doctorContainer">
            <div className="doctorSearchRow mb-3">
              <div className="d-flex justify-content-between">
                <AutoComplete
                  className="auto-search"
                  options={options}
                  onSelect={onSelect}
                  onSearch={onSearch}
                  placeholder="Search Image"
                  value={value}
                  onChange={onChange}
                />
                <button
                  className="doctorbtn bg-light text-dark"
                  onClick={handleReset}
                >
                  Reset
                </button>
                <button className="doctorbtn" onClick={handleSearch}>
                  Search
                </button>
              </div>
              <div className="rightSearch">
                <span className="text-black">
                  <Tooltip title="Export PDF">
                    <FaCloudDownloadAlt style={{ color: "black" }} />
                  </Tooltip>
                  Import
                </span>
                <span className="text-black">
                  More
                  <FaEllipsisH />
                </span>
              </div>
            </div>
            <div className="w-100">
              <Table
                columns={columns}
                dataSource={dataList}
                loading={isLoading}
                pagination={false}
              />
              <Pagination
                className="pagination"
                style={{ marginTop: "20px" }}
                current={page}
                pageSize={size}
                total={GalleryList?.meta?.total || 0}
                onChange={handlePageChange}
                showSizeChanger
                onShowSizeChange={handlePageChange}
              />
            </div>
          </div>
        </section>
      </AdminLayout>
    </>
  );
};

export default AdminGallery;
