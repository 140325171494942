import moment from "moment";
import { DatePicker } from "antd";
import { useEffect, useState } from "react";

const SelectDateAndTime = ({
  content,
  details,
  handleDateChange,
  disabledDateTime,
  selectedDate,
  dContent,
  selectTime,
  handleHospitalChange
}) => {
  const [selectedHospital, setSelectedHospital] = useState('');

  // Effect to set the default hospital with the least distance
  useEffect(() => {
    if (details?.hospitalDoctors?.length > 0) {
      const closestHospital = details.hospitalDoctors.reduce((prev, curr) => {
        return prev.hospital.distance < curr.hospital.distance ? prev : curr;
      });

      setSelectedHospital(closestHospital.hospital.id);
      handleHospitalChange(closestHospital.hospital.id);
    }
  }, [details, handleHospitalChange]);

  return (
    <div style={{ marginTop: "5rem" }}>
      <div>
        <h5 className="text-title">Selected Doctor</h5>
        {content}
      </div>

      <div className="row">
        <div className="col-md-5 col-sm-12 mt-3 row flex-column">
          <div>
            <h5 className="text-title mb-3">Please Select Date</h5>
            <DatePicker
              format="YYYY-MM-DD"
              className="w-100"
              disabledDate={disabledDateTime}
              onChange={handleDateChange}
              // defaultValue={moment().add(1, 'days')}
            />
          </div>
          <div className="mt-4">
            <h5 className="text-title mb-3 fw-bold">Select Hospital</h5>
            <select
              id="doctorSelect"
              className="form-control"
              value={selectedHospital}
              onChange={(event) => {
                setSelectedHospital(event.target.value);
                handleHospitalChange(event.target.value);
              }}
            >
              <option value="">Select a Hospital</option>
              {details?.hospitalDoctors?.map(({ hospital }) => (
                <option key={hospital?.id} value={hospital?.id}>
                  {hospital?.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-md-7 col-sm-12 mt-3">
          {selectedDate && (
            <h5 className="text-title mb-3">
              Selected Date: {selectedDate && moment(selectedDate).format("LL")}
              {selectTime && "Time: " + selectTime}
            </h5>
          )}
          <div className="date-card rounded">
            <div className="row text-center mt-3">
              {!selectedDate ? (
                <h5 className="text-title d-flex justify-content-center align-items-center mt-5">
                  Please Select A Date First
                </h5>
              ) : (
                dContent
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectDateAndTime;
