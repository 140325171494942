import React, { Dispatch, MouseEvent, SetStateAction, ChangeEvent } from "react"
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Autocomplete,
  Box,
  Checkbox,
  Typography,
} from "@mui/material"
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DatePickerEventFormData, ITodo } from "./EventCalendar"

// interface IProps {
//   open: boolean
//   handleClose: Dispatch<SetStateAction<void>>
//   datePickerEventFormData: DatePickerEventFormData
//   setDatePickerEventFormData: Dispatch<SetStateAction<DatePickerEventFormData>>
//   onAddEvent: (e: MouseEvent<HTMLButtonElement>) => void
//   todos: ITodo[]
// }

const AddDatePickerEventModal = ({
  open,
  handleClose,
  datePickerEventFormData,
  setDatePickerEventFormData,
  onAddEvent,
}) => {
  const { description, start, end, maximumPatient } = datePickerEventFormData

  const onClose = () => {
    handleClose()
  }

  const onChange = (event) => {
    setDatePickerEventFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add event</DialogTitle>
      <DialogContent>
        <DialogContentText>To add a event, please fill in the information below.</DialogContentText>
        <Box component="form">
          <TextField
            name="description"
            value={description}
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            variant="outlined"
            onChange={onChange}
          />
          <TextField
            name="maximumPatient"
            value={maximumPatient}
            margin="dense"
            id="maximumPatient"
            label="Maximum Patient"
            type="text"
            fullWidth
            variant="outlined"
            onChange={onChange}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box mb={2} mt={5}>
              <DateTimePicker
                label="Start date"
                value={start}
                ampm={true}
                minutesStep={30}
                onChange={(newValue) =>
                  setDatePickerEventFormData((prevState) => ({
                    ...prevState,
                    start: new Date(newValue),
                  }))
                }
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>

          

            <DateTimePicker
              label="End date"
              minDate={start}
              minutesStep={30}
              ampm={true}
              value={end}
              maxDate={start}
              minTime={start}
              onChange={(newValue) =>
                setDatePickerEventFormData((prevState) => ({
                  ...prevState,
                  end: new Date(newValue),
                }))
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>

        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cancel
        </Button>
        <Button  color="success" disabled={start===end} onClick={()=>{
          onAddEvent()
          onClose()
        } }>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddDatePickerEventModal