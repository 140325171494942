import React, { useState, useEffect, useContext } from "react";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import img from "../../../images/avatar.jpg";
import "./Appointments.css";
import {
  useGetDoctorAppointmentsQuery,
  useUpdateAppointmentMutation,
} from "../../../redux/api/appointmentApi";
import moment from "moment";
import {
  Button,
  Empty,
  message,
  Tag,
  Tooltip,
  Input,
  Select,
  Pagination,
} from "antd";
import { FaEye, FaCheck, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  FaClock,
  FaEnvelope,
  FaLocationArrow,
  FaPhoneAlt,
  FaBriefcaseMedical,
} from "react-icons/fa";
import { clickToCopyClipBoard } from "../../../utils/copyClipBoard";
import { SettingContext } from "../../context/setting";

const AppointmentsListView = () => {
  const setting = useContext(SettingContext);
  const [ShowPrescription, setShowPrrecription] = useState(
    setting[0].isPrescription
  );

  // State for filtering, sorting, and pagination
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  // Fetching doctor appointments with the new filters and pagination
  const { data, refetch, isError, isLoading } = useGetDoctorAppointmentsQuery({
    searchTerm,
    status: statusFilter,
    sortOrder,
    page: currentPage,
    limit: pageSize, // Ensure limit is correctly passed
  });

  const [updateAppointment, { isError: updateIsError, isSuccess, error }] =
    useUpdateAppointmentMutation();

  const updatedApppointmentStatus = (id, type) => {
    const changeObj = {
      status: type,
    };
    if (id) {
      updateAppointment({ id, data: changeObj });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      message.success("Successfully Updated Appointment");
    }
    if (isError) {
      message.error(error?.data?.message);
    }
  }, [isSuccess, updateIsError, error]);

  // Handle search
  const handleSearch = (value) => {
    setSearchTerm(value.toLowerCase());
    setCurrentPage(1); // Reset to the first page when a search is performed
  };

  // Handle status filter change
  const handleStatusChange = (value) => {
    setStatusFilter(value);
    setCurrentPage(1); // Reset to the first page when filter changes
  };

  // Handle sort order change
  const handleSortChange = (value) => {
    setSortOrder(value);
    setCurrentPage(1); // Reset to the first page when sorting changes
  };

  // Handle pagination change
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  let content = null;
  if (!isLoading && isError) content = <div>Something Went Wrong!</div>;
  if (!isLoading && !isError && data?.data.length === 0) content = <Empty />;
  if (!isLoading && !isError && data?.data.length > 0)
    content = (
      <>
        {data.data.map((item) => (
          <div
            className="w-100 mb-3 rounded p-3"
            style={{ background: "#f8f9fa" }}
            key={item.id}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center gap-3">
                <Link to={`/`} className="patient-img">
                  <img
                    src={item?.patient?.img ? item?.patient?.img : img}
                    alt=""
                  />
                </Link>
                <div className="patients-info">
                  <h5>
                    {item?.patient?.firstName} {item?.patient?.lastName}
                  </h5>
                  <Tooltip title="Copy Tracking Id">
                    <Button>
                      <h6>
                        Tracking
                        <Tag
                          color="#87d068"
                          className="ms-2 text-uppercase"
                          onClick={() => clickToCopyClipBoard(item?.trackingId)}
                        >
                          {item?.trackingId}
                        </Tag>
                      </h6>
                    </Button>
                  </Tooltip>

                  <div className="info mt-2">
                    <p>
                      <FaClock className="icon" />{" "}
                      {moment(
                        item?.scheduleDate + " " + item.scheduleTime
                      ).format("MMM Do YY")}{" "}
                    </p>
                    {item?.patient?.address && (
                      <p>
                        <FaLocationArrow className="icon" />{" "}
                        {item?.patient?.address}
                      </p>
                    )}
                    {item?.patient?.email && (
                      <p>
                        <FaEnvelope className="icon" /> {item?.patient?.email}
                      </p>
                    )}
                    {item?.patient?.phone && (
                      <p>
                        <FaPhoneAlt className="icon" /> {item?.patient?.phone}
                      </p>
                    )}
                  </div>
                </div>
                <div className="appointment-status card p-3 border-primary">
                  <p>
                    Current Status -{" "}
                    <span>
                      <Tag color="#f50" className="text-uppercase">
                        {item?.status}
                      </Tag>
                    </span>
                  </p>
                  <p>
                    Patient Status -{" "}
                    <span>
                      <Tag color="#2db7f5" className="text-uppercase">
                        {item?.patientType}
                      </Tag>
                    </span>
                  </p>
                  <p>
                    Is Follow Up -{" "}
                    <span>
                      <Tag color="#f50" className="text-uppercase">
                        {item?.isFollowUp ? "Yes" : "No"}
                      </Tag>
                    </span>
                  </p>
                  <p>
                    Is Paid -{" "}
                    <span>
                      <Tag color="#87d068" className="text-uppercase">
                        {item?.paymentStatus}
                      </Tag>
                    </span>
                  </p>
                  <p>
                    Prescribed -{" "}
                    <span>
                      <Tag color="#2db7f5" className="text-uppercase">
                        {item?.prescriptionStatus}
                      </Tag>
                    </span>
                  </p>
                </div>
              </div>
              <Link to={`/dashboard/appointments/${item?.id}`}>
                <Button type="primary" icon={<FaEye />} size="small">
                  View
                </Button>
              </Link>
              <div className="d-flex gap-2">
                {item?.status !== "cancel" && (
                  <>
                    {item.prescriptionStatus === "notIssued" ? (
                      <Link to={`/dashboard/appointment/treatment/${item?.id}`}>
                        <Button
                          type="primary"
                          icon={<FaBriefcaseMedical />}
                          size="small"
                        >
                          Treatment
                        </Button>
                      </Link>
                    ) : (
                      ShowPrescription && (
                        <Link
                          to={`/dashboard/prescription/${item?.prescription[0]?.id}`}
                        >
                          <Button type="primary" icon={<FaEye />} size="small">
                            Prescription
                          </Button>
                        </Link>
                      )
                    )}
                    {item?.isFollowUp && (
                      <Link
                        to={`/dashboard/appointment/treatment/edit/${item?.prescription[0]?.id}`}
                      >
                        <Button
                          type="primary"
                          icon={<FaBriefcaseMedical />}
                          size="small"
                        >
                          Follow Up
                        </Button>
                      </Link>
                    )}

                    {item?.status === "pending" && (
                      <>
                        <Button
                          type="primary"
                          icon={<FaCheck />}
                          size="small"
                          onClick={() =>
                            updatedApppointmentStatus(item.id, "scheduled")
                          }
                        >
                          Accept
                        </Button>
                      </>
                    )}
                    <Button
                      type="primary"
                      icon={<FaTimes />}
                      size="small"
                      danger
                      onClick={() =>
                        updatedApppointmentStatus(item.id, "cancel")
                      }
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={data?.meta?.total || 0}
          onChange={handlePageChange}
          style={{ marginTop: 16, textAlign: "center" }}
        />
      </>
    );

  return (
    <div className="appointments-list-view">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3 className="title">Appointments List</h3>
        <div className="d-flex gap-2">
          <Input.Search
            placeholder="Search appointments"
            onSearch={handleSearch} // This will handle Enter and Search icon clicks
            style={{ width: 200 }}
            allowClear
          />
          <Select
            defaultValue=""
            style={{ width: 120 }}
            onChange={handleStatusChange}
          >
            <Select.Option value="">All Statuses</Select.Option>
            <Select.Option value="pending">Pending</Select.Option>
            <Select.Option value="scheduled">Scheduled</Select.Option>
            <Select.Option value="cancel">Cancelled</Select.Option>
          </Select>
          <Select
            defaultValue="asc"
            style={{ width: 120 }}
            onChange={handleSortChange}
          >
            <Select.Option value="asc">Ascending</Select.Option>
            <Select.Option value="desc">Descending</Select.Option>
          </Select>
        </div>
      </div>
      {content}
    </div>
  );
};

export default AppointmentsListView;
