import { tagTypes } from "../tag-types"
import { baseApi } from "./baseApi"

const Setting_URL = '/setting'

export const settingApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getSetting: build.query({
            query: () => ({
                url: `${Setting_URL}`,
                method: 'GET',
            }),
            providesTags: [tagTypes.setting]
        }),
        updateSetting: build.mutation({
            query: ({ data }) => ({
                url: `${Setting_URL}`,
                method: 'PATCH',
                data: data,
                
            }),
            invalidatesTags: [tagTypes.setting]
        })
    })
})


export const {
    useGetSettingQuery,
    useUpdateSettingMutation,
} = settingApi