import { setUserInfo } from "../../utils/local-storage";
import { baseApi } from "./baseApi";

const AUTH_URL = "/auth";

export const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    userLogin: build.mutation({
      query: (loginData) => ({
        url: `${AUTH_URL}/login`,
        method: "POST",
        data: loginData,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = (await queryFulfilled).data;
          setUserInfo({ accessToken: result.accessToken });
        } catch (error) {}
      },
    }),
    AdminLogin: build.mutation({
      query: (loginData) => ({
        url: `${AUTH_URL}/login`,
        method: "POST",
        data: loginData,
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const result = (await queryFulfilled).data;
          if (result.user.role === "admin") {
            setUserInfo({ accessToken: result.accessToken });
            return { isAdmin: true };
          } else {
            throw new Error("Login failed: You are not an admin.");
          }
        } catch (error) {}
      },
    }),

    patientSignUp: build.mutation({
      query: (data) => ({
        url: `/patient`,
        method: "POST",
        data,
      }),
    }),
    doctorSignUp: build.mutation({
      query: (data) => ({
        url: `/doctor`,
        method: "POST",
        data,
      }),
    }),
    resetPassword: build.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/reset-password`,
        method: "POST",
        data,
      }),
    }),
    resetConfirm: build.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/reset-password/confirm`,
        method: "POST",
        data,
      }),
    }),
    verifyDoctorEmail: build.query({
      query: ({ userId, uniqueString }) => ({
        url: `${AUTH_URL}/user/verify/${userId}/${uniqueString}`,
        method: "GET",
      }),
      providesTags: ["verfiyDoctorEmail"],
    }),
  }),
});

export const {
  useUserLoginMutation,
  useAdminLoginMutation,
  useDoctorSignUpMutation,
  usePatientSignUpMutation,
  useResetPasswordMutation,
  useResetConfirmMutation,
  useVerifyDoctorEmailQuery,
} = authApi;
