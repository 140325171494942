import "./App.css";
import { useState, useEffect, useLayoutEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./components/Home/Home/Home";
import SignInForm from "./components/Login/SignInForm";
import DoctorBooking from "./components/Booking/DoctorBooking/DoctorBooking";
import BookingSuccess from "./components/Booking/BookingSuccess";
import BookingInvoice from "./components/Booking/BookingInvoice/BookingInvoice";
import DoctorProfile from "./components/Doctor/DoctorProfile/DoctorProfile";
import Appointments from "./components/Doctor/Appointments/Appointments";
import MyPatients from "./components/Doctor/MyPatients/MyPatients";
import Reviews from "./components/Doctor/Reviews/Reviews";
import Schedule from "./components/Doctor/Schedule/Schedule";
import ProfileSetting from "./components/Doctor/ProfileSetting/ProfileSetting";
import ChangePassword from "./components/Doctor/ChangePassword/ChangePassword";
import AdminDashboard from "./components/Admin/Dashboard/Dashboard";
import AdminAppointments from "./components/Admin/Appointments/Appointments";
import Doctors from "./components/Admin/Doctors/Doctors";
import Patients from "./components/Admin/Patients/Patients";
import Profile from "./components/Admin/Profile/Profile";
import Transactions from "./components/Admin/Transactions/Transactions";
import Specialites from "./components/Admin/Specialites/Specialites";
import AdminReviews from "./components/Admin/Reviews/Reviews";
import PatientFavouriteDoctor from "./components/Doctor/PatientFavourite/PatientFavourite";
import DoctorInvoice from "./components/Doctor/Invoice/DoctorInvoice";
import SearchDoctor from "./components/Doctor/SearchDoctor/SearchDoctor";
import Blog from "./components/Blog/Blog";
import BlogDetails from "./components/Blog/BlogDetails";
import Contact from "./components/Contact/Contact";
import About from "./components/About/About";
import Hospitals from "./components/Hospitals/Hospitals";
import Service from "./components/Service/Service";
import AppointmentPage from "./components/Appointment/AppointmentPage";
import TrackAppointment from "./components/TrackAppointment/TrackAppointment";
import Treatment from "./components/Doctor/Treatment/Treatment";
import Prescription from "./components/Doctor/Prescription/Prescription";
import PrescriptionView from "./components/Doctor/Prescription/PrescriptionView";
import TreatmentEdit from "./components/Doctor/Treatment/TreatmentEdit";
import ViewAppointment from "./components/Doctor/Appointments/ViewAppointment";
import ForgotPassword from "./components/Login/ForgotPassword";
import Dashboard from "./components/Doctor/Dashboard/Dashboard";
import PrivateOutlet from "./components/Shared/PrivateOutlet";
import PrivateAdminOutlet from "./components/Shared/PrivateAdminOutlet";
import NotFound from "./components/UI/NotFound";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AdminSignIn from "./components/Admin/AdminLogin/AdminLogin";
import Hospital from "./components/Admin/Hospital/Hospital";
import Specialities from "./components/ClinicAndSpecialities/Specialities";
import SpecialitiesDetails from "./components/ClinicAndSpecialities/SpecialitiesDetails";
import Services from "./components/Admin/Services/Services";
import { SettingProvider } from "./components/context/setting.js";
import Setting from "./components/Admin/Setting/Setting.jsx";
import AdminGallery from "./components/Admin/Galleries/gallery.jsx";
import ServiceDetails from "./components/Service/ServiceDetails.jsx";
import InsuranceCompany from "./components/Admin/InsuranceCompany/InsuranceCompany.jsx";
import Blogs from "./components/Admin/Blogs/Blogs.jsx";
import BlogsEdit from "./components/Admin/Blogs/BlogsEdit.jsx";
import AddBlog from "./components/Admin/Blogs/AddBlog.jsx";
import HospitalDetails from "./components/Hospitals/HospitalDetails.jsx";
import { LoadScript } from "@react-google-maps/api";
import DoctorEmailVerify from "./components/Doctor/Verify/DoctorEmailVerify.jsx";
import InvitePatient from "./components/Doctor/InvitePatient/InvitePatient.jsx";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const GoogleMapApi = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const Wrapper = ({ children }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);

  return children;
};

function App() {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <LoadScript googleMapsApiKey={GoogleMapApi}>
        <SettingProvider>
          <Router>
            <Wrapper>
              <Routes>
                <Route element={<PrivateOutlet />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route
                    path="/dashboard/my-patients"
                    element={<MyPatients />}
                  />
                  <Route path="/dashboard/reviews" element={<Reviews />} />
                  <Route path="/dashboard/schedule" element={<Schedule />} />
                  <Route
                    path="/dashboard/appointments"
                    element={<Appointments />}
                  />
                  <Route
                    path="/dashboard/appointments/:id"
                    element={<ViewAppointment />}
                  />
                  <Route
                    path="/dashboard/prescription"
                    element={<Prescription />}
                  />
                  <Route
                    path="/dashboard/prescription/:id"
                    element={<PrescriptionView />}
                  />
                  <Route
                    path="/dashboard/appointment/treatment/:id"
                    element={<Treatment />}
                  />
                  <Route
                    path="/dashboard/appointment/treatment/edit/:id"
                    element={<TreatmentEdit />}
                  />
                  <Route
                    path="/dashboard/change-password"
                    element={<ChangePassword />}
                  />
                  <Route
                    path="/dashboard/profile-setting"
                    element={<ProfileSetting />}
                  />
                  <Route
                    path="/dashboard/favourite"
                    element={<PatientFavouriteDoctor />}
                  />
                  <Route
                    path="/dashboard/invoices"
                    element={<DoctorInvoice />}
                  />
                  <Route
                    path="/dashboard/invite-patient"
                    element={<InvitePatient />}
                  />
                </Route>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<SignInForm />} />
                <Route path="/register/:id?" element={<SignInForm />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:id" element={<BlogDetails />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/about" element={<About />} />
                <Route path="/service" element={<Service />} />
                <Route path="/hospital" element={<Hospitals />} />
                <Route path="/hospital/:id" element={<HospitalDetails />} />
                <Route path="/service/:id" element={<ServiceDetails />} />
                <Route path="/specialites" element={<Specialities />} />
                <Route
                  path="/user/verify/:id/:uniqueString"
                  element={<DoctorEmailVerify />}
                />
                <Route
                  path="/specialites/:id"
                  element={<SpecialitiesDetails />}
                />
                <Route
                  path="/reset-password/:userId/:uniqueString"
                  element={<ForgotPassword />}
                />
                <Route path="/appointment" element={<AppointmentPage />} />
                <Route
                  path="/track-appointment"
                  element={<TrackAppointment />}
                />
                <Route path="/doctors/" element={<SearchDoctor />} />
                <Route
                  path="/doctors/profile/:id"
                  element={<DoctorProfile />}
                />

                <Route path="/booking/:doctorId" element={<DoctorBooking />} />
                <Route
                  path="/booking/success/:id"
                  element={<BookingSuccess />}
                />
                <Route
                  path="/booking/invoice/:id"
                  element={<BookingInvoice />}
                />
                {/* Admin Dashboard  */}
                <Route element={<PrivateAdminOutlet />}>
                  <Route path="/admin/login" element={<AdminSignIn />} />
                  <Route path="/admin/dashboard" element={<AdminDashboard />} />
                  <Route
                    path="/admin/appointments"
                    element={<AdminAppointments />}
                  />
                  <Route path="/admin/doctors" element={<Doctors />} />
                  <Route path="/admin/patients" element={<Patients />} />
                  <Route path="/admin/profile" element={<Profile />} />
                  <Route path="/admin/reviews" element={<AdminReviews />} />
                  <Route path="/admin/transaction" element={<Transactions />} />
                  <Route path="/admin/specialites" element={<Specialites />} />
                  <Route path="/admin/services" element={<Services />} />
                  <Route path="/admin/hospital" element={<Hospital />} />
                  <Route path="/admin/Setting" element={<Setting />} />
                  <Route path="/admin/gallery" element={<AdminGallery />} />
                  <Route
                    path="/admin/insuranceCompany"
                    element={<InsuranceCompany />}
                  />
                  <Route path="/admin/blogs" element={<Blogs />} />
                  <Route path="/admin/blog/:id" element={<BlogsEdit />} />
                  <Route path="/admin/blog/create" element={<AddBlog />} />
                  <Route
                    path="/admin/blogDetails/:id"
                    element={<BlogDetails />}
                  />
                </Route>

                <Route path="*" element={<NotFound />} />
              </Routes>
            </Wrapper>
          </Router>
        </SettingProvider>
      </LoadScript>
    </GoogleOAuthProvider>
  );
}
export default App;
