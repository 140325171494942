import React from "react";
import "./component.css";

const Heading = (props) => {
  return (
    <div>
      <div className="heading">
        <h2 className="text-shadow">{props.name}</h2>
        <div> <img className="icon-img"
          src={props.iconUrl}
          alt={props.name}
        /></div>
      </div>
    </div>
  );
};

export default Heading;
