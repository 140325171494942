import { createContext, useState ,useEffect } from 'react'
import { useGetSettingQuery } from '../../redux/api/settingApi';

export const SettingContext = createContext()

export function SettingProvider({ children }) {
  const [setting, setSetting] = useState({
    isDisplayPrice:true,
    isPaymentEnabled:true,
    isPrescription : true,
    isInvoice : true,

  });
  const { data,  refetch, isSuccess, isError, isLoading } = useGetSettingQuery();
  useEffect(() => {
    setSetting({
      isDisplayPrice: data?.isDisplayPrice,
      isPaymentEnabled: data?.isPaymentEnabled,
      isPrescription : data?.isPrescription,
      isInvoice : data?.isInvoice
    });
}, [data, isSuccess])


  return (
    <SettingContext.Provider value={[setting, setSetting]}>
      {children}
    </SettingContext.Provider>
  )
}