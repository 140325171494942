import { Empty, Tooltip } from "antd";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import icon from "../../../images/img/tilde.png";
import { useGetDoctorsQuery } from "../../../redux/api/doctorApi";
import Heading from "../../UI/component/Heading";
import "./index.css";

const OurDoctors = () => {
  const { data, isLoading, isError, isSuccess } = useGetDoctorsQuery({
    limit: 5,
  });
  const doctors = data?.doctors ?? [];

  if (isLoading) return <h1>Loading..</h1>;

  if (isError) return <h1>Something went wrong !</h1>;

  if (isSuccess && doctors?.length === 0) return <Empty />;
  return (
    <section className="our-doctors">
      <div className="container">
        <Heading name="Our Doctors" iconUrl={icon} />
        <div className="card-wrapper-doctors mt-5">
          <div className="row">
            {doctors.length > 0 &&
              doctors.map((item, index) => (
                <div className="col-md-4 col-sm-12 mt-3" key={index}>
                  <div className="card team-doctor">
                    <img src={item?.img} alt="doctor" />
                  </div>
                  <div className="text-center mt-3">
                    <span className="doctor-name">
                      {item?.firstName + " " + item?.lastName}
                    </span>
                    <br />

                    <span style={{ color: "var(--primary)" }}>
                      {item?.specialistsList[0]?.specialist?.name}
                    </span>
                  </div>
                </div>
              ))}
          </div>
          <div className="row">
            <div className="col-12 text-end">
              <Link to="/doctors">
                <Tooltip title="See More">
                  <FaArrowAltCircleRight
                    style={{
                      fontSize: "3rem",
                      color: "var(--primary)",
                      filter: "drop-shadow(1px 6px 3px var(--secondary))",
                    }}
                  />
                </Tooltip>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurDoctors;
