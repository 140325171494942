import React from "react";
import logo from "../../images/logo-dummy.png";
import userImg from "../../images/doctor.png";
import "./AdminHeader.css";
import { Popover } from "antd";
import { Button, message } from "antd";
import { loggedOut } from "../../service/auth.service";
import useAuthCheck from "../../redux/hooks/useAuthCheck";

import { Link, useNavigate } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import { FaBars } from "react-icons/fa";

const AdminHeader = ({showDrawer}) => {
  const navigate = useNavigate();
  const { authChecked, data } = useAuthCheck();
  const handleSignOut = () => {
    loggedOut({ role: "admin" });
    message.success("Successfully Logged Out");
    navigate("/");
  };
  const items = [
    {
      label: (
        <div className="user-header">
          <div className="avatar avatar-sm">
            <img src={userImg} alt="" className="avatar-img rounded-circle" />
          </div>
          <div className="user-text">
            <h6>Super Admin</h6>
            <p className="text-muted mb-0">Administrator</p>
          </div>
        </div>
      ),
      key: "0",
    },
    {
      label: <a className="dropdown-item">My Profile</a>,
      key: "1",
    },
    {
      type: <a className="dropdown-item">Settings</a>,
    },
    {
      label: (
        <a className="dropdown-item" onClick={handleSignOut}>
          Logout
        </a>
      ),
      key: "3",
    },
  ];

  return (
    <div className="header flex">
      <div className="flex justify-between items-center  w-100 px-2">
      <div className="header-left flex justify-start items-center  ">
        <a href="index.html" className="logo">
          <img src={logo} alt="Logo" />
        </a>
      </div>
      <FaBars className="mobile-nav-toggle md:hidden" onClick={showDrawer} />
      </div>

      <header className=" overflow-hidden gap-10 items-center justify-end w-100 hidden md:flex">
 
  <form className="flex gap-10 justify-between items-center self-stretch px-2 py-2 my-auto whitespace-nowrap rounded-xl bg-neutral-100 min-h-[40px]">
    <div className="flex gap-3 items-center self-stretch my-auto text-sm leading-relaxed text-zinc-500">
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a793a9f8b109f3308142903e17540c1117093db99054eb2d641097667ff5e881?placeholderIfAbsent=true&apiKey=3920dfd3cbf0498b96904feda0b7992f" alt="" className="object-contain shrink-0 self-stretch my-auto aspect-square w-[18px]" />
      <label for="search-input" className="sr-only">Search</label>
      <input type="text" id="search-input" placeholder="Search..." className="bg-transparent border-none outline-none" />
    </div>
    <button type="submit" className="flex gap-1 items-center self-stretch my-auto text-base font-semibold tracking-wide text-gray-700">
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a7c362070fb84a742737a5924317782dd3191614c6f1c873a8f8b31baf479fe6?placeholderIfAbsent=true&apiKey=3920dfd3cbf0498b96904feda0b7992f" alt="" className="object-contain shrink-0 self-stretch my-auto aspect-square w-[18px]" />
      <span className="self-stretch my-auto">K</span>
    </button>
  </form>
  <nav className="flex self-stretch my-auto">
    <a href="#" aria-label="Notification">
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/bc58aecace236040d59e3e751faa826001ccddf7ef18bebdf69233d1cf121264?placeholderIfAbsent=true&apiKey=3920dfd3cbf0498b96904feda0b7992f" alt="" className="object-contain shrink-0 w-12 aspect-square" />
    </a>
    <a href="#" aria-label="Messages">
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e19e20f5f1fa73cb315b35ac0a7a1acef0f3af042d4548726846d5a13964e483?placeholderIfAbsent=true&apiKey=3920dfd3cbf0498b96904feda0b7992f" alt="" className="object-contain shrink-0 w-12 aspect-square" />
    </a>
    <div className="shrink-0 my-auto w-px h-10 border border-solid bg-neutral-100 border-neutral-100" role="separator"></div>
  </nav>
  <div className="flex gap-4 items-center self-stretch my-auto">
    <a
            className="dropdown-toggle nav-link"
            data-toggle="dropdown"
          >
            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <span className="user-img">
                    <img
                      className="object-contain shrink-0 self-stretch my-auto w-10 aspect-square"
                      src={userImg}
                      alt="Ryan Taylor"
                    />
                  </span>
                </Space>
              </a>
            </Dropdown>
          </a>
  </div>
</header>
    </div>
  );
};

export default AdminHeader;
