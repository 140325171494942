import { tagTypes } from "../tag-types"
import { baseApi } from "./baseApi"

const SPEC_URL = '/specialist'

export const  speciallistApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getAllSpecialist : build.query({
            query: (arg) => ({
                url: `${SPEC_URL}`,
                method: 'GET',
                params: arg
            }),
            transformResponse: (response) => {
                return {
                    specialist: response.data,
                    meta: response.meta
                }
            },
            providesTags: [tagTypes.specialist]
        }),
        getSpecialites: build.query({
            query: (id) => ({
                url: `${SPEC_URL}/${id}`,
                method: 'GET',
            }),
            providesTags: [tagTypes.specialist]
        }),
        updateSpecialites: build.mutation({
            query: ({ data, id }) => ({
                url: `${SPEC_URL}/${id}`,
                method: 'PATCH',
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }),
            invalidatesTags: [tagTypes.specialist]
        }),
        createSpecialites: build.mutation({
            query: ({ data }) => ({
                url: `${SPEC_URL}`,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }),
            invalidatesTags: [tagTypes.specialist]
        }),
        deleteSpecialites: build.mutation({
            query: (id) => ({
                url: `${SPEC_URL}/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: [tagTypes.specialist]
        }),
    })
})


export const {
    useGetAllSpecialistQuery,
    useGetSpecialitesQuery,
    useUpdateSpecialitesMutation,
    useCreateSpecialitesMutation,
    useDeleteSpecialitesMutation 
} = speciallistApi