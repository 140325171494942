import { tagTypes } from "../tag-types"
import { baseApi } from "./baseApi"

const HOS_URL = '/hospital'

export const hospitalApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getAllHospital: build.query({
            query: (arg) => ({
                url: `${HOS_URL}`,
                method: 'GET',
                params: { patientId: arg?.id } 
            }),
            transformResponse: (response) => {
                return {
                    hospitals: response.data,
                    meta: response.meta
                }
            },
            providesTags: [tagTypes.hospital]
        }),
        getHospitalDDL: build.query({
            query: () => ({
                url: `${HOS_URL}/dropdown`,
                method: 'GET',
            }),
            providesTags: [tagTypes.hospital]
        }),
        getHospital: build.query({
            query: ({ id, serviceId = 0 }) => ({
                url: `${HOS_URL}/${id}`,
                method: 'GET',
                params: {serviceId}
            }),
            providesTags: [tagTypes.hospital]
        }),
        updateHospital: build.mutation({
            query: ({ data, id }) => ({
                url: `${HOS_URL}/${id}`,
                method: 'PATCH',
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }),
            invalidatesTags: [tagTypes.hospital]
        }),
        createHospital: build.mutation({
            query: ({ data }) => ({
                url: `${HOS_URL}`,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }),
            invalidatesTags: [tagTypes.hospital]
        }),
        getDoctorHospitals: build.query({
            query: (id) => ({
                url: `${HOS_URL}/doctor-hospital/${id}`,
                method: 'GET'
            }),
            providesTags: [tagTypes.hospital]
        }),
        deleteHospital: build.mutation({
            query: (id) => ({
                url: `${HOS_URL}/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: [tagTypes.hospital]
        }),
       
    })
})


export const {
    useGetAllHospitalQuery,
    useGetHospitalDDLQuery,
    useGetHospitalQuery,
    useUpdateHospitalMutation,
    useCreateHospitalMutation,
    useDeleteHospitalMutation,
    useGetDoctorHospitalsQuery
} = hospitalApi

