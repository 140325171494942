import { tagTypes } from "../tag-types"
import { baseApi } from "./baseApi"

const PO_Url = '/paymentOptions'

export const PaymentOptionApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getPaymentOptions: build.query({
            query: (arg) => ({
                url: `${PO_Url}`,
                method: 'GET',
                params: arg
            }),
            transformResponse: (response) => {
                return {
                    PaymentOptions: response.data,
                    meta: response.meta
                }
            },
            providesTags: [tagTypes.paymentOptions]
        }),
        getPaymentOptionsDDL: build.query({
            query: () => ({
                url: `${PO_Url}/dropdown`,
                method: 'GET',
            }),
            providesTags: [tagTypes.paymentOptions]
        }),
        getPaymentOption: build.query({
            query: (id) => ({
                url: `${PO_Url}/${id}`,
                method: 'GET',
            }),
            providesTags: [tagTypes.paymentOptions]
        }),
        updatePaymentOption: build.mutation({
            query: ({ data, id }) => ({
                url: `${PO_Url}/${id}`,
                method: 'PATCH',
                data: data,

            }),
            invalidatesTags: [tagTypes.paymentOptions]
        }),
        createPaymentOption: build.mutation({
            query: ({ data }) => ({
                url: `${PO_Url}`,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }),
            invalidatesTags: [tagTypes.paymentOptions]
        }),
        deletePaymentOption: build.mutation({
            query: (id) => ({
                url: `${PO_Url}/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: [tagTypes.paymentOptions]
        }),
    })
})


export const {
    useGetPaymentOptionsQuery,
    useGetPaymentOptionsDDLQuery,
    useGetPaymentOptionQuery,
    useUpdatePaymentOptionMutation,
    useCreatePaymentOptionMutation,
    useDeletePaymentOptionMutation
} = PaymentOptionApi