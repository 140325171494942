import React, { useEffect, useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Button, message } from "antd";
import { Link } from "react-router-dom";
import ImageUpload from "../../UI/form/ImageUpload";
import dImage from "../../../images/avatar.jpg";
import "./UpsertSpecialites.css";
import { FaAngleLeft } from "react-icons/fa";
import {
  useCreateSpecialitesMutation,
  useGetSpecialitesQuery,
  useUpdateSpecialitesMutation,
} from "../../../redux/api/specialites";

const UpsertSpecialites = ({ setShowForm, id }) => {
  const [updateSpecialite, { isLoading, isSuccess, isError, error }] =
    useUpdateSpecialitesMutation();
  const [
    createSpecialite,
    {
      isLoading: createisLoading,
      isSuccess: createIsSuccess,
      isError: createIsError,
    },
  ] = useCreateSpecialitesMutation();
  const {
    data,
    isLoading: isLoadingDoctor,
    isError: isErrorDoctor,
  } = useGetSpecialitesQuery(id, {
    skip: !id, // Skip the query if `id` is null
  });

  const { register, handleSubmit, setValue, reset } = useForm({});
  const [selectValue, setSelectValue] = useState({});
  const [date, setDate] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedIconImage, setSelectedIconImage] = useState(null);
  const [file, setFile] = useState(null);
  const [Iconfile, iconSetFile] = useState(null);
  useEffect(() => {
    if (id && data) {
      const { name, description, img, icon } = data;
      setValue("name", name);
      setValue("description", description);
      setSelectedIconImage(icon || dImage);
      setSelectedImage(img || dImage);
    } else {
      reset();
    }
  }, [id, data, setValue, reset]);

  const handleChange = (e) => {
    setSelectValue({ ...selectValue, [e.target.name]: e.target.value });
  };
  const onChange = (date, dateString) => {
    setDate(moment(dateString).format());
  };

  const onSubmit = async (data) => {
    // Create a copy of the data object
    const obj = data;

    // Convert obj.price to a string if it exists

    // Merge obj with selectValue
    const newObj = { ...obj, ...selectValue };

    // Add date as 'dob' property to newObj if date exists

    // Convert selectedItems array to comma-separated string and add to newObj as 'services' property

    // Create a new object with only non-empty values from newObj
    const changedValue = Object.fromEntries(
      Object.entries(newObj).filter(([key, value]) => value !== "")
    );

    // Create a new FormData object
    const formData = new FormData();

    // Append selectedImage as 'file' to formData if selectedImage exists
    selectedImage && formData.append("file", file);
    selectedIconImage && formData.append("iconFile", Iconfile);

    // Convert changedValue object to JSON string
    const changeData = JSON.stringify(changedValue);

    // Append changeData as 'data' to formData
    formData.append("data", changeData);
    debugger;
    // If id exists, update doctor using updateDoctor mutation
    if (id) {
      await updateSpecialite({ data: formData, id });
      debugger;
    } else {
      // Otherwise, create a new doctor using createDoctor mutation
      await createSpecialite({ data: formData });
    }

    // Hide the form after submission
    setShowForm(false);
  };

  useEffect(() => {
    if (!isLoading && isError) {
      message.error(error?.data?.message);
    }
    if (isSuccess) {
      message.success("Successfully Changed Saved !");
    }
  }, [isLoading, isError, error, isSuccess]);
  useEffect(() => {
    if (!createisLoading && createIsError) {
      message.error(error?.data?.message);
    }
    if (createIsSuccess) {
      message.success("Successfully  Saved !");
    }
  }, [createisLoading, createIsError, createIsSuccess]);

  return (
    <div style={{ marginBottom: "10rem" }}>
      <div
        className="w-100 mb-3 rounded mb-5 p-2"
        style={{ background: "#f8f9fa" }}
      >
        <div className="top">
          <h5 className="text-title mb-2 mt-3">
            {id ? "Update" : "Create"} Specialites
          </h5>
          <button
            className="getbackButton"
            onClick={() => {
              setShowForm(false);
            }}
          >
            {" "}
            <FaAngleLeft className="inline" /> Go Back
          </button>
        </div>
        <form className="row form-row" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-12 mb-5">
            <div className="form-group">
              <div className="change-avatar d-flex gap-2 align-items-center">
                <Link className="my-3 patient-img">
                  <img
                    src={selectedImage ? selectedImage : data?.img || dImage}
                    alt=""
                  />
                </Link>
                <div className="mt-3">
                  <ImageUpload
                    setSelectedImage={setSelectedImage}
                    setFile={setFile}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-2 card-label">
                <label>
                  Name <span className="text-danger">*</span>
                </label>
                <input
                  defaultValue={data?.name}
                  {...register("name")}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <div className="change-avatar d-flex gap-2 align-items-center">
                  <Link className="my-3 patient-img">
                    <img
                      src={
                        selectedIconImage
                          ? selectedIconImage
                          : data?.img || dImage
                      }
                      alt=""
                      style={{
                        width: "3rem",
                        height: "3rem",
                        objectFit: "cover",
                      }}
                    />
                  </Link>
                  <div style={{ width: "45%" }}>
                    <ImageUpload
                      setSelectedImage={setSelectedIconImage}
                      setFile={iconSetFile}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group mb-2 card-label">
              <label>
                Description <span className="text-danger"></span>
              </label>
              <textarea
                defaultValue={data?.description}
                {...register("description")}
                className="form-control"
                rows={5}
              />
            </div>
          </div>

          {/* <div className="col-md-12">
                        <div className="card mb-2 p-3 mt-2">
                            <h6 className="card-title text-secondary">Experience</h6>
                            <div className="row form-row">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="form-group mb-2 card-label">
                                        <label>Hospital Name</label>
                                        <input defaultValue={data?.experienceHospitalName} {...register("experienceHospitalName")} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="form-group mb-2 card-label">
                                        <label>From</label>
                                        <input defaultValue={data?.expericenceStart} {...register("expericenceStart")} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="form-group mb-2 card-label">
                                        <label>To</label>
                                        <input defaultValue={data?.expericenceEnd} {...register("expericenceEnd")} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="form-group mb-2 card-label">
                                        <label>Designation</label>
                                        <input defaultValue={data?.designation} {...register("designation")} className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

          <div className="text-center my-3 ">
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              loading={isLoading}
              disabled={isLoading ? true : false}
            >
              {id
                ? isLoading
                  ? "Updating ..."
                  : "Update Changes"
                : createisLoading
                ? "Saving ..."
                : "Save Changes"}
            </Button>
            <Button
              htmlType="button"
              className="ms-2"
              size="large"
              onClick={() => setShowForm(false)}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpsertSpecialites;
