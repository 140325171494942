import React, { useState } from "react";
import "./InfoPage.css";
import { FaClock, FaHeadset, FaHouseUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import Popup from "../../UI/component/Popup";

const InfoPage = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");

  const handlePopup = (content) => {
    setPopupContent(content);
    setShowPopup(true);
  };
  return (
    // <section className="why-us mt-5 mt-md-0">
    //     <div className="container">

    //         <div className="row">
    //             <div className="col-lg-4 d-flex align-items-stretch">
    //                 <div className="content">
    //                     <h3>Why Choose Us?</h3>
    //                     <p>
    //                         Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit
    //                         Asperiores dolores sed et. Tenetur quia eos. Autem tempore quibusdam vel necessitatibus optio ad corporis.
    //                     </p>
    //                     <div className="text-center">
    //                         <Link href="/" className="more-btn">Learn More <i className="bx bx-chevron-right"></i></Link>
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="col-lg-8 d-flex align-items-stretch">
    //                 <div className="icon-boxes d-flex flex-column justify-content-center">
    //                     <div className="row">
    //                         <div className="col-xl-4 d-flex align-items-stretch">
    //                             <div className="icon-box mt-4 mt-xl-0">
    //                                 <FaHouseUser className="icon"/>
    //                                 <h4>Appointment</h4>
    //                                 <small className='text-secondary'>24 Hours Service</small>
    //                                 <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
    //                             </div>
    //                         </div>
    //                         <div className="col-xl-4 d-flex align-items-stretch">
    //                             <div className="icon-box mt-4 mt-xl-0">
    //                                 <FaHeadset className="icon"/>
    //                                 <h4>Emegency Cases</h4>
    //                                 <h6 className='text-secondary'>+88 01751 040425</h6>
    //                                 <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Qui facilis perferendis quia maxime. Laborum excepturi pariatur laboriosam nihil, dolor molestias.</p>
    //                             </div>
    //                         </div>
    //                         <div className="col-xl-4 d-flex align-items-stretch">
    //                             <div className="icon-box mt-4 mt-xl-0">
    //                                 <FaClock className="icon"/>
    //                                 <h4>Working Hours</h4>
    //                                 <small className='text-secondary'>Timing schedule</small>
    //                                 <ul className='list-group list-group-flush'>
    //                                 <li className="list-group-item d-flex justify-content-between text-nowrap" ><p>Sun - Wed : </p> <p>8:00 - 17: 00</p></li>
    //                                 <li className="list-group-item d-flex justify-content-between text-nowrap" ><p>Thus - Fri : </p> <p>9:00 - 17: 00</p></li>
    //                                 <li className="list-group-item d-flex justify-content-between text-nowrap" ><p>Sat - Sun : </p> <p>10:00 - 17: 00</p></li>
    //                                 </ul>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>

    //     </div>
    // </section>

    <section className="why-us">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card why-us">
              <h3 className="heading-why-us">Why Choose Us?</h3>
              <p>
                We are committed to providing the best possible care and
                services to our patients. Our team of doctors and nurses are
                highly skilled and experienced in their respective fields. We
                strive to create a warm, welcoming and friendly environment for
                our patients, and we are dedicated to providing the highest
                quality care possible.
              </p>
              <div className="text-center mt-3">
                <Link href="/" className="btn btn-secondary">
                  Learn More <i className="bx bx-chevron-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="card small-card">
              <div>
                <h4>
                  <FaHouseUser className="icon me-2 inline" /> Appointment
                </h4>
              </div>
              <div>
                <span className="info">24 Hours Service</span>
              </div>
            </div>
            <div className="card small-card mt-3">
              <div>
                <h4>
                  <FaHeadset className="icon me-2 inline" /> Emegency Cases
                </h4>
              </div>
              <div>
                <span className="info">+88 01751 040425</span>
              </div>
            </div>
            <div
              className="card small-card mt-3"
              style={{ cursor: "pointer" }}
              onClick={() => handlePopup("Working Hours: Timing schedule")}
            >
              <div>
                <h4>
                  <FaClock className="icon me-2 inline" /> Working Hours
                </h4>
              </div>
              <div>
                <span className="info">Timing schedule</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popup show={showPopup} handleClose={() => setShowPopup(false)}>
        <p className="working-hour">Working Hours</p>
        <span className="working-time">SUN-WED: 8:00-17:00</span>
        <br />
        <br />
        <span className="working-time mt-2">THUS-FRI: 9:00-17:00</span>
        <br />
        <br />
        <span className="working-time mt-2">SAT-SUN: 10:00-17:00</span>
        <br />
        <br />
      </Popup>
    </section>
  );
};

export default InfoPage;
