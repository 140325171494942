import { Empty } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import doctorBg from "../../images/img/doctors-bg.jpg";
import { useGetAllServicesQuery } from "../../redux/api/servicesApi";
import { truncate } from "../../utils/truncate";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
import SubHeader from "../Shared/SubHeader";

const Service = () => {
  const navigate = useNavigate();

  const handleNavigate = (id) => {
    navigate("/service/" + id);
  };

  const weArePleaseStyle = {
    backgroundColor: "antiquewhite",
    height: "60vh",
    background: `url(${doctorBg}) no-repeat`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    padding: "10px",
    position: "relative",
    marginTop: 100,
    marginBottom: 100,
  };

  const { data, isLoading, isError } = useGetAllServicesQuery();
  const services = data?.services;

  let content = null;
  if (!isLoading && isError) content = <div>Something Went Wrong !</div>;
  if (!isLoading && !isError && services?.length === 0)
    content = (
      <div>
        <Empty />
      </div>
    );
  if (!isLoading && !isError && services?.length > 0)
    content = (
      <>
        {services &&
          services.map((item, key) => (
            <div
              className="col-lg-4 col-md-6 col-sm-6 "
              key={key}
              onClick={() => {
                handleNavigate(item.id);
              }}
            >
              <div className="card shadow border-0 mb-5   ">
                <img
                  src={item?.img}
                  alt="{item.name}"
                  className="img-fluid"
                  style={{
                    minHeight: "17rem",
                    height: "15rem",
                    objectFit: "cover",
                  }}
                />
                <div className="p-2">
                  <h4 className="mt-4 mb-2">{item?.name}</h4>
                  <p className="mb-4 min-h-[5rem]">
                    {truncate(item?.description, 129)}
                  </p>
                </div>
              </div>
            </div>
          ))}
      </>
    );

  // const servicesToShow = services.slice(0, 9);
  return (
    <>
      <Header />
      <SubHeader
        title="Service"
        // subtitle="Lorem ipsum dolor sit amet consectetur adipisicing."
      />

      <div className="container" style={{ marginTop: 100 }}>
        <div className="row">
          {content}

          {/* {servicesToShow.map((services) => (
            // {
            //   Array(6).fill(null).map((_item, id) => (
                <div className="col-lg-4 col-md-6 col-sm-6" key={services.id}>
                  <div className="card shadow border-0 mb-5">
                    <img src={services.image || img} alt="" className="img-fluid" style={{ maxHeight: '17rem', height: '15rem', objectFit: 'cover' }} />
                    <div className="p-2">
                      <h4 className="mt-4 mb-2">{services.value}</h4>
                      <p className="mb-4">Saepe nulla praesentium eaque omnis perferendis a doloremque.</p>
                    </div>
                  </div>
                </div>
              ))
            } */}

          {/* ))} */}
        </div>
      </div>

      <section style={weArePleaseStyle}>
        <div
          className="container"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className="row">
            <div className="col-lg-7">
              <div className="d-flex align-items-center">
                <div className="mb-4 section-title text-center">
                  <h2 className="text-uppercase">
                    We are pleased to offer you the
                  </h2>
                  {/* <p className="form-text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Id,
                    sed.
                  </p> */}
                  <Link to={"/doctors"} className="btn-get-started scrollto">
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Service;
