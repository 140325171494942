import React, { useState, useEffect } from "react";
import { Tabs, Button, Empty } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../Shared/Header/Header";
import SubHeader from "../Shared/SubHeader";
import Footer from "../Shared/Footer/Footer";
import { useGetHospitalQuery } from "../../redux/api/hospital";
import SearchContent from "../Doctor/SearchDoctor/SearchContent";
import "./hospital.css";
import  GoogleMapComponent from "../Shared/GoogleMaps/googlemaps"


const { TabPane } = Tabs;

const HospitalDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeServiceId, setActiveServiceId] = useState(null);
  const { data, isLoading, isError } = useGetHospitalQuery({ id, serviceId: activeServiceId });

  const handleTabClick = (serviceId) => {
    setActiveServiceId(serviceId);
  };

  let content = null;
  if (!isLoading && isError) content = <div>Something Went Wrong!</div>;
  if (!isLoading && !isError && data?.id === undefined) content = <Empty />;
  if (!isLoading && !isError && data?.id)
    content = (
      <>
        <div className="col-md-12 col-sm-12 mt-3">
          <div className="specialist-image" style={{ position: "relative" }}>
            <img
              src={
                data?.img ||
                "https://static.vecteezy.com/system/resources/thumbnails/004/493/181/small_2x/hospital-building-for-healthcare-background-illustration-with-ambulance-car-doctor-patient-nurses-and-medical-clinic-exterior-free-vector.jpg"
              }
              alt={data?.name}
              style={{
                width: "100%",
                height: "30rem",
                objectFit: "cover",
                borderRadius: "0.2rem",
              }}
            />
            <div className="specialist-details-header text-center">
              <span
                className="doctor-name"
                style={{
                  textShadow: "4px 4px 5px rgba(0, 5, 0, 0.28)",
                  marginRight: "1rem",
                }}
              >
                {data?.name}
              </span>
            </div>
          </div>

          <div className="my-5">
            <p style={{ textAlign: "justify", marginTop: "5rem" }}>
              {data?.description}
            </p>
            <p>
              <b>Phone:</b> {data?.phone}
              <br />
              <b>Website:</b> {data?.webUrl}
              <br />
              <b>Address:</b> {data?.address}
            </p>
          </div>
          <GoogleMapComponent location={{
                                            lat: data?.latitude,
                                            lng: data?.longitude
                                        }}  disableFixButton={false}/>
        </div>
      </>
    );

  return (
    <>
      <Header />
      <SubHeader title="Hospital Details" subtitle="Lorem ipsum dolor sit amet." />
      <div className="container">
        <div className="row mt-5 mb-5">
          {content}

          <div className="col-md-12">
              <h2 className="my-2">Doctors under this Hospital</h2>
            <Tabs activeKey={String(activeServiceId)} onChange={(key) => handleTabClick(Number(key))}>
              <TabPane tab="All" key={0} />
              {data?.services?.map(({ service }) => (
                <TabPane tab={service.name} key={String(service.id)} />
              ))}
            </Tabs>

            <div className="px-5">
              {data?.hospitalDoctors?.map(({ doctor }) => (
                <SearchContent key={doctor.id} data={doctor} />
              ))}
            </div>

            <div className="text-center my-5">
              <Button
                type="primary"
                onClick={() => {
                  navigate(`/doctors?Hname=${data?.id}`);
                }}
              >
                View More
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HospitalDetails;
