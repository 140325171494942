import { message } from "antd";
import React, { useEffect } from "react";
import { FaArrowCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGetDoctorsQuery } from "../../../redux/api/doctorApi";
import { useAddFavouriteMutation } from "../../../redux/api/favouriteApi";
import "./BookDoctor.css";

import doctor2 from "../../../images/doc/doctor2.png";
import icon from "../../../images/img/tilde.png";
// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import Heading from "../../UI/component/Heading";

const BookDoctor = () => {
  const { data, isError, isLoading } = useGetDoctorsQuery({ limit: 10 });
  const doctors = data?.doctors;
  const [
    addFavourite,
    { isSuccess, isLoading: FIsLoading, isError: fIsError, error },
  ] = useAddFavouriteMutation();

  const handleAddFavourite = (id) => {
    addFavourite({ doctorId: id });
  };

  useEffect(() => {
    if (!FIsLoading && fIsError) {
      message.error(error?.data?.message);
    }
    if (isSuccess) {
      message.success("Successfully Favourite Added");
    }
  }, [isSuccess, fIsError, FIsLoading, error?.data?.message]);

  const calculateExperience = (year) => {
    const years = new Date().getFullYear() - year;
    return years;
  };

  // what to render
  let content = null;
  if (!isLoading && isError) content = <div>Something Went Wrong !</div>;
  if (!isLoading && !isError && doctors?.length === 0)
    content = <div>Empty</div>;
  if (!isLoading && !isError && doctors?.length > 0)
    content = (
      <>
        {doctors?.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="card book-doctor">
              <div className="row">
                <div className="col-4">
                  <Link to={`/doctors/profile/${item?.id}`}>
                    <img
                      src={item?.img || doctor2}
                      alt="doctor"
                      className="h-50 rounded-circle"
                    />
                  </Link>
                  {/* <a
                    style={{ cursor: "pointer" }}
                    className="position-absolute top-0 end-0 me-2"
                    onClick={() => handleAddFavourite(item?.id)}
                  >
                    <FaRegHeart />
                  </a> */}
                </div>
                <div className="col-8">
                  {/* {item?.experience && (
                    <span className="years-of-experience ms-3">
                      {item?.experience} Years Experience
                    </span>
                  )} */}
                  {item?.expericenceStart && (
                    <span className="years-of-experience ms-3">
                      {calculateExperience(item?.expericenceStart)} Years Experience
                    </span>
                  )}
                  <div className="position">{item?.designation}</div>
                  <div className="mt-5">
                    <StarRatings
                      rating={item?.rating || 5}
                      starRatedColor="#f4c150"
                      numberOfStars={5}
                      name="rating"
                      className="star"
                      starDimension="20px"
                      starSpacing="5px"
                    />
                  </div>
                  <div className="doctor-name">
                    {item?.firstName + " " + item?.lastName}
                  </div>
                  <div className="get-in-touch">
                    <Link to={`/booking/${item?.id}`}>
                      Get In Touch
                      <FaArrowCircleRight className="circle-arrow ms-2" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </>
    );

  return (
    <section className="book-doctor">
      <div className="container">
        <Heading name="Book Our Doctor" iconUrl={icon} />
        <div className="mt-5">
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            modules={[Navigation, Autoplay]}
            navigation={true}
            loop={true}
            centeredSlides={true}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            breakpoints={{
              640: { slidesPerView: 2 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 2.5 },
            }}
          >
            {content}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default BookDoctor;
