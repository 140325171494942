import React, { useEffect, useState } from "react";
import { Slider, Button, DatePicker, Radio } from "antd";
import { FaSearch, FaRedoAlt } from "react-icons/fa";
import Search from "antd/es/input/Search";
import { doctorSpecialistOptions } from "../../../constant/global";
import "./index.css";
import { useContext } from "react";
import { SettingContext } from "../../context/setting";

import { useGetDoctorsQuery } from "../../../redux/api/doctorApi";
import { useGetAllSpecialistQuery } from "../../../redux/api/specialites";
import { useGetAllServicesQuery } from "../../../redux/api/servicesApi";
import { useGetHospitalDDLQuery } from "../../../redux/api/hospital";
import { useLocation, useParams } from "react-router-dom";
import Hospital from "../../Admin/Hospital/Hospital";

const SearchSidebar = ({
  setSearchTerm,
  setSorByGender,
  setSpecialist,
  setPriceRange,
  setHospital,
  setService,
  resetFilter,
  query,
}) => {
  const { data: specialList } = useGetAllSpecialistQuery();
  const { data: servicesList } = useGetAllServicesQuery();

  const { data: hospitalList } = useGetHospitalDDLQuery();

  const setting = useContext(SettingContext);
  const [ShowPrice, setShowPrice] = useState(setting[0].isDisplayPrice);
  const [touched, settouched] = useState(true);

  useEffect(() => {}, [ShowPrice, hospitalList]);

  const handleDateChange = (_date, _dateString) => {};

  const handleDoctorChange = (e) => {
    onSearch(e.target.value);
  };
  const options = [
    {
      label: "Male",
      value: "male",
    },
    {
      label: "Female",
      value: "female",
    },
  ];
  const onSelectGender = (e) => setSorByGender(e.target.value);

  const onSelectSepcialist = (e) => setSpecialist(e.target.value);
  const onSelectService = (e) => setService(e.target.value);
  const onSelectHospital = (e) => {
    console.log(e.target.value);
    setHospital(e.target.value);
  };

  const onRangeChange = (range) => {
    const obj = {
      min: range[0],
      max: range[1],
    };
    setPriceRange(obj);
  };
  const onSearch = (value) => {
    setSearchTerm(value);
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const DName = queryParams.get("Dname");
  const HName = queryParams.get("Hname");
  const serviceId = queryParams.get("service");
  const SpecialId = queryParams.get("speciality");

  if (touched && serviceId) {
    setService(serviceId);
  }

  if (touched && HName) {
    setHospital(HName);
  }

  if (touched && DName) {
    setSearchTerm(DName);
  }

  if (touched && SpecialId) {
    setSpecialist(SpecialId);
  }

  return (
    <div className="col-md-12 col-lg-4 col-xl-3">
      <div
        className="doctor-filter-card mt-4 rounded"
        style={{ boxShadow: "#DFDFE1 4px 12px 9px 2px;", padding: "2rem" }}
      >
        <h5 className="text-center mb-3" style={{ color: "#05335c" }}>
          Doctor Filter
        </h5>
        <div className="mb-3">
          <Search
            placeholder="Search..."
            onSearch={(e) => {
              settouched(true);
              onSearch(e);
            }}
            enterButton
            allowClear
            defaultValue={touched && DName ? DName : ""}
          />
        </div>

        {/* <div className='mb-3'>
          <h6 style={{ color: '#05335c'}}>Date Range</h6>
          <DatePicker
            style={{ width: "100%" }}
            format="YYYY-MM-DD HH:mm:ss"
            onChange={handleDateChange}
          />
        </div> */}

        <div className="mb-3">
          <h6 style={{ color: "#05335c" }}>Gender</h6>
          <div className="d-flex flex-column">
            <Radio.Group options={options} onChange={onSelectGender} />
          </div>
        </div>
        {ShowPrice && (
          <div className="mb-3">
            <h6 style={{ color: "#05335c" }}>Price Range</h6>
            <Slider range defaultValue={[75, 150]} onChange={onRangeChange} />
          </div>
        )}
        <div className="mb-3">
          <label htmlFor="doctorSelect">Select Service</label>
          <select
            id="doctorSelect"
            className="form-control"
            onChange={(e) => {
              settouched(false);
              onSelectService(e);
            }}
            defaultValue={serviceId}
          >
            <option value="">Select a Service</option>
            {servicesList?.services?.map((service) => (
              <option key={service?.id} value={service?.id}>
                {service?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="doctorSelect">Select Hospitals</label>
          <select
            id="doctorSelect"
            className="form-control"
            onChange={(e) => {
              settouched(false);
              onSelectHospital(e);
            }}
            defaultValue={HName}
          >
            <option value="">Select a Hospital</option>
            {hospitalList?.map((service) => (
              <option key={service?.id} value={service?.id}>
                {service?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <h6 style={{ color: "#05335c" }}>Select Specialist</h6>
          <div className="d-flex flex-column mt-1">
            <Radio.Group
              options={specialList?.specialist?.map((item) => ({
                label: item?.name,
                value: item?.id,
              }))}
              onChange={(e) => {
                settouched(false);
                onSelectSepcialist(e);
              }}
              defaultValue={SpecialId}
              className="mt-2"
            />
          </div>
        </div>

        <Button
          className="w-100 mt-4 mb-2"
          type="primary"
          style={{ backgroundColor: "var(--primary)" }}
          shape="round"
          icon={<FaSearch />}
          size="sm"
        >
          Search
        </Button>
        {Object.keys(query).length > 4 && (
          <Button
            className="w-100 mt-4 mb-2"
            style={{ backgroundColor: "#1977cc" }}
            onClick={() => {
              settouched(false);
              resetFilter();
              setSearchTerm("");
            }}
            type="primary"
            shape="round"
            icon={<FaRedoAlt />}
            size="sm"
          >
            Reset
          </Button>
        )}
      </div>
    </div>
  );
};

export default SearchSidebar;
