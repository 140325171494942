export const appointStatusDsc = {
  payment:
    'Payment Status: Set to "paid" to indicate that the payment has been completed.',
  appointment: {
    FollowUp:
      'Set to "Follow-up" to indicate the need for a re-appointment with the doctor.',
    pending:
      'Set to "pending" to indicate that the appointment has not yet been scheduled. The next step is to schedule the appointment.',
    scheduled:
      'Changed to "Scheduled" to indicate that the appointment has been set for a specific date and time.',
    Completed:
      "Indicates that the appointment has been successfully completed.",
    Canceled:
      "Indicates that the appointment has been canceled by either the patient or the healthcare provider.",
    "Follow-up Scheduled":
      "Indicates that a follow-up appointment has been scheduled.",
    cancel: "Indicates that the appointment cannot proceed for some reason.",
    archived:
      "Indicates that the appointment is closed and has been moved to historical records.",
    InProgress: "Indicates that the appointment is currently ongoing.",
    confirmed:
      "Indicates that both the patient and doctor have confirmed the appointment date and time.",
  },
  followUpDate: "Indicates a different date set for a follow-up appointment.",
  prescriptionStatus: {
    issued:
      "Set to 'issued' to indicate that a prescription has been provided to the patient.",
    notIssued:
      "Set to 'not_issued' to indicate that a prescription has not been issued yet.",
  },
};
