import React, { useEffect, useState, version } from "react";
import { Modal, Button, message } from "antd";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {
  useDoctorSignUpMutation,
  usePatientSignUpMutation,
  useUserLoginMutation,
} from "../../redux/api/authApi";
import axios from "axios";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { is } from "date-fns/locale";
import { lastDayOfDecade } from "date-fns";

const appId = process.env.REACT_APP_FACEBOOK_ID;
const SocialSignUp = ({ isSignup }) => {
  const [error, setError] = useState("");

  const [infoError, setInfoError] = useState("");
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState([]); // Manage the profile state
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userType, setUserType] = useState("patient");
  const navigate = useNavigate();
  const [userLogin, { isError, isLoading, isSuccess, error: loginerror }] =
    useUserLoginMutation();

  const [
    doctorSignUp,
    {
      data: dData,
      isSuccess: dIsSuccess,
      isError: dIsError,
      error: dError,
      isLoading: dIsLoading,
    },
  ] = useDoctorSignUpMutation();
  const [
    patientSignUp,
    {
      data: pData,
      isSuccess: pIsSuccess,
      isError: pIsError,
      error: pError,
      isLoading: pIsLoading,
    },
  ] = usePatientSignUpMutation();

  const handleSignUpSuccess = () => {
    setLoading(false);
  };

  useEffect(() => {
    // doctor account

    if (isSuccess) {
      navigate("/", { replace: true });
    }

    if (isError) {
      console.log("error", loginerror);
      message.error("Account Doesnt Exist !! Please Sign Up");
    }

    if (dIsError && dError) {
      message.success("Email Already Exist !!");

      setLoading(false);
    }

    if (!dIsError && dIsSuccess) {
      handleSignUpSuccess();
      setLoading(false);

      swal({
        icon: "success",
        text: `Successfully Account Created Please Verify Your email`,
        timer: 5000,
      });
      userLogin({ email: profile.email, password: profile.id });
      navigate("/", { replace: true });
    }

    // Patient account
    if (pIsError && pError) {
      message.success("Email Already Exist !!");
      setLoading(false);
    }

    if (!pIsError && pIsSuccess) {
      handleSignUpSuccess();
      setLoading(false);

      swal({
        icon: "success",
        text: `Successfully ${
          userType === "doctor" ? "Doctor" : "Patient"
        } Account Created Please Login`,
        timer: 2000,
      });

      if (isSignup) {
        userLogin({ email: profile.email, password: profile.id });
      }
    }
  }, [
    dIsError,
    dError,
    isSuccess,
    pError,
    pIsError,
    ,
    pIsLoading,
    dIsLoading,
    pData,
    dData,
    isError,
    setLoading,
    dIsSuccess,
  ]);

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      if (codeResponse) {
        const accessToken = codeResponse.access_token;
        axios
          .get(
            `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
              },
            }
          )
          .then((res) => {
            setProfile(res.data);
            console.log("User Info:", res.data);
            setIsModalVisible(false);
            if (isSignup) {
              if (userType === "doctor") {
                doctorSignUp({
                  email: res.data.email,
                  password: res.data.id,
                  firstName: res.data.given_name,
                  lastName: res.data.family_name,
                  img: res.data.picture,
                  verified: true,
                });
              } else {
                patientSignUp({
                  email: res.data.email,
                  password: res.data.id,
                  firstName: res.data.given_name,
                  lastName: res.data.family_name,
                  img: res.data.picture,
                });
              }
            } else {
              userLogin({ email: res.data.email, password: res.data.id });
            }
          })

          .catch((err) => {
            console.error("Error fetching user info:", err); // Log the error
            setError("Failed to fetch user information. Please try again.");
          });
      }
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFacebookResponse = (response) => {
    if (response.status !== "unknown") {
      console.log("Facebook Sign-In Success:", response);
      // Handle the response from Facebook
      // You can send the token to your server for further processing
      setProfile(response);
    } else {
      setError("Facebook Sign-In Failed");
    }
  };

  return (
    <div>
      <div className="social-media">
        <div className="social-icon" onClick={isSignup ? showModal : login}>
          <FaGoogle />
        </div>
        <div className="social-icon">
          <FacebookLogin
            appId={appId}
            autoLoad={false}
            callback={handleFacebookResponse}
            scope={["public_profile", "email"]}
            render={(renderProps) => (
              <div onClick={renderProps.onClick}>
                <FaFacebook />
              </div>
            )}
          />
        </div>
        {/* <div className="social-icon">
                    <FaGithub />
                </div> */}
      </div>
      {error && <h6 className="text-danger text-center p-2">{error}</h6>}

      <Modal
        title="Sign Up As"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Button
          type="primary"
          block
          onClick={() => {
            login();
            setUserType("doctor");
          }}
          style={{ marginBottom: "10px", marginTop: "10px", height: "50px" }}
        >
          Doctor
        </Button>

        <Button
          type="primary"
          block
          onClick={() => {
            login();
            setUserType("patient");
          }}
          style={{ marginBottom: "10px", marginTop: "10px", height: "50px" }}
        >
          Patient
        </Button>
      </Modal>
    </div>
  );
};

export default SocialSignUp;
