import React, { useEffect, useState } from 'react'
import useAuthCheck from '../../../redux/hooks/useAuthCheck';
import UpsertDoctor from '../../Admin/Doctors/UpsertDoctor';

const DoctorProfileSetting = () => {
    const { data } = useAuthCheck();
    const [userId, setUserId] = useState('');
    useEffect(() => {
        if (data) {
            const { id } = data;
            setUserId(id);
        };
    }, [data]);


    return (
        <>
        <UpsertDoctor id={userId} />
        </>
    )
}

export default DoctorProfileSetting