import { SetStateAction, MouseEvent, Dispatch, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";
import { Button, DatePicker, Modal, Tag } from "antd";
import { useUpdateAppointmentByDoctorMutation } from "../../../redux/api/appointmentApi";
import { useNavigate } from "react-router-dom";
import { FaBriefcaseMedical } from "react-icons/fa";

const statusColors = {
  pending: "orange",
  accepted: "darkgreen",
  cancel: "red",
  scheduled: "green",
  completed: "blue",
  rescheduled: "#b64fc8",
};

const AppointmentInfo = ({
  open,
  handleClose,
  onDeleteEvent,
  currentEvent,
}) => {
  const [
    updateAppointment,
    { isSuccess: AisSuccess, isLoading: AisLoading, isError: AisError },
  ] = useUpdateAppointmentByDoctorMutation();
  const [ChangeShedule, SetChangeSchedule] = useState(false);
  const [newSchedule, setNewSchedule] = useState("");
  const navigate = useNavigate();
  const onClose = () => {
    handleClose();
  };
  const SaveNewSchedule = () => {
    SetChangeSchedule(false);
    let id = currentEvent?.id;

    if (newSchedule) {
      const data = {
        id: id,
        isPending: true,
        newScheduleDateAndTime: newSchedule,
        isPatientApproved: false,
        isCancelled: false,
        reScheduledByPatient: false,
      };
      updateAppointment({ id, data });
      onClose();
    }
  };
  const AppointmentCancel = () => {
    SetChangeSchedule(false);
    let id = currentEvent?.id;

    const data = {
      id: id,
      status: "cancel",
    };
    updateAppointment({ id, data });
    onClose();
  };
  const AppointmentAccepted = () => {
    SetChangeSchedule(false);
    let id = currentEvent?.id;

    const data = {
      id: id,
      status: "scheduled",
    };
    updateAppointment({ id, data });
    onClose();
  };

  const handleChange = (value) => {
    setNewSchedule(value.format("YYYY-MM-DD hh:mm A"));
    console.log(value.format("YYYY-MM-DD hh:mm A"));
  };

  return (
    <Modal
      open={open}
      title={
        <>
          <div className="flex justify-between gap-3  pe-4">
            <span>
              <img
                className="h-10 w-10 rounded-full object-cover"
                src={currentEvent?.patientImg}
                alt={currentEvent?.title}
                srcset=""
              />
            </span>
            <div>{currentEvent?.title}</div>
          </div>
          <hr />{" "}
        </>
      }
      onOk={onClose}
      onCancel={onClose}
      footer={[
        currentEvent?.status == "scheduled" &&
          currentEvent?.status !== "old" &&
          currentEvent?.status !== "Completed" && (
            <Button
              type="primary"
              icon={<FaBriefcaseMedical />}
              onClick={() => {
                navigate(
                  `/dashboard/appointment/treatment/${currentEvent?.id}`
                );
              }}
            >
              Treatment
            </Button>
          ),
        <Button
          style={{ background: "var(--primary)", color: "var(--white)" }}
          onClick={() => {
            navigate("/dashboard/appointments/" + currentEvent?.id);
          }}
        >
          View
        </Button>,
        currentEvent?.status?.toLowerCase() !== "completed" &&
          currentEvent?.status !== "old" &&
          currentEvent?.status !== "cancel" && (
            <Button
              key="cancel"
              type="primary"
              danger
              onClick={AppointmentCancel}
            >
              Cancel Appointment
            </Button>
          ),
        currentEvent?.status?.toLowerCase() !== "completed" &&
          currentEvent?.status !== "old" &&
          currentEvent?.status !== "cancel" &&
          currentEvent?.status !== "scheduled" && (
            <Button
              key="accept"
              type="primary"
              className="bg-green-500"
              onClick={AppointmentAccepted}
            >
              Accept
            </Button>
          ),
        <Button key="ok" type="primary" onClick={onClose}>
          OK
        </Button>,
      ]}
    >
      <div className="row mb-2">
        <div>
          Status:{" "}
          <Tag
            color={
              statusColors[currentEvent?.status?.toLowerCase()] || "default"
            }
            className="ms-2"
          >
            {currentEvent?.status}
          </Tag>
        </div>
      </div>
      <div className="row mb-2">
        <div>
          Schedule:{" "}
          <span className="ms-2">
            {currentEvent?.scheduleDate} {currentEvent?.scheduleTime}
          </span>
        </div>
      </div>
      {currentEvent?.status?.toLowerCase() !== "completed" &&
        currentEvent?.status !== "old" &&
        currentEvent?.status !== "cancel" && (
          <div className=" mb-4">
            <Button
              style={{ background: "var(--primary)", color: "var(--white)" }}
              onClick={() => {
                SetChangeSchedule(!ChangeShedule);
              }}
            >
              {ChangeShedule ? "Cancel" : "Change Schedule"}
            </Button>

            {ChangeShedule && (
              <>
                <DatePicker
                  className="me-2 mx-3"
                  showTime={{ use12Hours: true, format: "HH:mm a" }}
                  format="YYYY-MM-DD HH:mm"
                  onChange={handleChange}
                />
                <Button
                  className="bg-success text-white"
                  onClick={SaveNewSchedule}
                >
                  Save
                </Button>
              </>
            )}
          </div>
        )}
    </Modal>
  );
};

export default AppointmentInfo;
