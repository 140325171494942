import dayjs from "dayjs";
import { Link } from "react-router-dom";
import image1 from "../../../images/doc/doc1.jpg";
import icon from "../../../images/img/tilde.png";
import { useGetAllBlogsQuery } from "../../../redux/api/blogApi";
import { truncate } from "../../../utils/truncate";
import Heading from "../../UI/component/Heading";
import "./BlogHome.css";

const Blog = () => {
  const { data, isError, isLoading } = useGetAllBlogsQuery({ limit: 3 });
  const blogData = data?.blogs;
  let content = null;

  if (isLoading) content = <div>Loading...</div>;
  if (!isLoading && isError) content = <div>Something went wrong!</div>;
  if (!isLoading && !isError && blogData?.length === 0)
    content = <div>No blogs available</div>;
  if (!isLoading && !isError && blogData?.length > 0) {
    content = (
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="home-blog-content-wrapper mt-5">
            <img src={blogData[0].img || { image1 }} alt="Blog Image" />
            <div className="home-blog-content mt-3">
              <span className="form-text">
                {dayjs(blogData[0].createdAt).format("MMM D, YYYY hh:mm A")}
              </span>
              <p>{truncate(blogData[0].description, 180)}</p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="row">
            {blogData.slice(1, 3).map((item, index) => (
              <div className="row mt-5" key={item.id}>
                <div className="col-md-4 col-sm-12">
                  <img
                    src={item.img || "defaultImage.jpg"}
                    alt="Blog Side Image"
                    className="blog-side-image"
                  />
                </div>
                <div className="col-md-8 col-sm-12">
                  <div className="home-blog-content">
                    <span className="form-text">
                      {dayjs(item.createdAt).format("MMM D, YYYY hh:mm A")}
                    </span>
                    <p>{truncate(item.description, 180)}</p>
                    <Link to={`/blog/${item.id}`}>
                      <button className="btn btn-link border-0 text-primary">
                        Read More
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <section className="home-blog">
      <div className="container">
        <Heading name="Blogs" iconUrl={icon} />
        {content}
      </div>
    </section>
  );
};
export default Blog;
