import React from "react";
import { FaBriefcase } from "react-icons/fa";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const OverView = ({ data }) => {
  console.log(data);

  const showEducationTimeLine =
    data?.college || data?.degree || data?.completionYear;

  const showWorkTimeLine =
    data?.experienceHospitalName ||
    data?.expericenceStart ||
    data?.expericenceEnd;

  const showAwardsTimeLine = data?.awards || data?.awardsYear;

  return (
    <div className="col-md-12 col-lg-9">
      <div className="mb-3">
        <h5 className="overview-text">About Me</h5>
        <p className="text-secondary">{data?.biography}</p>
      </div>
      <div>
        <h5 className="overview-text">Education</h5>
        {showEducationTimeLine ? (
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#2e81c4", color: "#000" }}
              contentArrowStyle={{ borderRight: "7px solid  #2e81c4" }}
              date={data?.completionYear}
              iconStyle={{ background: "#2e81c4", color: "#fff" }}
              icon={<FaBriefcase />}
            >
              <h5 className="text-white">College : {data?.college}</h5>
              <h6 className="text-white">Degree : {data?.degree}</h6>
            </VerticalTimelineElement>
          </VerticalTimeline>
        ) : (
          <p>Educational timeline information is currently unavailable.</p>
        )}
      </div>
      <div>
        <h5 className="overview-text">Work & Experience</h5>

        {showWorkTimeLine ? (
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#2e81c4", color: "#000" }}
              contentArrowStyle={{ borderRight: "7px solid  #2e81c4" }}
              date={`${data?.expericenceStart} - ${
                data?.expericenceEnd ?? "Present"
              }`}
              iconStyle={{ background: "#2e81c4", color: "#fff" }}
              icon={<FaBriefcase />}
            >
              <p className="text-white">
                Hospital : {data?.experienceHospitalName}
              </p>
            </VerticalTimelineElement>
          </VerticalTimeline>
        ) : (
          <p>Work timeline information is currently unavailable.</p>
        )}
      </div>
      <div>
        <h5 className="overview-text">Awards</h5>

        {showAwardsTimeLine ? (
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: "#2e81c4", color: "#000" }}
              contentArrowStyle={{ borderRight: "7px solid  #2e81c4" }}
              date={data?.awardYear}
              iconStyle={{ background: "#2e81c4", color: "#fff" }}
              icon={<FaBriefcase />}
            >
              <h5 className="text-white">{data?.award}</h5>
            </VerticalTimelineElement>
          </VerticalTimeline>
        ) : (
          <p>Awards timeline information is currently unavailable.</p>
        )}
      </div>
      <div>
        <h5 className="overview-text">Services</h5>
        {data?.servicesList.length === 0 && <p>No Services</p>}
        <ul>
          {data &&
            data.servicesList.map(({ service }) => {
              return <li key={service.id}>{service.name}</li>;
            })}
        </ul>
      </div>
      <div>
        <h5 className="overview-text">Specializations</h5>
        {data?.specialistsList === 0 && <p>No Specializations</p>}
        <ul className="clearfix">
          {data &&
            data.specialistsList.map(({ specialist }) => {
              return <li key={specialist.id}>{specialist.name}</li>;
            })}
        </ul>
      </div>
    </div>
  );
};
export default OverView;
