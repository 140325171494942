import { Button, Empty } from "antd";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetServicesQuery } from "../../redux/api/servicesApi";
import SearchContent from "../Doctor/SearchDoctor/SearchContent";
import Footer from "../Shared/Footer/Footer";
import Header from "../Shared/Header/Header";
import SubHeader from "../Shared/SubHeader";
import "./index.css";

const ServiceDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useGetServicesQuery(id);
  // useEffect(() => {
  //   console.log(data);
  // }, [data]);
  let content = null;
  if (!isLoading && isError) content = <div>Something Went Wrong !</div>;
  if (!isLoading && !isError && data.id === undefined) content = <Empty />;
  if (!isLoading && !isError && data.id)
    content = (
      <>
        <div className="col-md-12 col-sm-12 mt-3">
          <div className="specialist-image" style={{ position: "relative" }}>
            <img
              src={data?.img}
              alt={data?.name}
              style={{
                width: "100%",
                height: "30rem",
                objectFit: "cover",
                borderRadius: "0.2rem",
              }}
            />

            <div className="specialist-details-header text-center">
              <span
                className="doctor-name"
                style={{
                  textShadow: "4px 4px 5px rgba(0, 5, 0, 0.28)",
                  marginRight: "1rem",
                }}
              >
                {data?.name}
              </span>
              {/* <img
                src={data?.icon}
                alt="icon"
                style={{ width: "2rem", height: "2rem", objectFit: "cover" }}
              /> */}
            </div>
          </div>

          <div className="mt-5">
            <p style={{ textAlign: "justify", marginTop: "5rem" }}>
              {data?.description} cardiology details page
            </p>
          </div>
        </div>
      </>
    );

  return (
    <>
      <Header />
      <SubHeader
        title="Service Details"
        subtitle="Comprehensive information on available treatments, facilities, and specialist care."
      />
      <div className="container ">
        <div className="row mt-5 mb-5">
          {content}

          <div className="col-md-12"></div>
        </div>
      </div>
      <h2 className="ms-5">Doctors under this Service</h2>

      {data?.doctors?.length === 0 ? (
        <Empty />
      ) : (
        <div className="px-5">
          {data?.doctors?.map(({ doctor }) => (
            <SearchContent key={doctor.id} data={doctor} />
          ))}
          <div className="text-center my-5">
            <Button
              type="primary"
              onClick={() => {
                console.log("View More");
                navigate("/doctors?service=" + data?.id);
              }}
            >
              View More
            </Button>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default ServiceDetails;
