import React, { useEffect, useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Button, Select, message } from "antd";
import { Link } from "react-router-dom";
import {
  useCreateDoctorMutation,
  useGetDoctorQuery,
  useUpdateDoctorMutation,
} from "../../../redux/api/doctorApi";
import useAuthCheck from "../../../redux/hooks/useAuthCheck";
import { doctorSpecialistOptions } from "../../../constant/global";
import ImageUpload from "../../UI/form/ImageUpload";
import dImage from "../../../images/doctor.png";
import { DatePicker } from "antd";

import "./UpsertDoctor.css";
import { FaAngleLeft } from "react-icons/fa";
import { useGetAllHospitalQuery } from "../../../redux/api/hospital";
import { useGetAllServicesQuery } from "../../../redux/api/servicesApi";
import { useGetAllSpecialistQuery } from "../../../redux/api/specialites";

const UpsertDoctor = ({ setShowForm, id }) => {
  const [selectedService, setSelectedServices] = useState([]);
  const [selectedSpecialist, setSelectedSpecialist] = useState([]);
  const [selectedhospital, setSelectedHospital] = useState([]);
  const [updateDoctor, { isLoading, isSuccess, isError, error }] =
    useUpdateDoctorMutation();
  const { data: specialitestList } = useGetAllSpecialistQuery();
  const { data: hospitalList } = useGetAllHospitalQuery();
  const { data: ServicesList } = useGetAllServicesQuery();

  const [
    createDoctor,
    {
      isLoading: createisLoading,
      isSuccess: createIsSuccess,
      isError: createIsError,
    },
  ] = useCreateDoctorMutation();
  const {
    data,
    isLoading: isLoadingDoctor,
    isError: isErrorDoctor,
  } = useGetDoctorQuery(id, {
    skip: !id, // Skip the query if `id` is null
  });

  const { register, handleSubmit, setValue, reset } = useForm({});
  const [userId, setUserId] = useState("");
  const [selectValue, setSelectValue] = useState({});
  const [date, setDate] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  useEffect(() => {
    if (id && data) {
      const {
        firstName,
        lastName,
        email,
        phone,
        gender,
        dob,
        biography,
        clinicName,
        clinicAddress,
        address,
        city,
        state,
        country,
        postalCode,
        price,
        degree,
        college,
        completionYear,
        experienceHospitalName,
        expericenceStart,
        expericenceEnd,
        designation,
        award,
        awardYear,
        registration,
        year,
        img,
        specialistsList = [],
        hospitalDoctors = [],
        servicesList = [],
      } = data;
      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("email", email);
      setValue("phone", phone);
      setValue("gender", gender);
      setValue("dob", dob);
      setValue("biography", biography);
      setValue("clinicName", clinicName);
      setValue("clinicAddress", clinicAddress);
      setValue("address", address);
      setValue("city", city);
      setValue("state", state);
      setValue("country", country);
      setValue("postalCode", postalCode);
      setValue("price", price);
      setValue("degree", degree);
      setValue("college", college);
      setValue("completionYear", completionYear);
      setValue("experienceHospitalName", experienceHospitalName);
      setValue("expericenceStart", expericenceStart);
      setValue("expericenceEnd", expericenceEnd);
      setValue("designation", designation);
      setValue("award", award);
      setValue("awardYear", awardYear);
      setValue("registration", registration);
      setValue("year", year);
      setSelectedServices(
        servicesList?.map(({ service }) => ({
          label: service.name,
          value: service.id,
        })) || []
      );

      setSelectedHospital(
        hospitalDoctors?.map(({ hospital }) => ({
          label: hospital.name,
          value: hospital.id,
        })) || []
      );
      setSelectedSpecialist(
        specialistsList?.map(({ specialist }) => ({
          label: specialist.name,
          value: specialist.id,
        })) || []
      );
      setDate(dob ? moment(dob) : null);
      setSelectedImage(img || dImage);
    } else {
      reset();
    }
  }, [id, data, setValue, reset]);

  const handleChange = (e) => {
    setSelectValue({ ...selectValue, [e.target.name]: e.target.value });
  };
  const onChange = (date, dateString) => {
    if (date) {
      setDate(moment(dateString));
      // Save the moment object
    } else {
      setDate(null); // Clear the date
    }
  };

  const onSubmit = async (data) => {
    // Create a copy of the data object
    const obj = data;

    // Convert obj.price to a string if it exists
    obj.price && obj.price.toString();

    // Merge obj with selectValue
    const newObj = { ...obj, ...selectValue };

    // Add date as 'dob' property to newObj if date exists
    date && (newObj["dob"] = date);

    // Convert selectedItems array to comma-separated string and add to newObj as 'services' property
    newObj["services"] = Array.isArray(selectedService)
      ? selectedService.map((service) => {
          return typeof service == "object" ? service.value : service;
        })
      : null;
    newObj["specialists"] = Array.isArray(selectedSpecialist)
      ? selectedSpecialist.map((speciallist) => {
          return typeof speciallist == "object"
            ? speciallist.value
            : speciallist;
        })
      : null;
    newObj["hospitals"] = Array.isArray(selectedhospital)
      ? selectedhospital.map((hospital) => {
          return typeof hospital == "object" ? hospital.value : hospital;
        })
      : null;

    debugger;

    // Create a new object with only non-empty values from newObj
    const changedValue = Object.fromEntries(
      Object.entries(newObj).filter(([key, value]) => value !== "")
    );

    // Create a new FormData object
    const formData = new FormData();

    // Append selectedImage as 'file' to formData if selectedImage exists
    selectedImage && formData.append("file", file);

    // Convert changedValue object to JSON string
    const changeData = JSON.stringify(changedValue);

    // Append changeData as 'data' to formData
    formData.append("data", changeData);
    // If id exists, update doctor using updateDoctor mutation
    if (id) {
      await updateDoctor({ data: formData, id });
    } else {
      // Otherwise, create a new doctor using createDoctor mutation
      await createDoctor({ data: formData });
    }

    // Hide the form after submission
    if (setShowForm) {
      setShowForm(false);
    }
  };

  useEffect(() => {
    if (!isLoading && isError) {
      message.error(error?.message);
    }
    if (isSuccess) {
      message.success("Successfully Changed Saved !");
    }
  }, [isLoading, isError, error, isSuccess]);
  useEffect(() => {
    if (!createisLoading && createIsError) {
      message.error(error?.data?.message);
    }
    if (createIsSuccess) {
      message.success("Successfully  Saved !");
    }
  }, [createisLoading, createIsError, createIsSuccess]);

  return (
    <div style={{ marginBottom: "10rem" }}>
      <div
        className="w-100 mb-3 rounded mb-5 p-2"
        style={{ background: "#f8f9fa" }}
      >
        <div className="top">
          <h5 className="text-title mb-2 mt-3">
            {id ? "Update" : "Create"} Doctor
          </h5>
          {setShowForm && (
            <button
              className="getbackButton"
              onClick={() => {
                setShowForm(false);
              }}
            >
              {" "}
              <FaAngleLeft className="inline" /> Go Back
            </button>
          )}
        </div>
        <form className="row form-row" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-12 mb-5">
            <div className="form-group">
              <div className="change-avatar d-flex gap-2 align-items-center">
                <Link to={"/"} className="my-3 patient-img">
                  <img
                    src={selectedImage ? selectedImage : data?.img || dImage}
                    alt=""
                  />
                </Link>
                <div className="mt-3">
                  <ImageUpload
                    setSelectedImage={setSelectedImage}
                    setFile={setFile}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>
                First Name <span className="text-danger">*</span>
              </label>
              <input
                defaultValue={data?.firstName}
                {...register("firstName")}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>
                Last Name <span className="text-danger">*</span>
              </label>
              <input
                defaultValue={data?.lastName}
                {...register("lastName")}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>Email</label>
              <input
                defaultValue={data?.email}
                {...register("email")}
                className="form-control"
              />
            </div>
          </div>
          {!id && (
            <div className="col-md-6">
              <div className="form-group mb-2 card-label">
                <label>Password</label>
                <input
                  type="password"
                  {...register("password")}
                  className="form-control"
                />
              </div>
            </div>
          )}

          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>Phone Number</label>
              <input
                defaultValue={data?.phone}
                {...register("phone")}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>Gender</label>
              <select
                className="form-control select"
                onChange={handleChange}
                name="gender"
                defaultValue={data?.gender || ""} // Set the value of the select
              >
                <option value={""}>Select</option>
                <option value={"male"}>male</option>
                <option value={"female"}>female</option>
              </select>
              {/* <select
                className="form-control select"
                {...register("gender")}
                defaultValue={data?.gender || ""}
              >
                <option value="">Select</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select> */}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>Date of Birth</label>
              <DatePicker
                onChange={onChange}
                format={"YYYY-MM-DD"}
                style={{ width: "100%", padding: "6px" }}
                value={date} // Set the value of the DatePicker
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="card mb-2 mt-2">
              <div className="card-body">
                <h6 className="card-title text-secondary">About Me</h6>
                <div className="form-group mb-2 card-label">
                  <label>Biography</label>
                  <textarea
                    defaultValue={data?.biography}
                    {...register("biography")}
                    className="form-control"
                    rows={5}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Hospital Info</h6>
              <div className="row form-row">
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Hospitals</label>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Please select"
                      value={selectedhospital}
                      onChange={setSelectedHospital}
                      options={hospitalList?.hospitals.map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-12">
                        <div className="card mb-2 p-3 mt-2">
                            <h6 className="card-title text-secondary">Clinic Info</h6>
                            <div className="row form-row">
                                <div className="col-md-6">
                                    <div className="form-group mb-2 card-label">
                                        <label>Clinic Name</label>
                                        <input defaultValue={data?.clinicName} {...register("clinicName")} className="form-control" rows={5} />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group mb-2 card-label">
                                        <label>Clinic Address</label>
                                        <input type="text" defaultValue={data?.clinicAddress} {...register("clinicAddress")} className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

          <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Contact Details</h6>
              <div className="row form-row">
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Address Line</label>
                    <input
                      defaultValue={data?.address}
                      {...register("address")}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>City</label>
                    <input
                      defaultValue={data?.city}
                      {...register("city")}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>State / Province</label>
                    <input
                      defaultValue={data?.state}
                      {...register("state")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Country</label>
                    <input
                      defaultValue={data?.country}
                      {...register("country")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Postal Code</label>
                    <input
                      defaultValue={data?.postalCode}
                      {...register("postalCode")}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Pricing</h6>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>30 Min Fee</label>
                    <input
                      defaultValue={data?.price}
                      {...register("price")}
                      type="number"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">
                Services and Specialization
              </h6>
              <div className="row form-row">
                <div className="form-group mb-2 card-label">
                  <label>Services</label>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    value={selectedService}
                    onChange={setSelectedServices}
                    options={ServicesList?.services?.map((service) => ({
                      label: service.name,
                      value: service.id,
                    }))}
                  />
                  <small className="form-text text-muted">
                    Note : Type & Press enter to add new services
                  </small>
                </div>
                <div className="form-group mb-2 card-label">
                  <label>Specialization </label>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select"
                    value={selectedSpecialist}
                    onChange={setSelectedSpecialist}
                    options={specialitestList?.specialist?.map(
                      (specialist) => ({
                        label: specialist.name,
                        value: specialist.id,
                      })
                    )}
                  />
                  {/* <input
                    defaultValue={data?.specialization}
                    {...register("specialization")}
                    className="input-tags form-control"
                    placeholder="Enter Specialization"
                  />
                  <small className="form-text text-muted">
                    Note : Type & Press enter to add new specialization
                  </small> */}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Education</h6>
              <div className="row form-row">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-2 card-label">
                    <label>Degree</label>
                    <input
                      defaultValue={data?.degree}
                      {...register("degree")}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-2 card-label">
                    <label>College/Institute</label>
                    <input
                      defaultValue={data?.college}
                      {...register("college")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-2 card-label">
                    <label>Year of Completion</label>
                    <input
                      defaultValue={data?.completionYear}
                      {...register("completionYear")}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Experience</h6>
              <div className="row form-row">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-2 card-label">
                    <label>Hospital Name</label>
                    <input
                      defaultValue={data?.experienceHospitalName}
                      {...register("experienceHospitalName")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-2 card-label">
                    <label>From</label>
                    <input
                      defaultValue={data?.expericenceStart}
                      {...register("expericenceStart")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-2 card-label">
                    <label>To</label>
                    <input
                      defaultValue={data?.expericenceEnd}
                      {...register("expericenceEnd")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group mb-2 card-label">
                    <label>Designation</label>
                    <input
                      defaultValue={data?.designation}
                      {...register("designation")}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Awards</h6>
              <div className="row form-row">
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Awards</label>
                    <input
                      defaultValue={data?.award}
                      {...register("award")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Year</label>
                    <input
                      defaultValue={data?.awardYear}
                      {...register("awardYear")}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card mb-2 p-3 mt-2">
              <h6 className="card-title text-secondary">Registrations</h6>
              <div className="row form-row">
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Registrations</label>
                    <input
                      defaultValue={data?.registration}
                      {...register("registration")}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2 card-label">
                    <label>Year</label>
                    <input
                      defaultValue={data?.year}
                      {...register("year")}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center my-3 ">
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              loading={isLoading}
              disabled={isLoading ? true : false}
            >
              {id
                ? isLoading
                  ? "Updating ..."
                  : "Update Changes"
                : createisLoading
                ? "Saving ..."
                : "Save Changes"}
            </Button>
            {setShowForm && (
              <Button
                htmlType="button"
                className="ms-2"
                size="large"
                onClick={() => setShowForm(false)}
              >
                Cancel
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpsertDoctor;
