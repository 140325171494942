import React from 'react';
import moment from 'moment';
import './index.css';
import { useGetTimeSlotByDoctorIdQuery } from '../../../redux/api/timeSlotApi';

const Availibility = ({ doctorId }) => {
    const { data, isError, isLoading } = useGetTimeSlotByDoctorIdQuery(doctorId);
    const today = moment().format('D MMM YYYY');
    const currentDay = moment().format('dddd').toUpperCase(); // Get current day in uppercase

    // Find today's slot
    const todaySlot = data ? data.find(slot => slot.day.toUpperCase() === currentDay) : null;

    return (
        <div className="col-md-6 offset-md-3">
            <div className="widget business-widget">
                <div className="widget-content">
                    <div className="listing-hours">
                        {todaySlot ? (
                            <div className="listing-day current d-flex align-items-center">
                                <div className="day">Today <span>{today}</span></div>
                                <div className="time-items">
                                    {todaySlot.timeSlot.map((timing, index) => (
                                        timing ? (
                                            <span className="time ms-4" key={index}>{timing.startTime} - {timing.endTime}</span>
                                        ) : (
                                            <span className="time ms-4" key={index}><span className="badge bg-danger-light" style={{ background: 'red' }}>Closed</span></span>
                                        )
                                    ))}
                                    <span className="open-status"><span className="badge bg-success-light" style={{ background: 'var(--primary)', marginLeft: '1rem' }}>Open Now</span></span>
                                </div>
                            </div>
                        ) : (
                            <div className="listing-day current d-flex align-items-center">
                                <div className="day">Today <span>{today}</span></div>
                                <div className="time-items">
                                    <span className="time ms-4"><span className="badge bg-danger-light" style={{ background: 'red' }}>Closed</span></span>
                                </div>
                            </div>
                        )}
                        <hr className='mt-2 mb-0' />
                        {data && data.map((slot, index) => (
                            <React.Fragment key={index}>
                                <div className="listing-day d-flex align-items-center">
                                    <div className="day">{slot?.day.toUpperCase()}</div>
                                    <div className="time-items">
                                        {slot.timeSlot.map((timing, idx) => (
                                            timing ? (
                                                <span className="time ms-4" key={idx}>{timing.startTime} - {timing.endTime}</span>
                                            ) : (
                                                <span className="time ms-4" key={idx}><span className="badge bg-danger-light" style={{ background: 'red' }}>Closed</span></span>
                                            )
                                        ))}
                                    </div>
                                </div>
                                <hr className='mt-2 mb-0' />
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Availibility;
