import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { bloodGrupOptions, DosageOptions, FrequencyOptions } from '../../../constant/global';
import { useUpdatePatientMutation } from '../../../redux/api/patientApi';
import useAuthCheck from '../../../redux/hooks/useAuthCheck';
import { Button, message, Space } from 'antd'; 
import ImageUpload from '../../UI/form/ImageUpload';
import pImage from '../../../images/avatar.jpg';
import { DatePicker } from 'antd';
import SelectFormForMedicine from '../../UI/form/SelectFormForMedicine';
import MedicineRangePickerForm from '../../UI/form/MedicineRangePickerForm';
import { FaPlus, FaRegTrashAlt } from 'react-icons/fa';
import InputAutoCompleteForm from "../../UI/form/InputAutoCompleteForm";
import UpsertPatients from '../../Admin/Patients/UpsertPatients';

const PatientProfileSetting = () => {
    const { data } = useAuthCheck();
    const [userId, setUserId] = useState('');
    useEffect(() => {
        if (data) {
            const { id } = data;
            setUserId(id);
        };
    }, [data]);

    return (
        <>
        <UpsertPatients id={userId} />
        </>
    )
}

export default PatientProfileSetting