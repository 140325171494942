import React, { useContext, useEffect, useState } from "react";
import Footer from "../../Shared/Footer/Footer";
import img from "../../../images/doc/doctor 3.jpg";
import "./index.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Empty, Button, message, Steps, Tooltip } from "antd";
import { useGetDoctorQuery } from "../../../redux/api/doctorApi";
import { FaArchway } from "react-icons/fa";
import { useGetAppointmentTimeQuery } from "../../../redux/api/timeSlotApi";
import moment from "moment";
import SelectDateAndTime from "../SelectDateAndTime";
import PersonalInformation from "../PersonalInformation";
import CheckoutPage from "../BookingCheckout/CheckoutPage";
import {
  useCreateAppointmentMutation,
  useGetAllDoctorAppointmentsQuery,
  useGetPatientAppointmentsQuery,
} from "../../../redux/api/appointmentApi";
import { useDispatch } from "react-redux";
import { addInvoice } from "../../../redux/feature/invoiceSlice";
import Header from "../../Shared/Header/Header";
import useAuthCheck from "../../../redux/hooks/useAuthCheck";
import { SettingContext } from "../../context/setting";
import WithoutLogin from "../withoutLogin";

const DoctorBooking = () => {
  const setting = useContext(SettingContext);
  const dispatch = useDispatch();
  let initialValue = {
    paymentMethod: "Insurance",
    paymentType: "One-time",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    reasonForVisit: "",
    description: "",
    address: "",
    nameOnCard: "",
    cardNumber: "",
    expiredMonth: "",
    cardExpiredYear: "",
    cvv: "",
    insurancePlanId: "",
  };
  const { data: loggedInUser, role } = useAuthCheck();
  const [current, setCurrent] = useState(0);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectDay, setSelecDay] = useState("");
  const [selectTime, setSelectTime] = useState("");
  const [isCheck, setIsChecked] = useState(false);
  const [patientId, setPatientId] = useState("");
  const [
    createAppointment,
    {
      data: appointmentData,
      isSuccess: createIsSuccess,
      isError: createIsError,
      error: createError,
      isLoading: createIsLoading,
    },
  ] = useCreateAppointmentMutation();

  const { doctorId } = useParams();
  const navigation = useNavigate();
  const { data, isLoading, isError, error } = useGetDoctorQuery(doctorId);
  const {
    data: time,
    refetch,
    isLoading: dIsLoading,
    isError: dIsError,
    error: dError,
  } = useGetAppointmentTimeQuery({ day: selectDay, id: doctorId });

  const timeslot = time?.map((item) => {
    return item?.slot?.time;
  });

  const [selectValue, setSelectValue] = useState(initialValue);
  const [IsdDisable, setIsDisable] = useState(true);
  const [IsConfirmDisable, setIsConfirmDisable] = useState(true);
  const [hospitalId, setHospitalId] = useState();
  const { authChecked } = useAuthCheck();
  const [disabledTimeslot, setDisabledTimeslot] = useState([]);

  const { data: patient } = useGetAllDoctorAppointmentsQuery();

  useEffect(() => {
    if (patient) {
      const appointmentCounts = {};
      patient.forEach((item) => {
        if (item.scheduleDate === selectedDate) {
          const slotTime = item.scheduleTime;
          if (!appointmentCounts[slotTime]) {
            appointmentCounts[slotTime] = 0;
          }
          appointmentCounts[slotTime]++;
        }
      });
      const newDisabledTimeslot = time
        ?.filter(
          (item) =>
            appointmentCounts[item.slot.time] >= item.slot.maximumPatient
        )
        .map((item) => item.slot.time);

      setDisabledTimeslot(newDisabledTimeslot);
    }
  }, [patient, time, selectedDate]);

  const [paymentMethod, setPaymentMethod] = useState("Credit");

  const handleChange = (e) => {
    if (e?.target) {
      setSelectValue({ ...selectValue, [e.target.name]: e.target.value });
    } else {
      // Handle cases where data is directly passed as an object (e.g., custom components)
      setSelectValue((prevValue) => ({
        ...prevValue,
        ...e,
      }));
    }
  };
  useEffect(() => {
    const {
      firstName,
      lastName,
      email,
      phone,
      nameOnCard,
      cardNumber,
      expiredMonth,
      cardExpiredYear,
      cvv,
      reasonForVisit,
    } = selectValue;
    const isInputEmpty =
      !firstName ||
      !lastName ||
      !email ||
      !phone ||
      !reasonForVisit ||
      !hospitalId;
    if (setting[0].isPaymentEnabled) {
      let isConfirmInputEmpty = false;
      if (paymentMethod === "Credit") {
        isConfirmInputEmpty =
          !nameOnCard ||
          !cardNumber ||
          !expiredMonth ||
          !cardExpiredYear ||
          !cvv ||
          !isCheck;
      } else if (paymentMethod === "Cash") {
        isConfirmInputEmpty = false;
      } else if (paymentMethod === "Insurance") {
        isConfirmInputEmpty = false;
      }
      setIsConfirmDisable(isConfirmInputEmpty);
    } else {
      setIsConfirmDisable(isInputEmpty);
    }
    setIsDisable(isInputEmpty);
  }, [selectValue, isCheck, paymentMethod]);

  const handleDateChange = (_date, dateString) => {
    setSelectedDate(dateString.split(" ")[0]);

    setSelecDay(moment(dateString).format("YYYY-MM-DD"));
    refetch();
  };
  const handleHospitalChange = (hospitalId) => {
    setHospitalId(hospitalId);
  };
  const disabledDateTime = (current) =>
    current && current < moment().add(1, "day").startOf("day");
  const handleSelectTime = (date) => {
    setSelectTime(date);
  };

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  let dContent = null;
  if (dIsLoading) dContent = <div>Loading ...</div>;
  if (!dIsLoading && dIsError) dContent = <div>Something went Wrong!</div>;
  if (!dIsLoading && !dIsError && time.length === 0)
    dContent = <Empty children="Doctor Is not Available" />;
  if (!dIsLoading && !dIsError && time.length > 0)
    dContent = (
      <>
        {time &&
          time.map((item, id) => (
            <div className="col-md-4" key={id + 155}>
              <Tooltip
                title={
                  disabledTimeslot.includes(item?.slot?.time)
                    ? "Max Patient Reached"
                    : ""
                }
              >
                <Button
                  type={item?.slot?.time === selectTime ? "primary" : "default"}
                  shape="round"
                  size="large"
                  className="mb-3"
                  onClick={() => handleSelectTime(item?.slot?.time)}
                  disabled={disabledTimeslot.includes(item?.slot?.time)}
                >
                  {item?.slot?.time}
                </Button>
              </Tooltip>
            </div>
          ))}
      </>
    );

  //What to render
  let content = null;
  if (!isLoading && isError) content = <div>Something Went Wrong!</div>;
  if (!isLoading && !isError && data?.id === undefined) content = <Empty />;
  if (!isLoading && !isError && data?.id)
    content = (
      <>
        <div className="booking-doc-img my-3 mb-3 rounded">
          <Link to={`/doctors/${data?.id}`}>
            <img src={data?.img || img} alt="" />
          </Link>
          <div className="text-start">
            <Link
              to={`/doctors/${data?.id}`}
              style={{ textDecoration: "none" }}
            >
              Dr. {data?.firstName + " " + data?.lastName}
            </Link>
            <p className="form-text mb-0">
              <FaArchway />{" "}
              {data?.servicesList.map(({ service }) => service.name).join(", ")}
            </p>
          </div>
        </div>
      </>
    );

  let steps = [
    {
      title: "Select Appointment Date & Time",
      content: (
        <SelectDateAndTime
          content={content}
          details={data}
          handleDateChange={handleDateChange}
          disabledDateTime={disabledDateTime}
          selectedDate={selectedDate}
          dContent={dContent}
          selectTime={selectTime}
          handleHospitalChange={handleHospitalChange}
        />
      ),
    },
    {
      title: "Patient Information",
      content: (
        <PersonalInformation
          handleChange={handleChange}
          selectValue={selectValue}
          setPatientId={setPatientId}
        />
      ),
    },
  ];
  if (setting[0].isPaymentEnabled) {
    steps.push({
      title: "Payment",
      content: (
        <CheckoutPage
          handleChange={handleChange}
          selectValue={selectValue}
          isCheck={isCheck}
          setPaymentMethod={setPaymentMethod}
          setIsChecked={setIsChecked}
          data={data}
          selectedDate={selectedDate}
          selectTime={selectTime}
        />
      ),
    });
  }

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const handleConfirmSchedule = () => {
    const obj = {};
    obj.patientInfo = {
      firstName: selectValue.firstName,
      lastName: selectValue.lastName,
      email: selectValue.email,
      phone: selectValue.phone,
      scheduleDate: selectedDate,
      scheduleTime: selectTime,
      doctorId: doctorId,
      hospitalId: hospitalId,
      description: selectValue.description,
      reasonForVisit: selectValue.reasonForVisit,
      recentTest: selectValue.recentTest,
      currentMedications: selectValue.currentMedications,
      allergies: selectValue.allergies,
      existingConditions: selectValue.existingConditions,
      patientId: role !== "" && role === "patient" ? patientId : undefined,
    };
    setting[0].isPaymentEnabled &&
      (obj.payment = {
        paymentType: selectValue.paymentType,
        paymentMethod: paymentMethod,
        cardNumber: selectValue.cardNumber,
        cardExpiredYear: selectValue.cardExpiredYear,
        cvv: selectValue.cvv,
        expiredMonth: selectValue.expiredMonth,
        nameOnCard: selectValue.nameOnCard,
        insurancePlanId: selectValue.insurancePlanId,
      });
    createAppointment(obj);
  };

  useEffect(() => {
    if (createIsSuccess) {
      message.success("Succcessfully Appointment Scheduled");
      setSelectValue(initialValue);
      dispatch(addInvoice({ ...appointmentData }));
      navigation(`/booking/success/${appointmentData.id}`);
    }
    if (createIsError) {
      message.error(error?.data?.message);
    }
  }, [createIsSuccess, createError]);
  return (
    <>
      <Header />
      {!authChecked ? (
        <WithoutLogin />
      ) : (
        <div
          className="container"
          style={{ marginBottom: "12rem", marginTop: "8rem" }}
        >
          <Steps current={current} items={items} />
          <div className="mb-5 mt-3 mx-3">{steps[current].content}</div>
          <div className="text-end mx-3">
            {current < steps.length - 1 && (
              <Button
                type="primary"
                disabled={
                  current === 0
                    ? selectTime && hospitalId
                      ? false
                      : true
                    : IsdDisable || !selectTime
                }
                onClick={() => next()}
              >
                Next
              </Button>
            )}

            {current === steps.length - 1 && (
              <Button
                type="primary"
                disabled={IsConfirmDisable}
                loading={createIsLoading}
                onClick={handleConfirmSchedule}
              >
                Confirm
              </Button>
            )}
            {current > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                Previous
              </Button>
            )}
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default DoctorBooking;
