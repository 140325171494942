import React, { useEffect } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import "./Hospital.css";
import { AutoComplete } from "antd";
import { useState } from "react";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { Tooltip } from "antd";
import { Space, Table, Tag } from "antd";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaEllipsisH } from "react-icons/fa";
import { message } from "antd";
import UpsertHospital from "./UpsertHospital";
import {
  useDeleteHospitalMutation,
  useGetAllHospitalQuery,
} from "../../../redux/api/hospital";
const mockVal = (str, repeat = 1) => ({
  value: str.repeat(repeat),
});

const Hospital = () => {
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [anotherOptions, setAnotherOptions] = useState([]);
  const [dataList, setdataList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const {
    data: HospitalList,
    refetch,
    isError,
    isLoading,
  } = useGetAllHospitalQuery();
  const [selectedHospitalId, setSelectedHospitalId] = useState(null); // New state for selected doctor ID

  const [deleteHospital] = useDeleteHospitalMutation();
  const deleteHandler = async (id) => {
    // message.loading("Deleting ...");
    try {
      const res = await deleteHospital(id);
      if (res) {
        message.success("Successfully Deleted !!");
        refetch();
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "descritption",
      render: (descritption) => <>{descritption?.substring(0, 10)}...</>,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (Address) => <>{Address?.substring(0, 10)}...</>,
    },
    {
      title: "WebUrl",
      dataIndex: "webUrl",
      key: "webUrl",
      render: (webUrl) => <>{webUrl?.substring(0, 10)}...</>,
    },
    {
      title: "GoogleLocation",
      dataIndex: "googleLocation",
      key: "googleLocation",
      render: (googleLocation) => <>{googleLocation?.substring(0, 10)}...</>,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <a
              onClick={() => {
                setSelectedHospitalId(record.key); // Set the selected doctor ID
                setShowForm(true);
              }}
            >
              <FaEdit />
            </a>
          </Tooltip>
          <Tooltip title="Delete" onClick={() => deleteHandler(record.key)}>
            <a>
              <FaRegTrashAlt />{" "}
            </a>
          </Tooltip>
        </Space>
      ),
    },
  ];
  useEffect(() => {
    let data = HospitalList?.hospitals.map((doctor) => {
      return {
        key: doctor.id,
        name: doctor.name,
        description: doctor.description,
        address: doctor.description,
        webUrl: doctor.webUrl,
        phone: doctor.description,
        googleLocation: doctor.description,
        // img: doctor.img,
      };
    });
    setdataList(data);
  }, [HospitalList]);

  const getPanelValue = (searchText) =>
    !searchText
      ? []
      : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)];

  const onSelect = (data) => {
    console.log("onSelect", data);
  };

  const onChange = (data) => {
    setValue(data);
  };

  return (
    <>
      <AdminLayout>
        {showForm ? (
          <UpsertHospital setShowForm={setShowForm} id={selectedHospitalId} />
        ) : (
          <div className="doctorContainer ">
            <div className="doctorHeader">
              <h2 class="doctortitle text-black ">Hospital List</h2>
              <button
                className="doctorbtn"
                onClick={() => {
                  setShowForm(true);
                  setSelectedHospitalId(null);
                }}
              >
                New Hospital
              </button>
            </div>
            <div className="doctorSearchRow mb-3">
              <AutoComplete
                className="auto-search"
                options={options}
                onSelect={onSelect}
                onSearch={(text) => setOptions(getPanelValue(text))}
                placeholder="input here"
              />
              <div className="rightSearch">
                <span className="text-black">
                  <Tooltip title="Export PDf">
                    <FaCloudDownloadAlt />
                  </Tooltip>
                  Import
                </span>
                <span className="text-black">
                  More
                  <FaEllipsisH />
                </span>
              </div>
            </div>
            <div className="w-100">
              <Table columns={columns} dataSource={dataList} />
            </div>
          </div>
        )}
      </AdminLayout>
    </>
  );
};
export default Hospital;
