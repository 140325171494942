import React, { useState } from "react";
import AdminHeader from "../../UI/AdminHeader";
import "./AdminLayout.css";
import AdminSidebar from "../../UI/SideBarComponet/AdminSidebar";
import { Button, Drawer } from "antd";

const AdminLayout = ({ children }) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen((prev) => !prev);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="main-wrapper bg-[#f5f5f5]">
      <AdminHeader showDrawer={showDrawer} />
      <div className="hidden md:block">
        <AdminSidebar />
      </div>
      <div className="page-wrapper md:ml-[240px]">
        <div className="content container-fluid">{children}</div>
      </div>
      <Drawer
        placement="left"
        width={300}
        onClose={onClose}
        open={open}
        size="default"
      >
        <AdminSidebar />
      </Drawer>
    </div>
  );
};

export default AdminLayout;
