import React, { useEffect } from 'react'
import AdminLayout from '../AdminLayout/AdminLayout'
import './Reviews.css';
import img from '../../../images/avatar.jpg';
import { useGetAllReviewsQuery, useUpdateReviewMutation } from '../../../redux/api/reviewsApi';
import { FaCheck, FaRegThumbsDown, FaRegThumbsUp, FaSkullCrossbones } from "react-icons/fa";
import moment from 'moment';
import StarRatings from 'react-star-ratings';
import useAuthCheck from '../../../redux/hooks/useAuthCheck';
import { Button, Empty , message} from 'antd';

const AdminReviews = () => {
    const { data,refetch, isError, isLoading } = useGetAllReviewsQuery();
    const [  updateReview,{ isLoading: Uisloading, isSuccess, isError: UisError} ] = useUpdateReviewMutation();

    useEffect(() => { console.log(data) }, [data])


    const update = async (data, id)=>{
       if (id) {
        await updateReview({id, data: {'isActive':data} });
      } 
    }
    useEffect(()=>{
        if(UisError){
            message.success("Review updated Failed", UisError);
        }
        if(isSuccess){
            message.success("Review updated successfully")
            refetch();
        }
    }, [isLoading,,UisError, isSuccess ])
    let content = null;
    if (!isLoading && isError) content = <div>Something Went Wrong !</div>
    if (!isLoading && !isError && data?.length === 0) content = <Empty />
    if (!isLoading && !isError && data?.length > 0) content =
        <>
            {
                data && data.map((item, key) => (
                    <div className='mb-4' key={item?.id + key}>
                        <div className='d-flex gap-3 justify-content-between'>
                            <div className='d-flex gap-4'>
                                <div className='review-img'>
                                    <img className="h-100 w-100 rounded-circle" alt="" src={item?.patient?.img ? item?.patient?.img : img} />
                                </div>
                                <div>
                                    <h5 className="text-nowrap text-capitalize">{item?.patient?.firstName + ' ' + item?.patient?.lastName}</h5>
                                    <p className={item?.isRecommended ? 'text-success' : 'text-danger'}>
                                        {item?.isRecommended ? (
                                            <>
                                                <FaRegThumbsUp /> I recommend the doctor
                                            </>
                                        ) : (
                                            <>
                                                <FaRegThumbsDown /> I do not recommend the doctor
                                            </>
                                        )}
                                    </p>

                                </div>
                            </div>

                            <div className='text-end'>
                                <div>
                                    <StarRatings
                                        rating={Number(item?.star ?? '')}
                                        starRatedColor="#f4c150"
                                        numberOfStars={5}
                                        name='rating'
                                        starDimension="15px"
                                        starSpacing="2px"
                                    />
                                </div>
                                <div className="">Reviewed {moment(item?.createdAt).fromNow()}</div>
                            </div>
                            <div className='d-flex gap-4'>
                                {
                                    item?.isActive? (
                                        <Button
                                            danger
                                            onClick={()=>{update(false, item.id)}}
                                        >
                                            < FaSkullCrossbones/> Deactivate
                                        </Button>
                                    ) : (
                                        <Button
                                            type="primary"
                                            onClick={()=>{update(true, item.id)}}
                                        >
                                            <FaCheck /> Activate
                                        </Button>
                                    )
                                }
                                
                               
                            </div>
                        </div>
                        <div>
                            <p className="mx-2 form-text">{item?.description}</p>
                        </div>
                        <hr />
                    </div>
                ))
            }
        </>
    return (
        <>
            <AdminLayout >
                <div className="w-100 mb-3 rounded py-3 px-2" style={{ background: '#f8f9fa' }}>
                    {content}
                </div>
            </AdminLayout>
        </>
    )
}
export default AdminReviews;