import { tagTypes } from "../tag-types"
import { baseApi } from "./baseApi"

const SERVICE_URL = '/services'

export const  servicesApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getAllServices: build.query({
            query: (arg) => ({
                url: `${SERVICE_URL}`,
                method: 'GET',
                params: arg
            }),
            transformResponse: (response) => {
                return {
                    services: response.data,
                    meta: response.meta
                }
            },
            providesTags: [tagTypes.services]
        }),
        getServices: build.query({
            query: (id) => ({
                url: `${SERVICE_URL}/${id}`,
                method: 'GET',
            }),
            providesTags: [tagTypes.services]
        }),
        updateServices: build.mutation({
            query: ({ data, id }) => ({
                url: `${SERVICE_URL}/${id}`,
                method: 'PATCH',
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }),
            invalidatesTags: [tagTypes.services]
        }),
        createServices: build.mutation({
            query: ({ data }) => ({
                url: `${SERVICE_URL}`,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }),
            invalidatesTags: [tagTypes.services]
        }),
        deleteServices: build.mutation({
            query: (id) => ({
                url: `${SERVICE_URL}/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: [tagTypes.services]
        }),
    })
})


export const {
    useGetAllServicesQuery,
    useGetServicesQuery,
    useUpdateServicesMutation,
    useCreateServicesMutation,
    useDeleteServicesMutation 
} = servicesApi