import React from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import "./Patients.css";
import { AutoComplete } from "antd";
import { useState } from "react";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { Tooltip } from "antd";
import { Space, Table, Tag } from "antd";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaEllipsisH } from "react-icons/fa";
import { useEffect } from "react";
import { message } from "antd";
import UpsertPatients from "./UpsertPatients";
import {
  useDeletePatientMutation,
  useGetAllPatientQuery,
} from "../../../redux/api/patientApi";
import { compose } from "@reduxjs/toolkit";

const mockVal = (str, repeat = 1) => ({
  value: str.repeat(repeat),
});

const Patients = () => {
  let data = [];
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [anotherOptions, setAnotherOptions] = useState([]);
  const [dataList, setdataList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const {
    data: PatientList,
    refetch,
    isError,
    isLoading,
  } = useGetAllPatientQuery();
  const [selectedDoctorId, setSelectedDoctorId] = useState(null); // New state for selected doctor ID
  const [deletePatient, isSucess] = useDeletePatientMutation();
  const deleteHandler = async (id) => {
    // message.loading("Deleting ...");
    try {
      const res = await deletePatient(id);
      if (res.error) {
        message.error(res.error.data.message);
        refetch();
      } else {
        message.success("Successfully Deleted !!");
        refetch();
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <a
              onClick={() => {
                setSelectedDoctorId(record.key); // Set the selected doctor ID
                setShowForm(true);
              }}
            >
              <FaEdit />
            </a>
          </Tooltip>
          <Tooltip title="Delete" onClick={() => deleteHandler(record.key)}>
            <a>
              <FaRegTrashAlt />{" "}
            </a>
          </Tooltip>
        </Space>
      ),
    },
  ];
  useEffect(() => {
    console.log(PatientList);
    let data = PatientList?.map((patient) => {
      return {
        key: patient.id,
        name: patient.firstName + " " + patient.lastName,
        email: patient.email,
        gender: patient.gender,
        address: patient.address,
      };
    });
    setdataList(data);
  }, [PatientList, isSucess]);

  const getPanelValue = (searchText) =>
    !searchText
      ? []
      : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)];

  const onSelect = (data) => {
    // console.log('onSelect', data);
  };

  const onChange = (data) => {
    setValue(data);
  };

  return (
    <>
      <AdminLayout>
        {showForm ? (
          <UpsertPatients setShowForm={setShowForm} id={selectedDoctorId} />
        ) : (
          <div className="doctorContainer">
            <div className="doctorHeader">
              <h2 class="doctortitle text-black">Patient</h2>
              <button
                className="doctorbtn"
                onClick={() => {
                  setShowForm(true);
                  setSelectedDoctorId(null);
                }}
              >
                New Patient
              </button>
            </div>

            <div className="doctorSearchRow mb-3">
              <AutoComplete
                className="auto-search"
                options={options}
                onSelect={onSelect}
                onSearch={(text) => setOptions(getPanelValue(text))}
                placeholder="input here"
              />
              <div className="rightSearch">
                <span className="text-black">
                  <Tooltip title="Export PDf">
                    <FaCloudDownloadAlt style={{ color: "black" }} />
                  </Tooltip>
                  Import
                </span>
                <span className="text-black">
                  More
                  <FaEllipsisH />
                </span>
              </div>
            </div>
            <div className="w-100">
              <Table columns={columns} dataSource={dataList} />
            </div>
          </div>
        )}
      </AdminLayout>
    </>
  );
};
export default Patients;
