import React, { useEffect, useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Button, message, Select } from "antd";
import { Link } from "react-router-dom";
import ImageUpload from "../../UI/form/ImageUpload";
import dImage from "../../../images/avatar.jpg";
import "./UpsertHospital.css";
import { FaAngleLeft } from "react-icons/fa";
import {
  useCreateHospitalMutation,
  useGetHospitalQuery,
  useUpdateHospitalMutation,
} from "../../../redux/api/hospital";
import GoogleMapComponent from "../../Shared/GoogleMaps/googlemaps";
import { useGetAllServicesQuery } from "../../../redux/api/servicesApi";
const UpsertHospital = ({ setShowForm, id }) => {
  const [updateHospital, { isLoading, isSuccess, isError, error }] =
    useUpdateHospitalMutation();
  const [
    createHospital,
    {
      isLoading: createisLoading,
      isSuccess: createIsSuccess,
      isError: createIsError,
    },
  ] = useCreateHospitalMutation();
  const {
    data,
    isLoading: isLoadingDoctor,
    isError: isErrorDoctor,
  } = useGetHospitalQuery(
    { id },
    {
      skip: !id, // Skip the query if `id` is null
    }
  );

  const { register, handleSubmit, setValue, reset } = useForm({});
  const [selectValue, setSelectValue] = useState({});
  const [date, setDate] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [location, setLocation] = useState(null);
  const { data: ServicesList } = useGetAllServicesQuery();
  const [selectedService, setSelectedServices] = useState([]);

  useEffect(() => {
    if (id && data) {
      const {
        name,
        description,
        address,
        webUrl,
        phone,
        latitude,
        longitude,
        services = [],
      } = data;
      setValue("name", name);
      setValue("description", description);
      setValue("address", address);
      setValue("webUrl", webUrl);
      setValue("phone", phone);
      setSelectedServices(
        services?.map(({ service }) => ({
          label: service.name,
          value: service.id,
        })) || []
      );

      if (latitude != null || longitude != null) {
        setLocation({
          lat: latitude,
          lng: longitude,
        });
      }
    } else {
      reset();
    }
  }, [id, data, setValue, reset]);

  const handleChange = (e) => {
    setSelectValue({ ...selectValue, [e.target.name]: e.target.value });
  };

  const onChange = (date, dateString) => {
    setDate(moment(dateString).format());
  };

  const onSubmit = async (data) => {
    // Create a copy of the data object
    const obj = data;

    // Convert obj.price to a string if it exists
    const newObj = {
      ...obj,
      ...selectValue,
      latitude: location?.lat,
      longitude: location?.lng,
    };
    newObj["services"] = Array.isArray(selectedService)
      ? selectedService.map((service) => {
          return typeof service == "object" ? service.value : service;
        })
      : null;
    // Merge obj with selectValue
    // const newObj = { ...obj, ...selectValue };

    // Add date as 'dob' property to newObj if date exists

    // Convert selectedItems array to comma-separated string and add to newObj as 'services' property

    // Create a new object with only non-empty values from newObj
    const changedValue = Object.fromEntries(
      Object.entries(newObj).filter(([key, value]) => value !== "")
    );

    // Create a new FormData object
    const formData = new FormData();

    // Append selectedImage as 'file' to formData if selectedImage exists
    selectedImage && formData.append("file", file);

    // Convert changedValue object to JSON string
    const changeData = JSON.stringify(changedValue);

    // Append changeData as 'data' to formData
    formData.append("data", changeData);
    // If id exists, update doctor using updateDoctor mutation
    if (id) {
      await updateHospital({ data: formData, id });
    } else {
      // Otherwise, create a new doctor using createDoctor mutation
      await createHospital({ data: formData });
    }

    // Hide the form after submission
    setShowForm(false);
  };

  useEffect(() => {
    if (!isLoading && isError) {
      message.error(error?.data?.message);
    }
    if (isSuccess) {
      message.success("Successfully Changed Saved !");
    }
  }, [isLoading, isError, error, isSuccess]);
  useEffect(() => {
    if (!createisLoading && createIsError) {
      message.error(error?.data?.message);
    }
    if (createIsSuccess) {
      message.success("Successfully  Saved !");
    }
  }, [createisLoading, createIsError, createIsSuccess]);

  return (
    <div style={{ marginBottom: "10rem" }}>
      <div
        className="w-100 mb-3 rounded mb-5 p-2"
        style={{ background: "#f8f9fa" }}
      >
        <div className="top">
          <h5 className="text-title mb-2 mt-3">
            {id ? "Update" : "Create"} Hospital
          </h5>
          <button
            className="getbackButton"
            onClick={() => {
              setShowForm(false);
            }}
          >
            {" "}
            <FaAngleLeft className="inline" /> Go Back
          </button>
        </div>
        <form className="row form-row" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-12 mb-5">
            <div className="form-group">
              <div className="change-avatar d-flex gap-2 align-items-center">
                <Link className="my-3 patient-img">
                  <img
                    src={selectedImage ? selectedImage : data?.img || dImage}
                    alt=""
                  />
                </Link>
                <div className="mt-3">
                  <ImageUpload
                    setSelectedImage={setSelectedImage}
                    setFile={setFile}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>
                Name <span className="text-danger">*</span>
              </label>
              <input
                defaultValue={data?.name}
                {...register("name")}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>
                Address <span className="text-danger">*</span>
              </label>
              <input
                defaultValue={data?.address}
                {...register("address")}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>Services</label>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                value={selectedService}
                onChange={setSelectedServices}
                options={ServicesList?.services?.map((service) => ({
                  label: service.name,
                  value: service.id,
                }))}
              />
              <small className="form-text text-muted">
                Note : Type & Press enter to add new services
              </small>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>
                WebUrl <span className="text-danger">*</span>
              </label>
              <input
                defaultValue={data?.webUrl}
                {...register("webUrl")}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-2 card-label">
              <label>
                Phone <span className="text-danger">*</span>
              </label>
              <input
                defaultValue={data?.phone}
                {...register("phone")}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group mb-2 card-label">
              <label>
                Description <span className="text-danger"></span>
              </label>
              <textarea
                defaultValue={data?.description}
                {...register("description")}
                className="form-control"
                rows={5}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group mb-2 card-label">
              <label>
                Google Location <span className="text-danger">*</span>
              </label>
              <GoogleMapComponent
                location={location}
                setLocation={setLocation}
              />
            </div>
          </div>

          <div className="text-center my-3 ">
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              loading={isLoading}
              disabled={isLoading ? true : false}
            >
              {id
                ? isLoading
                  ? "Updating ..."
                  : "Update Changes"
                : createisLoading
                ? "Saving ..."
                : "Save Changes"}
            </Button>
            <Button
              htmlType="button"
              className="ms-2"
              size="large"
              onClick={() => setShowForm(false)}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpsertHospital;
