import React from "react";
import "./index.css";
import imgService from "../../../images/img/service-images.png";
import icon1 from "../../../images/img/doctor-service.png";
import icon2 from "../../../images/img/tooth-service.png";
import icon3 from "../../../images/img/patient-service.png";
import icon4 from "../../../images/img/teeth-service.png";
import img from "../../../images/doc/doc1.jpg";
import img2 from "../../../images/doc/doc4.jpg";
import img3 from "../../../images/doc/doctor 5.jpg";
import { Link } from "react-router-dom";

const Service = () => {
  return (
    // <section className="container" style={{marginTop: 200, marginBottom:200}}>
    //     <div className='mb-5 section-title text-center'>
    //         <h2>Services</h2>
    //         <p className='m-0'>Lorem ipsum dolor sit amet consectetur adipisicing.</p>
    //     </div>
    //     <div className="container">
    //         <div className="row align-items-center">
    //             <div className="col-lg-4 col-sm-6">
    //                 <div className="service-img">
    //                     <img src={img} alt="" className="img-fluid" />
    //                     <img src={img2} alt="" className="img-fluid mt-4" />
    //                 </div>
    //             </div>
    //             <div className="col-lg-4 col-sm-6">
    //                 <div className="service-img mt-4 mt-lg-0">
    //                     <img src={img3} alt="" className="img-fluid" />
    //                 </div>
    //             </div>
    //             <div className="col-lg-4">
    //                 <div className="service-content ps-4 mt-4 mt-lg-0">
    //                     <h2>Personal care <br />healthy living</h2>
    //                     <p className="mt-4 mb-5 text-secondary form-text">We provide best leading medicle service Nulla perferendis veniam deleniti ipsum officia dolores repellat laudantium obcaecati neque.</p>
    //                     <Link to={'/service'} className="btn-get-started scrollto">Services</Link>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </section>
    <section className="services-home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div>
              <img src={imgService} className="services" alt="" />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 d-flex align-items-center">
            <div className="services-content">
              <div className="icons-services flex">
                <img src={icon1} className="icon" alt="" />
                <img src={icon2} className="icon" alt="" />
                <img src={icon3} className="icon" alt="" />
                <img src={icon4} className="icon" alt="" />
              </div>
              <h3 className="heading-services">Services</h3>
              <p>
                We provide best leading medicle service Nulla perferendis veniam
                deleniti ipsum officia dolores repellat laudantium obcaecati
                neque.We provide best leading medicle service Nulla perferendis
                veniam deleniti ipsum officia dolores repellat laudantium
                obcaecati neque.
              </p>
              <div>
                <Link to={"/service"} className="more-btn scrollto">
                  Know More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;
