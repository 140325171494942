import React from 'react';
import './index.css';
import { Link } from 'react-router-dom';
import Form from './Form';

const HeroSection = () => {
    return (
        <section id="hero" className="d-flex align-items-center">
            <div className="container">
                <div>
                    {/* <small>TOTAL HEALTH CARE SOLUTION</small> */}
                    <h1>Your Most Trusted <br />Health Partner</h1>
                    {/* <small>A repudiandae ipsam labore ipsa voluptatum quidem quae laudantium quisquam aperiam maiores sunt fugit, deserunt rem suscipit placeat.</small> */}
                </div>
                {/* <div className="d-flex justify-content-start gap-2">
                    <Link to={'/doctors'} className="btn-get-started scrollto">Get Started</Link>
                    <Link to={'/track-appointment'} className="btn-get-started scrollto">Track Appointment</Link>
                </div> */}

                <Form />
               
            </div>
        </section>
    )
}
export default HeroSection;