import React, { useEffect } from 'react'
import img from '../../../images/chair.png';
import img1 from '../../../images/specialities/cavity.png';
import { useGetDoctorHospitalsQuery } from '../../../redux/api/hospital';
import GoogleMapComponent from '../../Shared/GoogleMaps/googlemaps';
const Location = ({ doctorId }) => {

    const { data, isError, isLoading } = useGetDoctorHospitalsQuery(doctorId);

    return (
        <div className="location-list ">
            {
                data?.hospitalDoctors?.map(({ hospital }, index) => (
                    <div className='card shadow p-3 border-0 mb-3'>
                        <div className="row">

                            <div className="col-md-6 d-flex align-items-center">
                                <div className=" clinic-content-proflle">
                                    <h4 className="clinic-name"><a href="#">{hospital?.name}</a></h4>
                                    <p className="doc-speciality">{hospital?.description}</p>
                                    <div className="clinic-details mb-0">
                                        <h5 className="clinic-direction"> <i className="fas fa-map-marker-alt"></i> {hospital?.address} <br /><a>Get Directions</a></h5>
                                     
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="clinic-timing">
                                <GoogleMapComponent location={{
                                            lat: hospital?.latitude,
                                            lng: hospital?.longitude
                                        }}  disableFixButton={false}/>      
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default Location