export const tagTypes = {
    favourite: 'favourite',
    prescription: 'prescription',
    patient: 'patient',
    appointments: 'appointments',
    doctor: 'doctor',
    reviews: 'reviews',
    timeSlot: 'timeSlot',
    blogs: 'blogs',
    medicine: 'medicine',
    specialist: 'specialist',
    hospital: 'hospitals',
    services: 'services',
    setting: 'setting',
    insuranceCompany: 'setting',
    paymentOptions: 'paymentOptions',
    gallery: 'gallery',

}

export const tagTypeList = [
    tagTypes.favourite,
    tagTypes.prescription,
    tagTypes.patient,
    tagTypes.appointments,
    tagTypes.doctor,
    tagTypes.reviews,
    tagTypes.timeSlot,
    tagTypes.blogs,
    tagTypes.medicine,
    tagTypes.hospital,
    tagTypes.services,
    tagTypes.gallery,
]