import React, { useState } from "react";
import AdminLayout from "../AdminLayout/AdminLayout";
import { useNavigate } from "react-router-dom";

import "./Appointments.css";
import { useGetAllDoctorAppointmentsQuery } from "../../../redux/api/appointmentApi";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

const AdminAppointments = () => {
  const { data, refetch, isError, isLoading } =
    useGetAllDoctorAppointmentsQuery();
  const [dataList, setdataList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setdataList(data);
  }, [data]);

  const onNavigate = (id) => {
    navigate("/dashboard/appointments/" + id);
  };

  return (
    <>
      <AdminLayout>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="datatable table table-hover table-center mb-0">
                    <thead>
                      <tr>
                        <th>Doctor Name</th>
                        <th>Speciality</th>
                        <th>Patient Name</th>
                        <th>Apointment Time</th>
                        <th>Status</th>
                        <th className="text-center">Payment Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataList &&
                        dataList.map((item) => {
                          return (
                            <tr
                              key={item.id}
                              onClick={() => {
                                onNavigate(item.id);
                              }}
                            >
                              <td>
                                <h2 className="table-avatar">
                                  <a
                                    href="profile.html"
                                    className="avatar avatar-sm mr-2"
                                  >
                                    <img
                                      className="avatar-img rounded-circle inline"
                                      src={item?.doctor?.img}
                                      alt=""
                                    />
                                  </a>
                                  <a href="profile.html">
                                    {item?.doctor?.firstName +
                                      " " +
                                      item?.doctor?.lastName}
                                  </a>
                                </h2>
                              </td>
                              <td>{item?.doctor?.specialization}</td>
                              <td>
                                <h2 className="table-avatar">
                                  <a
                                    href="profile.html"
                                    className="avatar avatar-sm mr-2"
                                  >
                                    <img
                                      className="avatar-img rounded-circle inline"
                                      src={item?.patient?.img}
                                      alt=""
                                    />
                                  </a>
                                  <a href="profile.html">
                                    {item?.patient?.firstName +
                                      " " +
                                      item?.patient?.lastName}{" "}
                                  </a>
                                </h2>
                              </td>
                              <td>
                                {item?.scheduleDate.split(" ")[0]}{" "}
                                <span className="text-primary d-block">
                                  {item?.scheduleTime}
                                </span>
                              </td>
                              <td>
                                <div className="status-toggle">
                                  <input
                                    type="checkbox"
                                    id="status_1"
                                    className="check"
                                    checked
                                  />
                                  <label for="status_1" className="checktoggle">
                                    {item?.status}
                                  </label>
                                </div>
                              </td>
                              <td className="text-center">
                                {item?.paymentStatus}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </>
  );
};
export default AdminAppointments;
